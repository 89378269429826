import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { InputNumber, Button } from "antd";
import { ItemTypes } from "./ItemTypes";
import "./Card.css";

export const QuestionCard = ({
  id,
  text,
  index,
  pmark,
  duration_seconds,
  nmark,
  moveCard,
  detachQuestion,
  changeNegativeMarkCall,
  changePositiveMarkCall,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  function detachQuestionFromSection(e) {
    e.preventDefault();
    detachQuestion(index);
  }
  function changePositiveMark(e) {
    console.log(e);
    changePositiveMarkCall(index, e);
    return false;
  }
  //   function changeNegativeMark(e) {
  //     changeNegativeMarkCall(index, e);
  //     return false;
  //   }
  return (
    <tr ref={ref}>
      <td>{index + 1}</td>
      <td>
        <span className="ArrangeSpan" style={{ opacity, textAlign: "left" }}>
          {text}
        </span>
      </td>
      <td>
        <InputNumber
          value={duration_seconds}
          onChange={changePositiveMark}
          min={0}
          step={1}
        />
      </td>
      {/* <td>
        <InputNumber
          value={nmark}
          min={0}
          step={0.25}
          onChange={changeNegativeMark}
        />
      </td> */}
      <td>
        {" "}
        <Button
          onClick={detachQuestionFromSection}
          type="primary"
          style={{ marginRight: 8 }}
        >
          Detach
        </Button>
      </td>
    </tr>
  );
};
