import {
  Steps,
  Button,
  message,
  Layout,
  Input,
  List,
  Avatar,
  Skeleton,
  Row,
  Col,
  Empty,
  Checkbox,
  Transfer,
} from "antd";
import { Siderc } from "../Main/Siderc";
import {
  FormOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import React from "react";
import "./CreateClass.css";
import { Headerc } from "../Main/Headerc";
import { Link, Redirect } from "react-router-dom";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content, Footer } = Layout;

class CreateBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      className:
        props.location.state && props.location.state.name
          ? props.location.state.name
          : "",
      users: [],
      step: props.location.state && props.location.state.name ? 2 : 1,
      mockData: [],
      targetKeys: [],
      batch_id:
        props.location.state && props.location.state.id
          ? props.location.state.id
          : "",
      addingSubject: false,
      updatedName:
        props.location.state && props.location.state.name
          ? props.location.state.name
          : "",
      child: [],
      addingTopic: false,
      currentTopic: "",
      editing: false,
      idArray: [],
      res: 0,
    };
  }
  componentDidMount() {
    if (typeof this.state.batch_id === "number") {
      selectorService
        .getClassDetails(this.state.batch_id)
        .then((res) => {
          let idArray = [];
          let users = res.result.subjects.map((x) => {
            idArray.push(x.id);
            return [x.name];
          });
          // console.log(users);
          // console.log(idArray);
          res.result.subjects.map((x, idx) => {
            x.topics.map((y, idxY) => {
              users[idx].push(y.name);
            });
            return null;
          });
          this.setState({ child: users, idArray });
        })
        .catch((err) => {
          console.log(err);
        });
      // this.setState({ upd });
    }
  }

  render() {
    let header =
      this.state.step === 1
        ? "Class Name"
        : !this.state.editing
        ? this.state.className + " Class"
        : "";
    console.log(this.state.child);
    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">
              {this.state.step === 1 ? "Create " : "Edit "} Class
            </Layout>
            <Layout className="createExamLayout">
              <div style={{ position: "relative" }}>
                <strong>
                  <h2>
                    <span>
                      <Input
                        value={this.state.updatedName}
                        onChange={(e) =>
                          this.setState({ updatedName: e.target.value })
                        }
                        hidden={!this.state.editing}
                        style={{ width: "10%", float: "left", height: "30px" }}
                        placeholder="Class Name"
                      />
                    </span>
                    <span style={{ float: "left" }}> {header}</span>
                    <span
                      className="examNameEdit"
                      hidden={this.state.step === 1}
                      style={{
                        cursor: "pointer",
                        width: "5%",
                        float: "left",
                        // position: "absolute",
                        // top: "10%",
                        // right: "0",
                        display: "inline-block",
                      }}
                    >
                      <div onClick={() => this.setState({ editing: true })}>
                        <FormOutlined
                          hidden={this.state.editing}
                          style={{ fontSize: "16px", color: "#1890FF" }}
                          twoToneColor="#1890FF"
                        />
                      </div>
                      <div
                        hidden={!this.state.editing}
                        onClick={() => {
                          this.setState({
                            editing: false,
                            updatedName: this.state.className,
                          });
                        }}
                      >
                        <CloseSquareTwoTone />
                      </div>
                      <div
                        onClick={() => {
                          const {
                            updatedName,
                            batch_id,
                            className,
                          } = this.state;
                          selectorService
                            .updateClass({
                              name: updatedName,
                              id: batch_id,
                            })
                            .then((res) => {
                              console.log(res);
                              if (!res.result.id) {
                                message.error("Some Error occured");
                                this.setState({
                                  editing: false,
                                  updatedName: className,
                                });
                              } else {
                                this.setState({
                                  editing: false,
                                  className: this.state.updatedName,
                                });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                              this.setState({ editing: false });
                            });
                        }}
                        hidden={!this.state.editing}
                      >
                        <CheckSquareTwoTone />
                      </div>
                    </span>
                  </h2>
                </strong>

                <span
                  hidden={this.state.step === 1}
                  style={{
                    cursor: "pointer",
                    width: "5px",
                    float: "right",
                    position: "absolute",
                    right: "15%",
                    top: "0",
                    // right: "1",
                  }}
                >
                  <div>
                    <Button
                      disabled={
                        this.state.addingSubject || this.state.addingTopic
                      }
                      onClick={() => {
                        let child = this.state.child;
                        child = [[]].concat(child);
                        this.setState({ child, addingSubject: true });
                      }}
                      type="primary"
                    >
                      Add Subjects
                    </Button>
                  </div>
                </span>
              </div>
              <div hidden={this.state.step !== 1}>
                <Input
                  value={this.state.className}
                  onChange={(e) => {
                    this.setState({ className: e.target.value });
                  }}
                  style={{ width: "80%" }}
                  size="large"
                  placeholder="Class"
                />
                <br />
                <br />
                <Button
                  onClick={() => {
                    if (
                      !this.state.className ||
                      this.state.className.length === 0
                    ) {
                      message.error("Class name is mandatory");
                    } else {
                      selectorService
                        .createClass({
                          name: this.state.className,
                        })
                        .then((res) => {
                          if (res.result.id) {
                            this.setState({
                              step: this.state.step + 1,
                              batch_id: res.result.id,
                              updatedName: this.state.className,
                            });
                          } else {
                            message.error("Some error occured");
                            this.setState({ className: "" });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          this.setState({ className: "" });
                        });
                    }
                    // this.setState({ step: 2 });
                  }}
                  style={{ width: "13%" }}
                  type="primary"
                >
                  Create Class
                </Button>
              </div>
              <div hidden={this.state.step === 1}>
                <br />
                {/* <Input
                  value={this.state.updatedName}
                  onChange={(e) =>
                    this.setState({ updatedName: e.target.value })
                  }
                  hidden={!this.state.addingSubject}
                  style={{ width: "90%" }}
                  placeholder="Subject Name"
                /> */}
                {this.state.child.map((x, idx) => {
                  if (this.state.addingSubject && idx === 0) {
                    return (
                      <Footer
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "white",
                          border: "1px solid rgb(231 230 230)",
                        }}
                      >
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Enter Subject"
                          onChange={(e) => {
                            let child = [...this.state.child];
                            child[idx] = [e.target.value];
                            this.setState({ child });
                          }}
                        />

                        <Button
                          onClick={() => {
                            selectorService
                              .addSubjectToClass({
                                name: this.state.child[idx][0],
                                id: this.state.batch_id,
                              })
                              .then((res) => {
                                console.log(res);
                                if (res.status.code !== "-1") {
                                  console.log("1");
                                  this.setState({
                                    addingSubject: false,
                                    idArray: [res.result.id].concat(
                                      this.state.idArray
                                    ),
                                  });
                                } else {
                                  let child = [...this.state.child];
                                  child = child.slice(1);
                                  this.setState(
                                    {
                                      addingSubject: false,
                                      child,
                                    },
                                    () => {
                                      message.error(
                                        "Same Subject name under class not allowed"
                                      );
                                    }
                                  );
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                                this.setState({ addingSubject: false });
                              });
                          }}
                          type="primary"
                          style={{
                            float: "right",
                            width: "8%",
                            textAlign: "center",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => {
                            let child = [...this.state.child];
                            child = child.slice(1);
                            this.setState({ child, addingSubject: false });
                          }}
                          type="primary"
                          style={{
                            float: "right",
                            marginRight: "2%",
                            width: "8%",
                            textAlign: "center",
                          }}
                        >
                          Cancel
                        </Button>
                      </Footer>
                    );
                  } else if (
                    this.state.addingTopic &&
                    idx === this.state.currentTopic
                  ) {
                    return (
                      <Footer
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "white",
                          border: "1px solid rgb(231 230 230)",
                        }}
                      >
                        {x[0]}
                        <Button
                          disabled={true}
                          onClick={() => {
                            console.log(idx);
                          }}
                          type="primary"
                          style={{ float: "right" }}
                        >
                          Add Topic
                        </Button>
                        {x.map((y, idxTopic) => {
                          if (idxTopic === 0) return null;
                          else if (idxTopic !== x.length - 1)
                            return (
                              <Footer
                                style={{
                                  border: "1px solid rgb(231 230 230)",
                                  backgroundColor: "white",
                                  padding: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                {y}
                              </Footer>
                            );
                          else
                            return (
                              <Footer
                                style={{
                                  border: "1px solid rgb(231 230 230)",
                                  backgroundColor: "white",
                                  padding: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                <Input
                                  style={{ width: "80%" }}
                                  placeholder="Enter Topic"
                                  onChange={(e) => {
                                    let child = [...this.state.child];
                                    let topic = [...this.state.child[idx]];
                                    topic[idxTopic] = e.target.value;
                                    child[idx] = topic;
                                    this.setState({ child });
                                  }}
                                />
                                <Button
                                  onClick={() => {
                                    selectorService
                                      .addTopicToClass({
                                        name: this.state.child[idx][idxTopic],
                                        id: this.state.idArray[idx],
                                      })
                                      .then((res) => {
                                        console.log(res);
                                        if (res.status.code !== "-1") {
                                          this.setState({
                                            addingTopic: false,
                                          });
                                        } else {
                                          let child = [...this.state.child];
                                          console.log(child);
                                          let topic = [
                                            ...this.state.child[idx],
                                          ];
                                          console.log(topic);
                                          topic = topic.slice(0, idxTopic);
                                          child[idx] = topic;
                                          this.setState(
                                            {
                                              addingTopic: false,

                                              child,
                                            },
                                            () => {
                                              message.error(
                                                "Same topic names under a subject not allowed"
                                              );
                                            }
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }}
                                  type="primary"
                                  style={{ float: "right" }}
                                >
                                  Save
                                </Button>
                                <Button
                                  onClick={() => {
                                    let child = [...this.state.child];
                                    let topic = [...this.state.child[idx]];
                                    topic = topic.slice(0, idxTopic);
                                    child[idx] = topic;
                                    this.setState({
                                      child,
                                      addingTopic: false,
                                    });
                                  }}
                                  type="primary"
                                  style={{
                                    float: "right",
                                    marginRight: "2%",
                                    width: "8%",
                                    textAlign: "center",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Footer>
                            );
                        })}
                      </Footer>
                    );
                  } else if (x.length === 1) {
                    return (
                      <>
                        <Footer
                          style={{
                            marginBottom: "10px",
                            backgroundColor: "white",
                            border: "1px solid rgb(231 230 230)",
                          }}
                        >
                          {x[0]}
                          <Button
                            disabled={
                              this.state.addingTopic || this.state.addingSubject
                            }
                            onClick={() => {
                              let child = [...this.state.child];
                              let topics = [...this.state.child[idx]];
                              topics.push("");
                              child[idx] = topics;
                              this.setState({
                                child,
                                currentTopic: idx,
                                addingTopic: true,
                              });
                            }}
                            type="primary"
                            style={{ float: "right" }}
                          >
                            Add Topic
                          </Button>
                        </Footer>
                      </>
                    );
                  } else {
                    return (
                      <Footer
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "white",
                          border: "1px solid rgb(231 230 230)",
                        }}
                      >
                        {x[0]}
                        <Button
                          disabled={
                            this.state.addingTopic || this.state.addingSubject
                          }
                          onClick={() => {
                            let child = [...this.state.child];
                            let topics = [...this.state.child[idx]];
                            topics.push("");
                            child[idx] = topics;
                            this.setState({
                              child,
                              currentTopic: idx,
                              addingTopic: true,
                            });
                          }}
                          type="primary"
                          style={{ float: "right" }}
                        >
                          Add Topic
                        </Button>
                        {x.map((y, idx) => {
                          if (idx === 0) return null;
                          else
                            return (
                              <Footer
                                style={{
                                  border: "1px solid rgb(231 230 230)",
                                  backgroundColor: "white",
                                  padding: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                {y}
                              </Footer>
                            );
                        })}
                      </Footer>
                    );
                  }
                })}

                <br />
                <Button type="primary">
                  <Link
                    to={{
                      pathname: "/viewclass",
                      state: this.state.batch_id,
                    }}
                  >
                    Continue
                  </Link>
                </Button>
              </div>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default CreateBatch;
