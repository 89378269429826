import React from "react";
import "../SelectSubjects.css";
import "../DragNDrop/Card.css";
import { Button, Form, Switch, Drawer, Input, message, Upload } from "antd";
import { FormOutlined, CheckSquareTwoTone, CloseSquareTwoTone, InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { authenticationService } from "../../../_services";
import { selectorService } from "../../../services/selectorService";
// import DragDropContainer from "../DragNDrop/Container";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import AddQuestions from "./AddQuestionsPractice";
// import { Card } from "./PracticeCard";
// import { isEqual } from "lodash";
import { S3Bucket } from '../../../services/S3Bucket';
import ImgCrop from 'antd-img-crop';

let subject = [];

class ArrangeSections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      examName: props.selectorState.name,
      update: false,
      examId: props.selectorState.examId,
      sections: props.selectorState.sections,
      currentUser: authenticationService.currentUserValue,
      authorId: props.selectorState.authorId,
      examStatus: props.selectorState.examStatus,
      examTypeId: props.selectorState.examTypeId,
      id: props.selectorState.id,
      image: props.selectorState.image,
      instituteId: props.selectorState.instituteId,
      isActive: props.selectorState.isActive,
      languageId: props.selectorState.languageId,
      subjects: [],
      currentStatus: 0,
      rearrange: true,
      visible: false,
      visibleButtonIndex: 0,
      modal: { examId: props.selectorState.id },
      classes: 1,
      loaded: false,
      updated: false,
      updatedName: props.selectorState.name,
      file: "",
      uploading: false,
    };

    this.uploadProps = {
      onRemove: (file) => {
        this.setState(prev => ({...prev, file: ""}));
      },
      beforeUpload: (file) => {
        this.setState(prevVal => {
          return {
            ...prevVal,
            file,
            uploading: true,
          }
        });
  
        S3Bucket.practiceImageUpload(this.state.id, file);
        return false;
      },
      onchange: (err, event) => console.log('event'),
      file: this.state.file,
    };

    this.sendData = this.sendData.bind(this);
    this.handleRearrange = this.handleRearrange.bind(this);
    this.handleArrangeSubject = this.handleArrangeSubject.bind(this);
    this.handleLoadSections = this.handleLoadSections.bind(this);
    this.checkType = this.checkType.bind(this);
  }

  

  componentDidMount() {
    this.handleLoadSections();
  }

  componentDidUpdate(prevprops) {
    console.log(this.state.updated);
    if (!this.state.updated)
      selectorService.loadSectionsForPractice(this.state.id).then((data) => {
        console.log("Success:", data);
        const result = data.result;
        this.setState({ sections: [result], updated: true });
        // // this.state.sections = [];
        // let array = [];
        // const sections = result.sections;
        // for (let index = 0; index < sections.length; index++) {
        //   let c = {};
        //   c.id = sections[index].id;
        //   c.label = sections[index].subject_name;
        //   c.value = sections[index].subject_id;
        //   c.section_state = sections[index].section_state;
        //   c.totalQuestion = sections[index].total_questions;
        //   c.subject_id = sections[index].subject_id;
        //   c.index = index;
        //   c.buttonState = false;
        //   array.push(c);
        // }
        // if (!isEqual(array, this.state.sections)) {
        //   this.setState({ sections: array });
        // }
      });
  }

  handleLoadSections = () => {
    selectorService.loadSectionsForPractice(this.state.id).then((data) => {
      console.log("Success:", data);
      const result = data.result;
      // this.state.sections = [];
      // const sections = result.sections;
      // for (let index = 0; index < sections.length; index++) {
      //   let c = {};
      //   c.id = sections[index].id;
      //   c.label = sections[index].subject_name;
      //   c.value = sections[index].subject_id;
      //   c.section_state = sections[index].section_state;
      //   c.totalQuestion = sections[index].total_questions;
      //   c.subject_id = sections[index].subject_id;
      //   c.index = index;
      //   c.buttonState = false;
      //   this.state.sections.push(c);
      // }
      // this.state.loaded = true;
      // this.forceUpdate();
      this.setState({ sections: [result] });
    });
  };

  handleRearrange = (event) => {
    this.setState({
      rearrange: event,
    });
  };

  callbackFunctionDragNDrop = (childData) => {
    console.log("Parent recieved Selector Data: " + JSON.stringify(childData));
    this.setState({
      sections: childData,
      updated: true,
    });
  };

  callbackButtonClick = (childData) => {
    console.log("Parent recieved Selector Data: " + childData);
    console.log(this.props.selectorState.id);
    this.setState({
      visible: true,
      visibleButtonIndex: childData,
      modal: {
        sections: this.state.sections,
        visible: true,
        examId: this.props.selectorState.id,
        classes: sessionStorage.getItem("currentClass"),
        module_id: this.state.sections[childData].module_id,
        module: this.state.sections[childData].module,
        subjectId: this.state.sections[childData].module_id,
        sectionName: this.state.sections[childData].name,
      },
      subject: this.state.sections[childData].name,
    });
  };

  handleArrangeSubject() {
    if (
      this.state.sections.filter((x) => x.module_status === "PUBLISHED")
        .length !== this.state.sections.length
    ) {
      message.warning("Please add questions for the available sections");
    }
    //  else {
    //   let sectionsId = [];
    //   this.state.sections.forEach((section) => {
    //     sectionsId.push(section.id);
    //   });
    //   let json = {};
    //   json.idList = sectionsId;
    //   selectorService
    //     .arrangeSectionsFromExam(this.state.id, json)
    //     .then((data) => {
    //       let result = {};
    //       result.current = 3;
    //       result.sections = this.state.sections;
    //       result.rearrange = this.state.rearrange;
    //       result.exam = this.state.examName;
    else this.sendData({ sections: this.state.sections });
    //     });
    // }
  }

  sendData = (result) => {
    console.log(result);
    this.props.parentCallback(result);
  };

  handleCancel = (e) => {
    // console.log(e);
    this.state.modal.visible = false;
    this.setState({
      visible: false,
      updated: false,
    });
  };

  checkType = (file) => {
    if (file.type === "image/png") {
      return true;
    } else {
      message.error("Please select PNG files for Exam Icon");
      return false;
    }
  }

  render() {
    const { current } = this.state.currentStatus;
    let { sections } = this.state;
    console.log(this.state.sections);

    return (
      <div className="SelectSubjects">
        <Form className="exam-form">
          <div className="exam">
            <table className="examTable">
              <tbody>
                <tr>
                  <td>
                    <div className="examNameOuter">
                      <div>
                        <img
                          width={50}
                          height={50}
                          src={this.state.image}
                        ></img>
                      </div>

                      <div className="examName">
                        <div>
                          <span>Practice Name</span>
                        </div>
                        <div>
                          <span
                            hidden={this.state.editing}
                            className="examNameSpan"
                          >
                            {this.state.examName}
                          </span>
                          <span>
                            {" "}
                            <Input
                              value={this.state.updatedName}
                              onChange={(e) =>
                                this.setState({ updatedName: e.target.value })
                              }
                              hidden={!this.state.editing}
                              style={{ width: "60%" }}
                              placeholder="Practice Name"
                            />{" "}
                          </span>
                        </div>
                      </div>

                      <ImgCrop beforeCrop={this.checkType}>
                        <Upload {...this.uploadProps} maxCount={1} showUploadList={false}>
                          <Button type="primary" icon={<UploadOutlined />}>Change Icon</Button>
                        </Upload>
                      </ImgCrop>

                    </div>
                  </td>
                  <td
                    className="examNameEdit"
                    style={{ cursor: "pointer", width: "5px" }}
                  >
                    <div onClick={() => this.setState({ editing: true })}>
                      <FormOutlined
                        hidden={this.state.editing}
                        style={{ fontSize: "16px", color: "#1890FF" }}
                        twoToneColor="#1890FF"
                      />
                    </div>
                    <div
                      hidden={!this.state.editing}
                      onClick={() => {
                        this.setState({
                          editing: false,
                          updatedName: this.state.examName,
                        });
                      }}
                    >
                      <CloseSquareTwoTone />
                    </div>
                    <div
                      onClick={() => {
                        const {
                          examId,
                          updatedName,
                          image,
                          authorId,
                          examTypeId,
                          id,
                        } = this.state;
                        selectorService
                          .updateExam({
                            id,
                            examId,
                            name: updatedName,
                            image,
                            authorId,
                            examTypeId,
                          })
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                        this.setState({
                          editing: false,
                          examName: this.state.updatedName,
                        });
                      }}
                      hidden={!this.state.editing}
                    >
                      <CheckSquareTwoTone />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>{" "}
          </div>

          <br />
          <div>
            <span>Select Subject</span>{" "}
            {/* <Switch
              defaultChecked
              onChange={(e) => {
                this.handleRearrange(e);
              }}
            />
            <span> re-arrange</span> */}
          </div>
          {!this.state.visible && this.state.sections.length > 0 ? (
            <div className="subjectButtons">
              {/* <DndProvider backend={HTML5Backend}>
                <DragDropContainer
                  id={this.state.id}
                  modelState={this.state.sections}
                  parentCallback={this.callbackFunctionDragNDrop}
                  parentButtonCallback={this.callbackButtonClick}
                />
              </DndProvider>
              <Card
                key={0}
                status={this.state.sections[0].module_status}
                index={0}
                id={this.state.sections[0].id}
                text={this.state.sections[0].module_name}
                moveCard={() => {}}
                clickButton={() => {}}
              /> */}
              <button
                className={
                  this.state.sections[0].module_status !== "DRAFT"
                    ? "sectionStatusPUB"
                    : "ReaarngeButtonTrue"
                }
                onClick={() => {
                  this.callbackButtonClick(0);
                }}
              >
                {this.state.sections[0].module_name}
              </button>
            </div>
          ) : null}
          <br />
          <InfoCircleOutlined />
          <span >
            {" "}
            <strong>Note:</strong> Click subject/topic to add respective
            questions
          </span>
          <br />
          <Button
            type="primary"
            onClick={(e) => {
              this.handleArrangeSubject();
            }}
          >
            Continue
          </Button>
        </Form>
        <Drawer
          title={"Attach Question for Section : " + this.state.subject}
          width={"100%"}
          placement="right"
          closable={true}
          onClose={this.handleCancel}
          visible={this.state.visible}
          footer={
            <div
              style={{
                textAlign: "left",
              }}
            >
              <Button
                onClick={this.handleCancel}
                type="primary"
                style={{ marginRight: 8 }}
              >
                Close
              </Button>
            </div>
          }
        >
          <AddQuestions
            closeComplete={this.handleCancel}
            addQuestion={this.state.modal}
          ></AddQuestions>
        </Drawer>
      </div>
    );
  }
}

export default ArrangeSections;
