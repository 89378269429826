import {
  Steps,
  Button,
  message,
  Layout,
  List,
  Avatar,
  Skeleton,
  Empty,
  Dropdown,
  Menu,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import "../Exams/DraftExam.css";
import { Siderc } from "../Main/Siderc";
import React from "react";
import { Headerc } from "../Main/Headerc";
import { Link } from "react-router-dom";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content } = Layout;

class DraftExam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total_count: 0,
      total_pages: 0,
      processing: true,
      type: "Subjects",
    };
  }
  componentDidMount() {
    if (this.state.type === "Subjects")
      selectorService
        .draftPractice({
          pageSize: 10,
          pageNumber: 1,
        })
        .then((res) => {
          console.log(res);
          this.setState({
            data: res.result.practices,
            total_count: res.result.total_count,
            total_pages: res.result.total_pages,
            processing: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    else {
      selectorService
        .draftPracticeCategory({
          pageSize: 10,
          pageNumber: 1,
        })
        .then((res) => {
          console.log(res);
          this.setState({
            data: res.result.practices,
            total_count: res.result.total_count,
            total_pages: res.result.total_pages,
            processing: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleChange = (pageNumber, pageSize) => {
    if (this.state.type === "Subjects")
      selectorService
        .draftPractice({ pageNumber: pageNumber, pageSize: pageSize })
        .then((res) => {
          this.setState({
            data: res.result.practices,
            total_count: res.result.total_count,
            total_pages: res.result.total_pages,
            processing: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    else {
      selectorService
        .draftPracticeCategory({ pageNumber: pageNumber, pageSize: pageSize })
        .then((res) => {
          this.setState({
            data: res.result.practices,
            total_count: res.result.total_count,
            total_pages: res.result.total_pages,
            processing: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.state.data && this.state.data.length !== 0)
      return (
        <Layout>
          <Siderc />
          <Layout>
            <Headerc />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <Layout className="createExamLayoutHeader">
                Draft Practice
                {/* <br /> */}
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          console.log("Subjects");
                          // selectorService
                          //   .loadSubjects()
                          //   .then((res) => {
                          //     console.log("inside", res);
                          //     this.setState({
                          //       options: isNaN(parseInt(res.result.length))
                          //         ? []
                          //         : res.result,
                          //       type: "Subjects",
                          //       buttons: isNaN(parseInt(res.result.length))
                          //         ? []
                          //         : res.result,
                          //     });
                          //   })
                          //   .catch((err) => console.log(err));
                          this.setState({ type: "Subjects" }, () => {
                            this.handleChange(1, 10);
                          });
                        }}
                        key="0"
                      >
                        Subjects
                      </Menu.Item>
                      <Menu.Item
                        onClick={(e) => {
                          console.log("Topics");
                          this.setState({ type: "Topics" }, () => {
                            this.handleChange(1, 10);
                          });
                        }}
                        key="1"
                      >
                        Topics
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    href="#"
                    style={{ width: "10%" }}
                  >
                    {this.state.type === "" ? (
                      <>
                        Type <DownOutlined />
                      </>
                    ) : (
                      this.state.type
                    )}
                    <DownOutlined />
                  </a>
                </Dropdown>
              </Layout>

              <Layout className="createExamLayout">
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.data}
                  pagination={{
                    onChange: (page, pageSize) => {
                      this.handleChange(page, pageSize);
                    },

                    total: this.state.total_count,
                  }}
                  renderItem={(item, index) => {
                    console.log(item);
                    let currentTime = new Date();
                    let loginTime = new Date(item.updated_on);
                    const diff = currentTime.getTime() - loginTime.getTime();
                    // console.log(diff,loginTime,currentTime,item.updated_on);
                    var years = Math.floor(
                      diff / (1000 * 60 * 60 * 24 * 30 * 12)
                    );
                    var months = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30 * 12)) /
                        (1000 * 60 * 60 * 24 * 30)
                    );
                    var days = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30)) /
                        (1000 * 60 * 60 * 24)
                    );

                    var hours = Math.floor(
                      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    var minutes = Math.floor((diff % 3.6e6) / 6e4);
                    let updatedOn =
                      years > 0
                        ? `${years} year(s)/${months} months`
                        : months > 0
                        ? `${months} month(s)/${days} days`
                        : days > 0
                        ? `${days} day(s)/${hours} hours`
                        : minutes > 0
                        ? `${hours} hour(s)${minutes}minutes(s)`
                        : minutes + "minutes";
                    return (
                      <List.Item
                        className="DraftExam"
                        key={index}
                        actions={[
                          <Button
                            onClick={(e, newValue) => {
                              selectorService
                                .deletePractice(item.id)
                                .then((res) => {
                                  console.log(res);
                                  if (res.status.code === "-1")
                                    message.error("Some error occured");
                                  else {
                                    let data = [...this.state.data];
                                    data = data.filter((x) => x.id !== item.id);
                                    this.setState({ data }, () => {
                                      message.success("Deleted Successfully");
                                    });
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                  message.error("Some error occured");
                                });
                            }}
                            type="primary"
                          >
                            Delete Practice
                          </Button>,
                          <Button
                            onClick={(e, newValue) => {
                              console.log(item, e);
                            }}
                            type="primary"
                          >
                            <Link
                              to={{
                                pathname: "/createPractise",
                                state: { id: item.id, current: 1 },
                              }}
                            >
                              Recreate Practice
                            </Link>
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                              size="large"
                              shape="square"
                              className="DraftExamImage"
                            />
                          }
                          size="large"
                          bordered="true"
                          title={parse(
                            `<b style={{color:'black'}}>${item.name}</b>`
                          )}
                          description={
                            <>
                              <div className="MyExamDiv">
                                <table>
                                  <thead>
                                    <tr>
                                      <td style={{ color: "black" }}>
                                        Progress
                                      </td>
                                      <td style={{ color: "black" }}>
                                        Last Update
                                      </td>
                                      <td style={{ color: "black" }}>Author</td>
                                      <td style={{ color: "black" }}>
                                        Total Questions
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{updatedOn}</td>
                                      <td>{updatedOn}</td>
                                      <td>{item.author_name}</td>
                                      <td>{item.total_questions}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          }
                          // description={parse(
                          //   `<b>Progress - Step</b> ${step} <b>Updated At:</b> ${updatedOn}  <b>Author Name:</b> ${item.author_name} <b>Sections Count: </b>${item.sections_count}`
                          // )}
                        />
                      </List.Item>
                    );
                  }}
                />
              </Layout>
            </Content>
          </Layout>
        </Layout>
      );
    else if (this.state.processing)
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">
                  Draft Practice
                </Layout>
                <Layout className="createExamLayout">
                  <List
                    itemLayout="horizontal"
                    dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    pagination={{
                      onChange: (page, pageSize) => {
                        this.handleChange(page, pageSize);
                      },

                      total: this.state.total_count,
                    }}
                    renderItem={(item, index) => {
                      // let step = 2;
                      // if (item.sections_count >= 1) step = 3;
                      // if (item.sections_status !== "DRAFT") step = 4;
                      // let currentTime = new Date();
                      // let loginTime = new Date(item.updated_on);
                      // const diff = currentTime.getTime() - loginTime.getTime();
                      // // console.log(diff,loginTime,currentTime,item.updated_on);
                      // var years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12));
                      // var months = Math.floor(
                      //   (diff % (1000 * 60 * 60 * 24 * 30 * 12)) / (1000 * 60 * 60 * 24 * 30),
                      // );
                      // var days = Math.floor(
                      //   (diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
                      // );

                      // var hours = Math.floor(diff%(1000*60*60*24) / (1000 * 60 * 60));
                      // var minutes=Math.floor((diff % 3.6e+6) / 6e+4);
                      // let updatedOn=
                      // years > 0
                      //   ? `${years} year(s)/${months} months`
                      //   : months > 0
                      //   ? `${months} month(s)/${days} days`
                      //   : days > 0
                      //   ? `${days} day(s)/${hours} hours`
                      //   :minutes>0? `${hours} hour(s)${minutes}minutes(s)`:minutes+'minutes';
                      return (
                        <List.Item
                          className="DraftExam"
                          key={index}
                          // actions={[
                          //   <Button
                          //     onClick={(e, newValue) => {
                          //       console.log(item, e);
                          //     }}
                          //     type="primary"
                          //   >
                          //     <Link
                          //       to={{
                          //         pathname: "/createExam",
                          //         state: { id: item.id, current: step - 1 },
                          //       }}
                          //     >
                          //       Recreate Exam
                          //     </Link>
                          //   </Button>,
                          // ]}
                        >
                          <Skeleton>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                                  size="large"
                                  shape="square"
                                  className="DraftExamImage"
                                />
                              }
                              size="large"
                              bordered="true"
                              title={parse(`<b>${"item.name"}</b>`)}
                              description={parse(
                                `<b>Progress - Step</b> ${"step"} <b>Updated At:</b> ${"updatedOn"}  <b>Author Name:</b> ${"item.author_name"} <b>Sections Count: </b>${"item.sections_count"}`
                              )}
                            />
                          </Skeleton>
                        </List.Item>
                      );
                    }}
                  />
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </>
      );
    else
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">
                  Draft Practice
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() => {
                            console.log("Subjects");
                            // selectorService
                            //   .loadSubjects()
                            //   .then((res) => {
                            //     console.log("inside", res);
                            //     this.setState({
                            //       options: isNaN(parseInt(res.result.length))
                            //         ? []
                            //         : res.result,
                            //       type: "Subjects",
                            //       buttons: isNaN(parseInt(res.result.length))
                            //         ? []
                            //         : res.result,
                            //     });
                            //   })
                            //   .catch((err) => console.log(err));
                            this.setState({ type: "Subjects" }, () => {
                              this.handleChange(1, 10);
                            });
                          }}
                          key="0"
                        >
                          Subjects
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            console.log("Topics");
                            this.setState({ type: "Topics" }, () => {
                              this.handleChange(1, 10);
                            });
                          }}
                          key="1"
                        >
                          Topics
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      href="#"
                      style={{ width: "10%" }}
                    >
                      {this.state.type === "" ? (
                        <>
                          Type <DownOutlined />
                        </>
                      ) : (
                        this.state.type
                      )}
                    </a>
                  </Dropdown>
                </Layout>
                <Layout className="createExamLayout">
                  <Empty />
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </>
      );
  }
}

export default DraftExam;
