import React from 'react';
import { Layout, Avatar, Dropdown, Menu, Button } from 'antd';
import { authenticationService } from '../../_services';
import { selectorService } from '../../services/selectorService';
import { history } from '../../_helpers';
import { DownOutlined } from "@ant-design/icons";
// import { useHistory } from 'react-router-dom';

import './Headerc.css';


const { Header } = Layout;

function logout() {
  // let history = useHistory();
  authenticationService.logout();
  history.push('/login');
}

export class Headerc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: authenticationService.currentUserValue,
      refresh: false,
      language_code: authenticationService.currentUserValue.language_code ? authenticationService.currentUserValue.language_code : "en",
      language_name: authenticationService.currentUserValue.language_name ? authenticationService.currentUserValue.language_name : "English"
    }

    this.menuValue = [];
    this.menuClassValue = [];
    this.language = {};
    this.classes = [];
    this.handleLoad = this.handleLoad.bind(this);

    this.menu = () => {
      return (
        <Menu onClick={this.onClickLanguageChange}>
          {this.menus}
        </Menu>
      )
    }
    this.menuClass = () => {
      return (
        <Menu onClick={this.onClickClassChange}>
          {this.menuClasses}
        </Menu>
      )
    }

  }

  componentDidMount() {
    //window.addEventListener('load', this.handleLoad);
    if(!authenticationService.currentUserValue.language_code) {
      const storedUser = JSON.parse(sessionStorage.getItem('currentUser'));
      storedUser.language_code = 'en';
      sessionStorage.setItem('currentUser', JSON.stringify(storedUser));
    }
    
    this.handleLoad();
  }

  componentDidUpdate() {
    if (this.state.refresh) {
      this.setState({
        refresh: false
      })
      this.handleLoad();
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('load', this.handleLoad)
  }

  handleLoad() {
    selectorService.loadLanguages().then(data => {
      if (!data) {
        authenticationService.refreshFireToken().then(() => {
          this.setState({
            user: authenticationService.currentUserValue,
            refresh: true
          });
        });
      }
      if (data) {
        const result = data.result;
        this.language = {};

        for (let index = 0; index < result.length; index++) {
          // console.log((result[index]).name);
          let c = {};
          c.label = (result[index]).name;
          c.code = (result[index]).code;
          this.menuValue.push(c);
          this.language[index] = c;
        }
        this.menus = Object.entries(this.menuValue).map((key) => {
          // console.log(key);
          return (
            <Menu.Item key={key[0]} >
              {key[1].label}
            </Menu.Item>
          )
        });
      }
    });

    if (authenticationService.currentUserValue?.role_id !== 4) {
      selectorService.loadClasses().then(data => {
        if (data) {
          this.classes = [];
          // console.log(data); 
          const result = data.result;

          for (let index = 0; index < result.length; index++) {
            // console.log((result[index]).name);
            let c = {};
            c.id = (result[index]).id;
            c.name = (result[index]).name;
            this.menuClassValue.push(c);
            this.classes[index] = c;
          }

          this.menuClasses = 
            Object.entries(this.menuClassValue).map((key) => {
              return (
                <Menu.Item key={key[0]} >
                  {key[1].name}
                </Menu.Item>
              )
            });
          
      this.setState({
        className: result[0].name
      })
      const currentClass = authenticationService.currentClassValue;
      if(currentClass) this.props.isClassLoading && this.props.isClassLoading(false);
      if (currentClass === null)
        {
          authenticationService.updateClass(result[0].name, result[0].id);
          this.props.isClassLoading && this.props.isClassLoading(false);
        }
    }
  });
}
  }

onClickLanguageChange = ({ key }) => {
  // console.log(this.language[key])
  authenticationService.currentUserValue.language_code = this.language[key].code;
  authenticationService.currentUserValue.language_name = this.language[key].label;
  this.setState({
    language_code: this.language[key].code,
    language_name: this.language[key].label
  });
  sessionStorage.setItem('currentUser', JSON.stringify(authenticationService.currentUserValue));
  this.forceUpdate();
};

onClickClassChange = ({ key }) => {
  authenticationService.updateClass(this.classes[key].name, this.classes[key].id);
  window.location.reload();
  //this.forceUpdate();
};

render() {
  const currentClass = authenticationService.currentClassNameValue;
  this.state.className = currentClass;
  return (
    <Header className="app-header">
      {authenticationService.currentUserValue?.role_id !== 4 ?
        <div>

          <Dropdown overlay={this.menuClass} trigger={['click']}>
            <a style={{ color: 'blue' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {this.state.className} <DownOutlined />
            </a>
          </Dropdown>
        </div> : ""}
      <div>

        <Dropdown overlay={this.menu} trigger={['click']} >
          <Avatar gap={4} size={34} onClick={e => e.preventDefault()} style={{
            color: '#0E3997', textAlign: 'right', backgroundColor: 'white', cursor: 'pointer', display: 'grid', placeItems: 'center'
          }}> {this.state.language_code.toUpperCase()} <DownOutlined />
          </Avatar>
        </Dropdown>
      </div>
      <div>
        <Button type='primary' size='small' onClick={logout} danger>Logout</Button>
      </div>
    </Header>
  );
}
}