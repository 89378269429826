import history from "../history";
import { authenticationService } from "../_services";
import { INSTITUTE_API, LOAD_CLASSES, LOAD_LANGAUGES, LOAD_QUESTIONTYPES, LOAD_TOPICS_PREFIX } from "../_services/config";

export const selectorService = {
  loadCategory,
  loadSubjects,
  loadSubCategory,
  loadQuestionTypes,
  loadQuestions,
  loadComplexity,
  updateBookMark,
  loadLanguages,
  loadClasses,
  deleteQuestions,
  deletePractice,
  deleteExam,
  getQuestion,
  getQuestionTypes,
  createQuestion,
  myQuestion,
  createExam,
  createPractise,
  updateExam,
  attachSectionToExam,
  getExamDetails,
  getPracticeDetails,
  draftQuestion,
  savedDraftQuestion,
  submitcreateQuestion,
  attachQuestionsToExam,
  attachQuestionsToPractice,
  loadSections,
  loadSectionsForPractice,
  arrangeSectionsFromExam,
  loadReviewQuestions,
  loadReviewQuestionsPractice,
  detachQuestion,
  detachQuestionPractice,
  createBatch,
  addToBatch,
  deleteFromBatch,
  deleteBatch,
  deleteClass,
  deleteExamBatch,
  getAllBatch,
  getExamBatch,
  getStudentsOfBatch,
  getClassDetails,
  markQuestions,
  markQuestionsPractice,
  publishExam,
  publishPractice,
  loadQuestionsBookmark,
  draftExam,
  draftPractice,
  livePractice,
  upcomingPractice,
  draftPracticeCategory,
  livePracticeCategory,
  upcomingPracticeCategory,
  liveExam,
  upcomingExam,
  expiredExam,
  loadUpcomingExam,
  getAllClass,
  loadLiveExams,
  createExamAttempt,
  createClass,
  addBatchToExam,
  addBatchToPractice,
  addSubjectToClass,
  addTopicToClass,
  updateClass,
  getExamAttempt,
  updateExamStatus,
  updateBatchName,
  endExam,
  pauseExam,
  getAllUser,
  loadReport,
  getAllBatchForClass,
  addBatchesToExam,
  detachBatchesFromExam,
  livePracticeExams,
  createPracticeAttempt,
  getPracticeAttempt,
};

function loadReport(examId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/ExamAttempts/" +
      examId +
      "/Report",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function pauseExam(attempt_id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    //body: JSON.stringify(json)
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/en/ExamAttempts/" +
      attempt_id +
      "/Pause",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function endExam(attempt_id, json) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(json),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/en/ExamAttempts/" +
      attempt_id +
      "/End",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function updateExamStatus(attempt_id, json) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(json),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/ExamAttempts/" +
      attempt_id +
      "/HeartBeat",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function updateBatchName(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/" + obj.id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function getPracticeAttempt(practiceId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/PracticeAttempts/" +
      practiceId +
      "/Revision",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}


function getExamAttempt(attemptId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/ExamAttempts/" +
      attemptId +
      "/Questions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function getAllUser() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/users",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function getAllBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/Classes/" +
      obj.class_id,
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      //  console.error('Error:', error);
    });
}

function getExamBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" + obj.id + "/Batches",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      //  console.error('Error:', error);
    });
}

function getStudentsOfBatch(id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/" + id + "/Users",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      //  console.error('Error:', error);
    });
}

function getClassDetails(id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes/" + id + "/Structure",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      //  console.error('Error:', error);
    });
}

function createPracticeAttempt(practiceId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/PracticeAttempts/" +
      practiceId + "/CreateAttempt",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createExamAttempt(examId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/ExamAttempts/" +
      examId,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function loadLiveExams(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Batches/" +
      jsonObj.batch +
      "/LiveExams" +
      queryStr,
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {});
}

function loadUpcomingExam(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Batches/" +
      jsonObj.batch +
      "/UpcomingExams" +
      queryStr,
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {});
}

function getAllClass() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };

  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // authenticationService.logout();
      // window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

//console.log(myQuestion())
function publishExam(examId, json) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(json),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" + examId + "/Publish",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function publishPractice(practiceId, json) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(json),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" +
      practiceId +
      "/Publish",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function markQuestions(sectionId, questions) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(questions),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/ExamSections/" +
      sectionId +
      "/MarkQuestions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function markQuestionsPractice(practiceId, questions) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(questions),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" +
      practiceId +
      "/ReviewQuestions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function detachQuestion(sectionId, question) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(question),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/ExamSections/" +
      sectionId +
      "/DetachQuestions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response.json());
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function detachQuestionPractice(practiceId, question) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(question),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" +
      practiceId +
      "/DetachQuestions",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        console.log(response.json());
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createClass(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deleteExamBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" +
      obj.id +
      "/DetachBatch",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addBatchToExam(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" +
      obj.id +
      "/AttachBatch",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function detachBatchesFromExam(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj.batches),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" +
      obj.id +
      "/DetachBatch",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addBatchesToExam(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj.batches),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" +
      obj.id +
      "/AttachBatch",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addBatchToPractice(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practice/" +
      obj.id +
      "/AttachBatch",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addSubjectToClass(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes/" +
      obj.id +
      "/Subjects",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addTopicToClass(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Subjects/" +
      obj.id +
      "/Categories",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function updateClass(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  console.log(requestOptions);
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes/" + obj.id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/Classes/" +
      obj.class_id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push('/login');
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function getAllBatchForClass(class_id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/Classes/" +
      class_id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push('/login');
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function addToBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/" +
      obj.batch_id +
      "/AttachUsers",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deleteFromBatch(obj) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/" +
      obj.batch_id +
      "/DetachUsers",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deleteBatch(id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },

    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UserGroups/" + id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deleteClass(id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },

    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Classes/" + id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function loadReviewQuestions(sectionId) {
  const currentUser = authenticationService.currentUserValue;

  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/ExamSections/" +
      sectionId +
      "/Questions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      // console.log(data)
      return data;
    })
    .catch((error) => {});
}

function loadReviewQuestionsPractice(practiceId) {
  const currentUser = authenticationService.currentUserValue;

  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" +
      practiceId +
      "/Questions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      // console.log(data)
      return data;
    })
    .catch((error) => {});
}

function loadSections(examId) {
  const currentUser = authenticationService.currentUserValue;

  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" + examId,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      // console.log(data)
      return data;
    })
    .catch((error) => {});
}

function loadSectionsForPractice(practiceId) {
  const currentUser = authenticationService.currentUserValue;

  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" + practiceId,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      authenticationService.logout();
      window.location.reload(true);
    })
    .then((data) => {
      // console.log(data)
      return data;
    })
    .catch((error) => {});
}

function getQuestion(question_id) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(INSTITUTE_API + "/" + currentUser.language_code + "/Questions/" + question_id, requestOptions)
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // authenticationService.logout();
      // window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {});
}

function loadComplexity() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-admin/v1/QuestionTypes",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}
function deleteQuestions(questionArray) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify({ idList: questionArray }),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(INSTITUTE_API + "/" + currentUser.language_code + "/Questions", requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deletePractice(practiceId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" + practiceId,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function deleteExam(examId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" + examId,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createQuestion(question) {
  // console.log("here");
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(question),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Questions",
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function attachSectionToExam(examId, sections) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(sections),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" + examId + "/Sections",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function getExamDetails(examId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" + "Exams/" + examId,
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {});
}

function getPracticeDetails(practiceId) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" + practiceId,
    requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      window.location.reload(true);
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {});
}

function arrangeSectionsFromExam(examId, sections) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(sections),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Exams/" +
      examId +
      "/ArrangeSections",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function attachQuestionsToExam(examId, questions) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(questions),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/ExamSections/" +
      examId +
      "/AttachQuestions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function attachQuestionsToPractice(practiceId, questions) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(questions),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/Practices/" +
      practiceId +
      "/AttachQuestions",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createExam(exam) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(exam),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Classes/" +
      currentClass +
      "/Exams",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function createPractise(obj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(obj),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/En/Classes/" +
      currentClass +
      "/Practices",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function updateExam(exam) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(exam),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1" +
      // currentUser.language_code +
      "/Exams/" +
      exam.id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function updateBookMark(questionArray, isBookmarkValue) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify({
      idList: questionArray,
      isBookmark: isBookmarkValue,
    }),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(INSTITUTE_API + "/" + currentUser.language_code + "/Questions/Bookmark", requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

async function loadQuestionTypes() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  const response = await fetch(LOAD_QUESTIONTYPES, requestOptions);
  const data = await response.json();
  return data;
}

function getQuestionTypes(questionType) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    "https://api.odiprojects.com/api-admin/v1/QuestionTypes/" + questionType,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function loadLanguages() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  return fetch(
    // "https://api.odiprojects.com/api-admin/v1/Languages",
    LOAD_LANGAUGES,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

async function loadClasses() {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  const response = await fetch(LOAD_CLASSES, requestOptions);
  // const response = await fetch("https://api-institute.odiprojects.com/v1/Classes", requestOptions)
  const data = await response.json();
  return data;
}

function loadSubCategory(category) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Categories/" +
      category +
      "/SubCategories",
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function loadQuestions(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  // console.log(currentUser);
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  if (jsonObj.type !== undefined && jsonObj.type !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "type=" + jsonObj.type;
  }
  if (jsonObj.module_id !== undefined && jsonObj.module_id !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr =
      queryStr + "module_id=" + jsonObj.module_id + "&module=" + jsonObj.module;
  }
  if (jsonObj.complexity !== undefined && jsonObj.complexity !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "complexity=" + jsonObj.complexity;
  }
  if (jsonObj.authorId !== undefined && jsonObj.authorId !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "author_id=" + jsonObj.authorId;
  }
  if (queryStr !== "") {
    queryStr = "?" + queryStr;
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `&pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined)
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
  } else {
    queryStr = "?";
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined) {
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
    }
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    `${INSTITUTE_API}`+"/" +
      currentUser.language_code +
      "/Classes/" +
      currentClass +
      "/Questions" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      // console.log(response); // logs the response from api after fetching all the questions
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function draftExam(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      "Classes/" +
      currentClass +
      "/DraftExams" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function draftPractice(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/DraftSubjectPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function livePractice(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/LiveSubjectPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function upcomingPractice(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UpcomingSubjectPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function draftPracticeCategory(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/DraftCategoryPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function livePracticeCategory(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/LiveCategoryPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function upcomingPracticeCategory(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/UpcomingCategoryPractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function liveExam(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      "Classes/" +
      currentClass +
      "/LiveExams" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function livePracticeExams(module) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
    queryStr = queryStr + `module=${module}`;
    queryStr = queryStr + `&module_id=-1`;

  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
    "Classes/" +
      currentClass +
      "/LivePractices" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}


function upcomingExam(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      "Classes/" +
      currentClass +
      "/UpcomingExams" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function expiredExam(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  queryStr = "?";
  if (jsonObj.pageSize !== undefined) {
    queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
  }
  if (jsonObj.pageNumber !== undefined) {
    queryStr = queryStr + `&pageNumber=${jsonObj.pageNumber}`;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      "Classes/" +
      currentClass +
      "/HistoryExams" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function loadQuestionsBookmark(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  let queryStr = "";
  if (jsonObj.type !== undefined && jsonObj.type !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "type=" + jsonObj.type;
  }
  if (jsonObj.module_id !== undefined && jsonObj.module_id !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr =
      queryStr + "module_id=" + jsonObj.module_id + "&module=" + jsonObj.module;
  }
  if (jsonObj.complexity !== undefined && jsonObj.complexity !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "complexity=" + jsonObj.complexity;
  }
  if (queryStr !== "") {
    queryStr = "?" + queryStr;
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `&pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined)
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
  } else {
    queryStr = "?";
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined) {
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
    }
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    INSTITUTE_API + "/" + currentUser.language_code + "/Classes/" + currentClass + "/BookmarkedQuestions" +
      queryStr,
    requestOptions
    //return fetch("https://api.odiprojects.com/api-institute/v1/en/Classes/1/Questions?module=subject&module_id=1", requestOptions
  )
    .then((response) => {
      console.log(response);
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

async function loadCategory(subject) {
  if(subject != "") {
    const currentUser = authenticationService.currentUserValue;
    console.log(subject);
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer ".concat(currentUser.jwtToken),
      },
    };
    if (currentUser.language_code === null) {
      currentUser.language_code = "En";
      currentUser.language_name = "English";
    }
    const response= await fetch( LOAD_TOPICS_PREFIX + subject + "/Categories", requestOptions )
    const data = await response.json();

    return data;
  }
}

async function loadSubjects(classes) {
  const currentUser = await authenticationService.currentUserValue;
  const currentClass = await authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  //return was in front of fetch originally
  const response = await fetch(LOAD_CLASSES + "/" + currentClass +"/Subjects", requestOptions)
  const data = await response.json();
  return data;

}

function myQuestion(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  let queryStr = "";
  if (jsonObj.type !== undefined && jsonObj.type !== "") {
    queryStr = "type=" + jsonObj.type;
  }
  if (jsonObj.module_id !== undefined && jsonObj.module_id !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr =
      queryStr + "module_id=" + jsonObj.module_id + "&module=" + jsonObj.module;
  }
  if (jsonObj.complexity !== undefined && jsonObj.complexity !== "") {
    if (queryStr !== "") queryStr = queryStr + "&";
    queryStr = queryStr + "complexity=" + jsonObj.complexity;
  }
  if (queryStr !== "") {
    queryStr = "author_id=" + currentUser.id + "&" + queryStr;
  }
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  if (queryStr !== "") {
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `&pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined)
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
  } else {
    if (jsonObj.pageSize !== undefined) {
      queryStr = queryStr + `pageSize=${jsonObj.pageSize}`;
    }
    if (jsonObj.page !== undefined) {
      queryStr = queryStr + `&pageNumber=${jsonObj.page}`;
    }
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/en/Classes/" +
      currentClass +
      "/Questions?" +
      queryStr,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      //  console.log('Success:', data);
      return data;

      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function draftQuestion(jsonObj) {
  const currentUser = authenticationService.currentUserValue;
  const currentClass = authenticationService.currentClassValue;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
  };
  let queryStr = "";
  if (jsonObj.type !== undefined && jsonObj.type !== "") {
    queryStr = "?type=" + jsonObj.type;
  }
  if (jsonObj.module_id !== undefined && jsonObj.module_id !== "") {
    if (queryStr !== "")
      queryStr =
        queryStr +
        "&module_id=" +
        jsonObj.module_id +
        "&module=" +
        jsonObj.module;
    else
      queryStr =
        "?module_id=" + jsonObj.module_id + "&module=" + jsonObj.module;
  }
  if (jsonObj.complexity !== undefined && jsonObj.complexity !== "") {
    queryStr !== ""? queryStr = queryStr + "&complexity=" + jsonObj.complexity 
    : queryStr = "?complexity=" + jsonObj.complexity;
  }
  if (queryStr !== "") {
    queryStr =
      queryStr + "&pageSize=" + jsonObj.pageSize + "&page=" + jsonObj.page;
  } else {
    queryStr =
      queryStr +
      "?pageNumber=" +
      jsonObj.page +
      "&pageSize=" +
      jsonObj.pageSize;
  }
  // console.log(queryStr);
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    INSTITUTE_API + "/" + currentUser.language_code + "/Classes/" +
      currentClass +
      "/DraftQuestions" +
      queryStr,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      // window.localStorage.clear();
      // history.push("/login");
    })
    .then((data) => {
      return data;
      // console.log("result", data.result.jwtToken);
      // sessionStorage.setItem('login', true);
      // sessionStorage.setItem('token', data.result.jwtToken);
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}

function savedDraftQuestion(id, content) {
  const currentUser = authenticationService.currentUserValue;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(content),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Questions/" +
      id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        console.log(response.json());
        return response.json();
      }
      // window.localStorage.clear();
      //  history.push('/draftQuestions');
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}
function submitcreateQuestion(id, content) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(currentUser.jwtToken),
    },
    body: JSON.stringify(content),
    // body: JSON.stringify(this.state)
    //body: JSON.stringify({ emailId:"sa@odiware.com", userPassword:"aaaa" })
  };
  if (currentUser.language_code === null) {
    currentUser.language_code = "En";
    currentUser.language_name = "English";
  }
  return fetch(
    "https://api.odiprojects.com/api-institute/v1/" +
      currentUser.language_code +
      "/Questions/" +
      id,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        // history.push("/draftQuestions");
        return response.json();
        // return response.json();
      }
      // window.localStorage.clear();
    })
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch((error) => {
      //  console.error('Error:', error);
    });
}
