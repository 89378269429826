import React from 'react';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Input, Button, Alert, Typography, Select, Image } from 'antd';
import { authenticationService } from '../../_services';
import { useTranslation } from 'react-i18next';

import Background from './Background';

// SVG imports here
import logo from '../assets/image/PracticeKiaLogo.svg';

// CSS imports here
import './Login.css';

const Login = (props) => {

    const { t, i18n } = useTranslation();

    const validationSchema = Yup.object().shape({
        username: Yup.string('Enter your username or email').required('Username is required'),
        password: Yup.string('Enter your password').required('Password is required')
    });

    React.useEffect(() => {
        const userAuth = authenticationService.currentUserValue;
        if (userAuth) {
            props.history.push(props.location.state || '/dashboard' );
        }
    }, []);

    function handleSubmit({ username, password }, { setStatus, setSubmitting }) {
        // This state comes from Formik
        setStatus();
        // setSubmitting is automatically set to true by Formik

        authenticationService.login(username, password)
            .then(
                user => {
                    // Dev build logging
                    // console.log(user?.id);

                    if (!user) {
                        // Technically, I should never enter this block of code and just jump to error block, 
                        // but this is written to make sure everything works in certain edge cases.
                        setStatus('Something went wrong, please try again after some time.');
                        setSubmitting(false);
                        setTimeout(() => {
                            setStatus();
                        }, 6000);

                        // Original Code - I am already on login page, why make user reload the page again?
                        // const { from } = this.props.location.state || { from: { pathname: "/login" } };
                        // this.props.history.push(from);

                    } else {
                        const { from } = props.location.state || { from: { pathname: "/dashboard" } };
                        props.history.push(from);
                    }
                },
                error => {
                    setSubmitting(false);
                    setStatus(error.message);
                }
            );
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    // function grabLangauage(e) {
    //     i18n.changeLanguage(e);
    // }

    return (
        <Row className='login-page'>
            <Col xs={0} md={0} lg={14} id='background' className='center-flex'>{/*background image here*/}
                <Background />
            </Col>
            <Col xs={24} md={24} lg={10} id='' style={{ position: "relative" }}>
                <div className="container">
                    {/* <Link to='/login' component={Typography.Link} className="redirect-link"><LeftOutlined style={{ marginRight: 6 }} />Back to Login</Link> */}
                    <div className='top-insert'>
                        {/* <Typography.Text className='mr-1' type='primary'>Change Language: </Typography.Text> */}
                        {/* <Select placeholder="Select language" size='small' defaultValue={'en'} onChange={grabLangauage}>
                            <Select.Option value="en">English</Select.Option>
                            <Select.Option value="hi">Hindi</Select.Option>
                            <Select.Option value="or">Odia</Select.Option>
                        </Select> */}
                    </div>
                    <div className="frame">
                        <div className="image-block">
                            <img src={logo} id='logo' alt='Institute Logo' />
                            <Typography.Title level={3}>Practice<span>Kea</span></Typography.Title>
                        </div>
                        <Typography.Title level={2} className='heading'>Welcome to Institute Login</Typography.Title>
                        <Typography.Text type="secondary" className='heading subtitle'>Login into your account by filling the details below</Typography.Text>
                        <form className='mb-3' onSubmit={formik.handleSubmit}>
                            <label htmlFor="username"><Typography.Title level={5}>Username<span className='required-span'>*</span>:</Typography.Title></label>
                            <Input
                                className='input-custom'
                                name="username"
                                id="username"
                                size="large"
                                placeholder="Email Address"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                            />

                            <label htmlFor="password"><Typography.Title level={5}>Password<span className='required-span'>*</span>:</Typography.Title> </label>
                            <Input.Password
                                className='input-custom'
                                name="password"
                                autoComplete='current-password'
                                id="password"
                                size="large"
                                placeholder="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                            // style={{ marginBottom: 20 }}
                            />

                            <div className='link float-right'><Link to="/reset-password">
                                Forgot Password?
                            </Link></div>

                            <Button htmlType="submit" type={"primary"} size={`large`} loading={formik.isSubmitting} style={{ marginBottom: 8, width: "100%" }}
                                onClick={() => { formik.errors.username || formik.errors.password ? formik.setStatus(formik.errors.password || formik.errors.username) : formik.setStatus() }}>
                                {formik.isSubmitting ? `Loading` : t('login')}
                            </Button>

                            {formik.status ? <Alert message={formik.status} type='error' style={{ marginBottom: 8 }} /> : <></>}

                            {/* <Typography.Paragraph>Don't have an account? <Link to="/createAccount">Click here to register!</Link> </Typography.Paragraph> */}
                        </form>
                    </div>
                    <footer>© {new Date().getFullYear()} PracticeKea</footer>
                </div>
            </Col>

        </Row>
    );
}

export default Login;
