import React from "react";
import "../CreateExamName.css";
import {
  Input,
  Button,
  Form,
  message,
  Upload,
  Space,
  Dropdown,
  Menu,
} from "antd";
import { UploadOutlined, DownOutlined } from "@ant-design/icons";
import { authenticationService } from "../../../_services";
import { selectorService } from "../../../services/selectorService";

class CreateExamName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      examName: props.selectorState.name,
      image:
        "https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg",
      update: false,
      examId: "",
      options: [],
      type: "",
      buttons: [],
      topicName: "",
      selected: {},
    };
    this.handleCreateExamName = this.handleCreateExamName.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.sendData = this.sendData.bind(this);
  }
  onTextChange = (event) => {
    this.setState({
      examName: event.target.value,
      update: true,
    });
  };

  handleSubjectButtonClick(index) {
    console.log("herre");
    let tempSubjects = [...this.state.buttons];
    tempSubjects[index].buttonState = true;
    tempSubjects = tempSubjects.map((x, idx) => {
      if (idx === index) {
        x.buttonState = true;
        return x;
      }
      x.buttonState = false;
      return x;
    });
    let selected = tempSubjects[index];
    console.log(selected);
    // let array = this.state.subjects.filter((x) => x.buttonState);
    this.setState({ buttons: tempSubjects, selected });
    // this.forceUpdate();
  }

  handleCreateExamName() {
    if (!this.state.examName || this.state.examName === "") {
      message.warning("Please enter a valid practise name");
    } else if (this.state.selected.id === undefined) {
      message.warning("Please select a subject or topic");
    } else {
      if (!this.state.update)
        this.state.examName = this.props.selectorState.name;
      let json = {
        name: this.state.examName,
        image: this.state.image,
        module: this.state.type === "Subjects" ? "Subject" : "Category",
        module_id: this.state.selected.id,
      };
      message.loading({ content: "Creating Practise Name..." });
      selectorService.createPractise(json).then((data) => {
        console.log(data);
        if (!data) {
          message.warning("Some error occured");
        } else {
          let result = data.result;
          result.current = 1;
          result.image = this.state.image;
          message.success({ content: "Practise Name Created!", duration: 3 });
          this.sendData(result);
        }
      });
    }
  }
  sendData = (result) => {
    this.props.parentCallback(result);
  };

  render() {
    console.log(this.state.buttons);
    return (
      <div className="createExamName">
        <Form className="exam-form">
          <span>Enter Practise Name</span>
          <br />
          <Space>
            <Input
              placeholder="Practise Name"
              value={this.state.examName}
              onChange={(e) => {
                this.onTextChange(e);
              }}
            />
            {/* <Upload>
              <Button type="primary" icon={<UploadOutlined />}>
                Upload
              </Button>
            </Upload> */}
          </Space>
          <br />
          <br />
          Practise Type:
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    console.log("Subjects");
                    selectorService
                      .loadSubjects()
                      .then((res) => {
                        console.log("inside", res);
                        this.setState({
                          options: isNaN(parseInt(res.result.length))
                            ? []
                            : res.result,
                          type: "Subjects",
                          buttons: isNaN(parseInt(res.result.length))
                            ? []
                            : res.result,
                        });
                      })
                      .catch((err) => console.log(err));
                  }}
                  key="0"
                >
                  Subjects
                </Menu.Item>
                <Menu.Item
                  onClick={(e) => {
                    console.log("Topics");
                    selectorService
                      .loadSubjects()
                      .then((res) => {
                        console.log(res);

                        this.setState({
                          options: isNaN(parseInt(res.result.length))
                            ? []
                            : res.result,
                          type: "Topics",
                          buttons: [],
                          selected: {},
                        });
                      })
                      .catch((err) => console.log(err));
                  }}
                  key="1"
                >
                  Topics
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              href="#"
            >
              {this.state.type === "" ? (
                <>
                  Choose here <DownOutlined />
                </>
              ) : (
                this.state.type
              )}
            </a>
          </Dropdown>
          {this.state.type === "Subjects" ? (
            <></>
          ) : (
            <>
              <br />
              <br />
              Topics :
              <Dropdown
                overlay={
                  <Menu>
                    {this.state.options.map((x, idx) => {
                      return (
                        <Menu.Item
                          onClick={(e) => {
                            console.log("Topic");
                            selectorService
                              .loadCategory(x.id)
                              .then((res) => {
                                this.setState({
                                  buttons: res.result,
                                  topicName: x.name,
                                });
                              })
                              .catch((err) => console.log(err));
                          }}
                          key={idx}
                        >
                          {x.name}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                trigger={["click"]}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  href="#"
                >
                  {this.state.topicName === "" ? (
                    <>
                      Choose here <DownOutlined />
                    </>
                  ) : (
                    this.state.topicName
                  )}
                </a>
              </Dropdown>
            </>
          )}
          {this.state.buttons.length === 0 ? (
            <></>
          ) : (
            <>
              <br />
              <Space>
                {this.state.buttons.map((subject, index) => {
                  return (
                    <Button
                      onClick={() => this.handleSubjectButtonClick(index)}
                      className={
                        subject.buttonState
                          ? "subjectButtonTrue"
                          : "subjectButtonFalse"
                      }
                      style={{
                        color: subject.buttonState ? "#1890FF" : "#cccccc",
                        borderColor: subject.buttonState
                          ? "#1890FF"
                          : "#cccccc",
                      }}
                      key={index}
                      title={subject.name}
                    >
                      {subject.name}
                    </Button>
                  );
                })}
              </Space>
            </>
          )}
          <br />
          <Button
            type="primary"
            onClick={(e) => {
              console.log("inside");
              this.handleCreateExamName();
            }}
          >
            Continue
          </Button>
        </Form>
      </div>
    );
  }
}

export default CreateExamName;
