import {
  Steps,
  Button,
  message,
  Layout,
  Input,
  List,
  Avatar,
  Skeleton,
  Row,
  Col,
  Empty,
  Checkbox,
  Transfer,
} from "antd";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Siderc } from "../Main/Siderc";
import React from "react";
import "./CreateBatch.css";
import { Headerc } from "../Main/Headerc";
import { Link } from "react-router-dom";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content } = Layout;

class CreateBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batchName: "",
      batches: [],
      step: 1,
      mockData: [],
      targetKeys: [],
    };
  }
  componentDidMount() {
    selectorService
      .getAllBatch({ class_id: sessionStorage.getItem("currentClass") })
      .then((res) => {
        let batches = res.result;
        console.log(batches);

        this.setState({
          batches,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  render() {
    let header = this.state.step === 1 ? "Create " : this.state.batchName + " ";
    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">View Batches</Layout>
            <Layout className="createExamLayout">
              <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
                <Row>
                  {this.state.batches.map((x, idx) => {
                    if (idx % 3 === 0)
                      return (
                        <Col span={7}>
                          <div
                            key={idx}
                            style={{
                              minHeight: "7vh",
                              backgroundColor: "#F5F6F7",
                              marginBottom: "10%",
                              padding: "2vh",
                            }}
                          >
                            <strong>{x.name}</strong>
                            <span style={{ marginLeft: "2%" }}>
                              {"- (" + x.student_count + " Students)"}
                            </span>
                            <Link
                              onClick={() => {
                                // submit = (e,idx,x) => {

                                confirmAlert({
                                  title: "Confirm to submit",
                                  message:
                                    x.student_count === 0
                                      ? "Are you sure you want to delete " +
                                        x.name +
                                        " batch"
                                      : x.name +
                                        " consists of students. Are you sure you want to delete this batch",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        selectorService
                                          .deleteBatch(
                                            this.state.batches[idx].id
                                          )
                                          .then((res) => {
                                            let batches = this.state.batches.filter(
                                              (y) =>
                                                y.id !==
                                                this.state.batches[idx].id
                                            );
                                            this.setState({ batches }, () => {
                                              message.success(
                                                "Deleted Successfully"
                                              );
                                            });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      },
                                    },
                                    {
                                      label: "No",
                                      onClick: () => {},
                                    },
                                  ],
                                });
                                // };
                                // selectorService
                                //   .deleteBatch(this.state.batches[idx].id)
                                //   .then((res) => {
                                //     let batches = this.state.batches.filter(
                                //       (x) => x.id !== this.state.batches[idx].id
                                //     );
                                //     this.setState({ batches });
                                //   })
                                //   .catch((err) => {
                                //     console.log(err);
                                //   });
                              }}
                              style={{ float: "right", marginLeft: "2%" }}
                              to="#"
                            >
                              <DeleteTwoTone />
                            </Link>

                            <Link
                              style={{ float: "right" }}
                              to={{
                                pathname: "/createbatch",
                                state: this.state.batches[idx],
                              }}
                            >
                              <EditTwoTone />
                            </Link>
                          </div>
                        </Col>
                      );
                    else
                      return (
                        <Col span={7} offset={1}>
                          <div
                            key={idx}
                            style={{
                              minHeight: "7vh",
                              backgroundColor: "#F5F6F7",
                              marginBottom: "10%",
                              padding: "2vh",
                            }}
                          >
                            <strong>{x.name}</strong>
                            <span style={{ marginLeft: "2%" }}>
                              {"- (" + x.student_count + " Students)"}
                            </span>
                            <Link
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to submit",
                                  message:
                                    x.student_count === 0
                                      ? "Are you sure you want to delete " +
                                        x.name +
                                        " batch"
                                      : x.name +
                                        " consists of students. Are you sure you want to delete this batch",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        selectorService
                                          .deleteBatch(
                                            this.state.batches[idx].id
                                          )
                                          .then((res) => {
                                            let batches = this.state.batches.filter(
                                              (y) =>
                                                y.id !==
                                                this.state.batches[idx].id
                                            );
                                            this.setState({ batches }, () => {
                                              message.success(
                                                "Deleted Successfully"
                                              );
                                            });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      },
                                    },
                                    {
                                      label: "No",
                                      onClick: () => {},
                                    },
                                  ],
                                });
                              }}
                              style={{ float: "right", marginLeft: "2%" }}
                              to="#"
                            >
                              <DeleteTwoTone />
                            </Link>
                            <Link
                              style={{ float: "right" }}
                              to={{
                                pathname: "/createbatch",
                                state: this.state.batches[idx],
                              }}
                            >
                              <EditTwoTone />
                            </Link>
                          </div>
                        </Col>
                      );
                  })}
                  {this.state.batches.length === 0 ? (
                    <div style={{ width: "100%" }}>
                      <Empty />
                    </div>
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default CreateBatch;
