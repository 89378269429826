import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Row,Col, Layout, message} from 'antd';
import { authenticationService } from '../../_services';
import './Login.css';
import {Input, Button, Checkbox} from 'antd';
import { Link,Redirect} from "react-router-dom";
import logo from '../assets/image/logo.png'

class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activated: false,
            message: ''
          };
    }

    componentDidMount() {
        //window.addEventListener('load', this.handleLoad);
        const str = window.location.href;
        const act = str.substring(str.indexOf('/ActivateAccount/') + 17);
        authenticationService.authenticate(act)
        .then((res) => {
            if(res !== undefined){
                console.log("User Activated ");
                console.log(res);
                if(res.status.code > 0){
                this.setState({
                    activated : true,
                    message : res.status.message[0]
                })
                }else{
                    this.setState({
                        activated : false,
                        message : res.status.message[0]
                    })
                }
             }else{
                this.setState({
                    activated : false,
                    message : "User Not Activated"
                })
             }
          })
          .catch((err) => {
              console.log(err)
            });

      }

    render() {
        return (
                <Row>
                <Col flex='66.66%' id='background'>

                </Col>
                <Col flex='33.33%' id='form'>
                            <div className="row LoginPage">
                            <Col flex='100%' >
                            {/* <Col span={6} offset={3}> */}
                            <Row>
                            <Col span={8}></Col>
                            <Col span={8}><img src={logo} id='logo'/></Col>
                            <Col span={8}></Col>
                            </Row>
                            
                                        {this.state.activated?<Row>
                        <Col span={6}></Col>
                                <Col span={16} offset={3}>
                                <div className="flex-item">
                                    <label  id='float' className='authenticateMailMsg'>{this.state.message}</label>
                                    <br/>
                                    <div className='flex-item' style={{float:'left',marginLeft:'17px',position:'fixed'}}>
                                <Link to="/login">Login</Link>
                                </div>
                                </div>
                                <br /><br /><br/>
                                </Col>
                    </Row>:<Row>
                        <Col span={6}></Col>
                                <Col span={16} offset={3}>
                                <div className="flex-item">
                                    <label  id='float' className='authenticateMailMsg'>{this.state.message}</label>
                                    <br/>
                                    <div className='flex-item' style={{float:'left',marginLeft:'17px',position:'fixed'}}>
                                
                                </div>
                                </div>
                                <br /><br /><br/>
                                </Col>
                    </Row>}
                                </Col>
                               
                            </div>



                     </Col>
                       
                </Row>
               
        // </div>
        )
    }
}

export default Authenticate;
