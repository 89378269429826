import React from "react";
import "./AttemptExam.css";
import { authenticationService } from '../../../../_services';
import { selectorService } from "../../../../services/selectorService";
import { Layout, Avatar, Button, List, Tabs, Card, Collapse, Statistic, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import QuestionDetail from "./QuestionDetail";
import parse from "html-react-parser";

const { Content } = Layout;
const { Header } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Countdown } = Statistic;
class AttemptExam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      exam_id: 'props.location.state.examId',
      showQuestion: false,
      activeDetailQuestion:{},
      activeQuestionIndex:-1,
      pauseUpdate: false
    };
    this.onGetExamAttempt = this.onGetExamAttempt.bind(this);
    this.callbackFunction = this.callbackFunction.bind(this);
    this.callbackFunctionNext = this.callbackFunctionNext.bind(this);
    this.sendExamUpdate =this.sendExamUpdate.bind(this);
    this.stopExamUpdate = this.stopExamUpdate.bind(this);
    this.updateExamStatus = this.updateExamStatus.bind(this);
    this.onSubmitExam = this.onSubmitExam.bind(this);
    this.onPauseExam = this.onPauseExam.bind(this);
    this.getExamModelToUpdate = this.getExamModelToUpdate.bind(this);
  }

  componentDidMount() {
    this.onGetExamAttempt();
    this.sendExamUpdate();
   
  }

  getExamModelToUpdate(){
    let tempAllQuestions = this.state.allQuestions;
    let questionsArray = [];
    if(tempAllQuestions === undefined)
      return;
    tempAllQuestions.map((question, index) => {
      let quest = {};
      quest.question_id = question.id;
      quest.answer_duration = 0;
      quest.is_reviewed = question.isReviewMarked;
      quest.is_visited = question.isVisited;
      let options = question.options;
      let answers =[];
      for (let i = 0; i < options.length; i++) {
        if(options[i].isSelected){
          let ans = {}
          ans.id = options[i].id;
          answers.push(ans);
      }
      
    }
    quest.answers = answers;
    if(quest.is_visited)
      questionsArray.push(quest);
  })
  return questionsArray;
  }

  
  onPauseExam(){

    let questionsArray = this.getExamModelToUpdate();
   
        this.stopExamUpdate();
      selectorService.pauseExam(this.state.attempt_id).then((data) => {
  
        console.log("Exam Paused");
      });
    
    }

  onSubmitExam(){
    let questionsArray = this.getExamModelToUpdate();    
      this.stopExamUpdate();
    selectorService.endExam(this.state.attempt_id,questionsArray).then((data) => {
      console.log(data);
      console.log("Exam Ended");
    });
    
    
  }


  stopExamUpdate(){
    clearInterval(this.interval);
  }

  componentWillUnmount(){
   this.stopExamUpdate();
  }

  sendExamUpdate(){
     this.interval = setInterval(() => {
      if(this.state.pauseUpdate){
        this.stopExamUpdate();
      }
      else{
        this.updateExamStatus();
        console.log("UpdateAnswer");

      }
    }, 60000);
  }

  updateExamStatus(){
    let questionsArray = this.getExamModelToUpdate();

    console.log(questionsArray);
    
    selectorService.updateExamStatus(this.state.attempt_id,questionsArray).then((data) => {
      const result = data.result;
      
      this.setState({
        time_left: result,
      });
      console.log(result);
    });
  }

  showDetailQuestion(question) {
    question.isVisited = true;
    this.state.sections[question.sectionIndx].questions[question.secQIndex] = question;
    this.state.allQuestions[question.index-1] = question;
    let tempSections = this.state.sections;
    let tempAllQuestions = this.state.allQuestions;
    this.setState({
      showQuestion: true,
      activeDetailQuestion: question,
      activeQuestionIndex: question.index-1,
      sections: tempSections,
      allQuestions: tempAllQuestions
    })
    this.forceUpdate();
  }

  onQuestionClick(index){
    let q =this.state.allQuestions[index-1];
    this.showDetailQuestion(q);
  }

  onTabPaneClick(){
    this.setState({
      showQuestion: false,
      activeDetailQuestion: {},
      activeQuestionIndex: -1
    })
  }


  onGetExamAttempt() {
    var attemptId;

      selectorService.createExamAttempt(this.props.location.state.examId).then((data) => {
        const result = data.result;
        attemptId = result.attempt_id;
        let index1 = 0;
        selectorService.getExamAttempt(attemptId).then((data) => {
          const result = data.result;

          let sections1 = [];
          let questionsArray = [];
          sections1 = result.sections.map((item, secIndex) => {
            let quest = [];
            quest = item.questions.map((question, index) => {
              index1 = index1 + 1
              question.index = index1;
              question.sectionIndx = secIndex;
              question.secQIndex = index;
              questionsArray.push(question);
              return question;
            })
           
            item.questions = quest;
            return item
          });
          this.setState({
            attempt_id: attemptId,
            exam_id: result.exam_id,
            exam_language: result.exam_language,
            exam_name: result.exam_name,
            sections: sections1,
            time_left: result.time_left,
            deadline:  Date.now() + result.time_left*1000,
            allQuestions:questionsArray
          });

        });

      });
    


  };

  callbackFunction = (question) => {
    //  console.log("Parent recieved Selector Data: "+ childData);
    let q = question;
    let indx = q.index;
    let secQuesIndx = q.secQIndex;
    this.state.sections[q.sectionIndx].questions[secQuesIndx] =q;
    this.state.allQuestions[indx-1] = q;
    let tempSections = this.state.sections;
    let tempAllQuestions = this.state.allQuestions;
    this.setState({
      showQuestion: true,
      sections: tempSections,
      allQuestions: tempAllQuestions
    })
 }

 callbackFunctionNext = (childData) => {
  //  console.log("Parent recieved Selector Data: "+ childData);
  let q = childData;
  let indx = q.index;
  let nxtQuestion = this.state.allQuestions[(indx)];
  this.showDetailQuestion(nxtQuestion);
}
  render() {
    let listItems = "";
    let accordions = "";
    let activeQuestion ="";
    let total_notVisited = 0;
    let total_notAnswered = 0;
    let total_Answered = 0;
    let total_AnsweredReviwed = 0;
    let total_Reviwed = 0;
    let questionStatusLabel ={"QuesttionStatusNotAnswered":"Not Answered","QuesttionStatusAnswered":"Answered","QuesttionStatusReview":"To Review","QuesttionStatusAnsweredReview":"Answered and To Review"};
    if (this.state.sections !== undefined) {
      let tempSections = this.state.sections.map((item, index) =>{
        let tempQuestions = item.questions.map((question) => {
          let isAnswered = false;
          let options = question.options;
          let answered =[];
          for (let i = 0; i < options.length; i++) {
            if(options[i].isSelected){
              answered.push(options[i]);
          }
       }
          if(answered.length>0)
             isAnswered = true;
           let classN = "QuesttionStatusNotVisited";
           if(question.isVisited)
                classN = "QuesttionStatusNotAnswered";
          if(isAnswered && !question.isReviewMarked)
                classN = "QuesttionStatusAnswered";
          if(question.isReviewMarked && isAnswered){
                  classN = "QuesttionStatusAnsweredReview";
          }
           if(question.isReviewMarked && !isAnswered){
            classN = "QuesttionStatusReview";
           }
           question.classN = classN;
           question.statusLabel = questionStatusLabel[classN];
              if(classN === "QuesttionStatusReview")
                  total_Reviwed=total_Reviwed+1;
              if(classN === "QuesttionStatusAnsweredReview")
                total_AnsweredReviwed=total_AnsweredReviwed+1;    
              if(classN === "QuesttionStatusAnswered")
                total_Answered=total_Answered+1;   
              if(classN === "QuesttionStatusNotAnswered")
                total_notAnswered=total_notAnswered+1;    
              if(classN === "QuesttionStatusNotVisited")
                total_notVisited=total_notVisited+1;               
           return question;
        })
        item.questions = tempQuestions;
        return item;
      })

      this.state.sections = tempSections;
      let index1 = 0;
      activeQuestion = <Layout><QuestionDetail totalQuestion={this.state.allQuestions.length} question={this.state.activeDetailQuestion} parentCallbackNext = {this.callbackFunctionNext} parentCallback = {this.callbackFunction}/></Layout>;
      listItems = this.state.sections.map((item, index) =>

        <TabPane tab={item.subject_name} key={index} >
          {
            this.state.showQuestion?activeQuestion:
          <Row gutter={40} className="AttemptQuestionGrid">
            {item.questions.map((question, index) => {
              let title = <><Avatar className={question.classN}>{question.index}</Avatar><div className='Outerline'>
                <div className={"HeaderQuestionStatusLabel "+question.classN+"Title"}>{question.statusLabel}</div>
                
                </div></>

              return <Col key={question.id} >
                <Card title={title}     actions={[
                   question.type_text
                  ]} className={"QuestionCard "+question.classN+"Card"} onClick={this.showDetailQuestion.bind(this,question)}>
                  <p>{parse(question.question_text)}</p>
        
                </Card>
              </Col>
            })}
          </Row>
    }






        </TabPane>


      );
       
      

      accordions = this.state.sections.map((item, index) => 
        <Panel header={item.subject_name} key={index}>
          <Row gutter={20}>
            {item.questions.map((question) => {
              return <Col key={question.id}>
              <Avatar className={question.classN}   onClick={this.onQuestionClick.bind(this,question.index)}>{question.index}</Avatar>
          </Col>})}
          </Row>






        </Panel>


      );
    }
    return (
      <>
        <Layout>
          <Layout>
            <Header className="header">
              <div style={{ paddingRight: 10, display: 'inline' }} className="ExamNameHeader">
                <span >{this.state.exam_name}</span>
              </div>
              <div className="ExamTimeHeader">
                <Countdown title="Time Left" value={this.state.deadline} onFinish={this.onSubmitExam}  />
              </div>
              <div style={{ paddingLeft: 10, display: 'inline' }}>
                <Button onClick={this.onPauseExam} style={{ marginRight: 8 }}>Leave</Button>
              </div>
              <div style={{ paddingRight: 10, display: 'inline' }}>
                <Button type="primary" onClick={this.onSubmitExam}
                  style={{ marginRight: 8 }}>Submit</Button>
              </div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <Layout className="MainExamLayout">
                <Layout className="createExamLayoutLeft">{
                   <Tabs defaultActiveKey="1" size={"large"} type={"line"} style={{ marginBottom: 32 }} onTabClick={this.onTabPaneClick.bind(this)}>
                  { listItems }</Tabs>
                   
                  
  }
                </Layout>
                <Layout className="createExamLayoutRight">
                  <div>
                    <table className="ExamStatusTable">
                      <tbody>
                        <tr >
                          <td><span className="ExamAvatar"><Avatar className="StatusAvatar QuesttionStatusNotVisited"  >{total_notVisited}</Avatar><span className="StatusLabel">{" Not Visited "}</span></span></td>
                          <td><span className="ExamAvatar"><Avatar className="StatusAvatar QuesttionStatusNotAnswered"  >{total_notAnswered}</Avatar><span className="StatusLabel">{" Not Answered "}</span></span></td>
                        </tr>
                        <tr>
                          <td><span className="ExamAvatar"><Avatar className="StatusAvatar QuesttionStatusAnswered">{total_Answered}</Avatar><span className="StatusLabel">{" Answered "}</span></span></td>
                          <td><span className="ExamAvatar"><Avatar className="StatusAvatar QuesttionStatusReview"  >{total_Reviwed}</Avatar><span className="StatusLabel">{" To Review "}</span></span></td>
                        </tr>
                        <tr>
                          <td><span className="ExamAvatar"><Avatar className="StatusAvatar QuesttionStatusAnsweredReview">{total_AnsweredReviwed}</Avatar><span className="StatusLabel">{" Answered & To Review "}</span></span></td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                  <div>
                    <Collapse accordion>
                      {accordions}
                    </Collapse>
                  </div>
                </Layout>
              </Layout>
            </Content>
          </Layout>
        </Layout>
      </>
    )
  };
};

export default AttemptExam;
