import React from 'react';
import './CreateExamName.css';
import { Input, Button, Form, message, Upload, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { authenticationService } from '../../_services';
import { selectorService } from '../../services/selectorService';


class CreateExamName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      examName: props.selectorState.name,
      image: 'https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg',
      update: false,
      examId: ''
    };
    this.handleCreateExamName = this.handleCreateExamName.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.sendData = this.sendData.bind(this);
  }

  onTextChange = (event) => {
    this.setState({
      examName: event.target.value,
      update: true
    });
  }

  handleCreateExamName() {
    if (!this.state.examName || this.state.examName === '') {
      message.warning('Please enter a valid exam name');
    } else {
      if (!this.state.update) this.state.examName = this.props.selectorState.name;
      let json = {
        name: this.state.examName,
        image: this.state.image,
        examtype_id: '1'
      }
      message.loading({ content: 'Creating Exam Name...' });
      selectorService.createExam(json).then(data => {
        let result = data.result;
        // console.log(result);
        result.current = 1;
        result.image = this.state.image;
        message.success({ content: 'Exam Name Created!', duration: 3 });
        this.sendData(result);
      });
    }
  }

  sendData = (result) => {
    this.props.parentCallback(result);
  }

  render() {
    return (
      <div className='createExamName'>
        <Form className="exam-form">
          <span>Enter Exam Name</span>
          <br />
          <Space>
            <Input placeholder="Exam Name" value={this.state.examName} onChange={e => { this.onTextChange(e) }} />
            {/* <Upload >
          <Button type="primary" icon={<UploadOutlined />}>Upload</Button>
        </Upload> */}
          </Space>
          <br />
          <Button type="primary" onClick={e => { this.handleCreateExamName() }}>Continue</Button>
        </Form>
      </div>
    )
  };
};

export default CreateExamName;
