import React from "react";
import "../SelectSubjects.css";
import { Button, Form, Switch, Drawer, message } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { authenticationService } from "../../../_services";
import { selectorService } from "../../../services/selectorService";
import DragDropContainer from "../DragNDrop/ReviewContainerPractice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isEqual } from "lodash";

let subject = [];

class ReviewQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      classes: props.sectionState.classes,
      module_id: props.sectionState.subject_id,
      section_id: props.sectionState.module_id,
      module: props.sectionState.module,
      subjectId: props.sectionState.subject_id,
      examId: props.sectionState.examId,
      loaded: false,
      questions: [],
      totalQuestion: 0,
    };
    this.handleLoadQuestions = this.handleLoadQuestions.bind(this);
    this.detachQuestionCallback = this.detachQuestionCallback.bind(this);
    this.submitReviewQuestion = this.submitReviewQuestion.bind(this);
  }

  submitReviewQuestion() {
    let list = {};
    let cards = [];
    const questions = this.state.questions;
    for (let index = 0; index < questions.length; index++) {
      let c = {};
      c.id = questions[index].value;
      c.duration_seconds = questions[index].duration_seconds;
      // c.negative_mark = questions[index].nmark;
      cards.push(c);
    }
    list.qnsMarkList = cards;
    console.log(list, this.props.sectionState, this.props, this.state);
    // let section_id = this.props.sectionState.sections.filter(
    //   (x) => x.label === this.props.sectionState.sectionName
    // )[0].id;
    message.loading({ content: "Submiting Review Question..." });
    selectorService
      .markQuestionsPractice(this.state.examId, list)
      .then((data) => {
        console.log(data);
        message.success({ content: "Review Question Submitted!", duration: 3 });
        this.state.loaded = false;
        this.props.handleCancel();
      });
  }

  callbackFunctionDragNDrop = (childData) => {
    console.log("Parent recieved Selector Data: " + JSON.stringify(childData));
    this.setState({
      questions: childData,
    });
  };
  detachQuestionCallback(index) {
    let quesJson = {};
    let question = this.state.questions[index];
    let quesId = [];
    quesId.push(question.value);
    quesJson.idList = quesId;
    message.loading({ content: "Detaching Question..." });
    selectorService
      .detachQuestionPractice(this.state.examId, quesJson)
      .then((data) => {
        console.log(data);
        let questionsL = this.state.questions;
        questionsL = questionsL.splice(index + 1);
        this.setState({
          questions: questionsL,
        });
        message.success({ content: "Question Detached!", duration: 3 });
      });
  }

  componentDidMount() {
    this.handleLoadQuestions();
  }

  componentDidUpdate(prevprops) {
    console.log(
      this.props.sectionState.sectionName,
      prevprops.sectionState.sectionName,
      prevprops.sectionState.sectionName !== this.props.sectionState.sectionName
    );
    if (
      this.props.sectionState.sectionName !== prevprops.sectionState.sectionName
    ) {
      this.handleLoadQuestions();
    }
  }

  removeTags(str) {
    if (str === null || str === "") return "";
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  async handleLoadQuestions() {
    await selectorService
      .loadReviewQuestionsPractice(this.state.examId)
      .then((data) => {
        console.log("Success:", data);
        const result = data.result;
        this.state.questions = [];
        const questions =
          result.questions === undefined ? [] : result.questions;
        this.state.totalQuestion = result.total_count;

        for (let index = 0; index < questions.length; index++) {
          let c = {};
          c.label = this.removeTags(questions[index].title);
          c.value = questions[index].id;
          c.index = index;
          c.duration_seconds = questions[index].duration_seconds;
          // c.nmark = questions[index].incorrect_mark;
          this.state.questions.push(c);
        }
        this.state.loaded = true;
        this.forceUpdate();
      });
  }

  render() {
    console.log(this.state.questions);
    return (
      <div className="ReviewQuestionsDiv">
        {this.state.loaded ? (
          <div className="ReviewQuestionsDiv">
            <DndProvider backend={HTML5Backend}>
              <DragDropContainer
                modelState={this.state.questions}
                parentCallback={this.callbackFunctionDragNDrop}
                detachQuestionCallback={this.detachQuestionCallback}
              />
            </DndProvider>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ReviewQuestions;
