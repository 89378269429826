import { Typography } from 'antd';
import React, { useState, useEffect, useRef } from 'react';

const normalDotStyle = {
    width: "10px",
    opacity: "40%"
}

const selectedDotStyle = {
    width: "20px",
    opacity: "100%"
}

const Carousel = ({ imageArr, interval, headings, subtitles }) => {
    const [image, setImage] = useState(imageArr[0]);
    const [dot, setDot] = useState(0);

    const mounted = useRef(true);

    const imageArray = useRef(imageArr);

    useEffect(() => {
        // effect
        let i = 0;
        let intervalId;
        if (mounted.current) {
            intervalId = setInterval(() => {
                i++;
                if (i >= imageArr.length) i = 0;
                setImage(() => imageArray.current[i]);
                setDot(() => (i));
            }, interval);
        }
        return () => {
            // cleanup
            mounted.current = false;
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const dots = document.createElement("div");
            dots.classList.add('center-flex');
            dots.style.gap = "5px";
            for (let i = 0; i < imageArr.length; i++) {
                const dot = document.createElement("div");
                dot.classList.add('track-dot');
                dot.style.height = "6px";
                dot.style.width = "10px";
                dot.style.opacity = "40%";
                dot.style.backgroundColor = "white";
                dot.style.borderRadius = "8px";
                dot.style.transition = "all 400ms";
                dots.appendChild(dot);
            }
            // dots.style.zIndex = "10000";
            mounted.current && document.querySelector('#carousel-track').appendChild(dots);
        }, 0);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const trackDots = mounted.current? document.querySelectorAll(".track-dot") : [];
            for (let oneDot of trackDots) {
                for (let prop of Object.keys(normalDotStyle)) {
                    oneDot.style[prop.toString()] = normalDotStyle[prop.toString()];
                }
            }
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if(mounted.current)
                    {
                        for (let prop of Object.keys(selectedDotStyle)) {
                        trackDots[dot].style[prop.toString()] = selectedDotStyle[prop.toString()];
                        }
                    }
                });
            });
        }, 0);
        return () => {
            //cleanup
        }
    }, [dot]);

    return (
        <>
            <div style={{ position: 'relative', height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} id="carousel-slick" className="mb-2">
                <img src={image} className="mx-auto" width={400} alt="Feature" />
            </div>
            <div style={{ width: "60%", textAlign: 'center' }} id="carousel-text" className="mb-2">
                <Typography.Title level={4}>{headings[dot]}</Typography.Title>
                <Typography.Text>{subtitles[dot]}</Typography.Text>
            </div>
            <div style={{ position: 'absolute', bottom: "40px", width: 450, height: 10 }} id="carousel-track" className="center-flex">

            </div>
        </>
    );
}

export default Carousel;
