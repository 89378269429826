import {
  Steps,
  Button,
  message,
  Layout,
  List,
  Avatar,
  Skeleton,
  Empty,
  Drawer,
  Transfer,
} from "antd";
import "./DraftExam.css";
import { Siderc } from "../Main/Siderc";
import React from "react";
import { Headerc } from "../Main/Headerc";
import { Link } from "react-router-dom";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";
import { authenticationService } from "../../_services";

const { Content } = Layout;

class LiveExam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total_count: 0,
      total_pages: 0,
      processing: true,
      associateBatchVisible: false,
      batches:[],
      selectedBatches:[],
      selectedExam: "",
    };
  }
  showDrawer = (exam) => {
    this.setState({
      associateBatchVisible: true,
      selectedExam: exam,
    }, () => {
      this.getBatches(exam);
    });
  };

  handleChangeBatch =(nextTargetKeys, direction, moveKeys) =>{
      if(direction === 'right'){
      selectorService.addBatchesToExam({
                          id: this.state.selectedExam.id,
                          batches: {idList:moveKeys},
                        })
                        .then((res) => {
                          if (!res) message.error("Some error occured");
                         })
                        .catch((err) => {
                          console.log(err);
                        });
                      }
                      else if(direction === 'left'){
                        let updatedArray = this.state.selectedBatches.filter(item => !moveKeys.includes(item));
                        selectorService.detachBatchesFromExam({
                          id: this.state.selectedExam.id,
                          batches: {idList:moveKeys},
                        })
                        .then((res) => {
                          if (!res) message.error("Some error occured");
                         
                         })
                        .catch((err) => {
                          console.log(err);
                        });
                      }
                      this.setState({
                        selectedBatches : nextTargetKeys,
                      });
    
  }

  getBatches = (exam) => {
    selectorService
      .getExamBatch({ id: exam.id })
      .then((response) => {
        console.log(response);
        this.setState({
          selectedBatches: response.result.idList.map(String),
        });
      })
      .then(() => {
        selectorService
          .getAllBatch({ class_id: authenticationService.currentClassValue })
        .then((res) => {

          let batches = res.result.map((x) => {
          const data = {
            key: `${x.id.toString()}`,
            title: `${x.name}`,
            description: `${x.name}`,
            chosen: this.state.selectedBatches.includes(x.id.toString()) ? true : false,
          };
          return data;
        })
        this.setState({
          "batches":batches,
        });
        })
      })
      .catch((err) => {
        console.log(err);
      });

  }
  handleCancel = (e) => {
    // console.log(e);

    this.hideDrawer();
  };

  handleSubmit =  (e) => {
    // console.log(e);

    this.hideDrawer();
  };
  hideDrawer = () => {
    this.setState({
      associateBatchVisible: false,
      selectedExam: "",
      selectedBatches:[]
    });
  };
  componentDidMount() {
    selectorService
      .liveExam({
        pageSize: 10,
        pageNumber: 1,
        classId: parseInt(sessionStorage.getItem("currentClass")),
      })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.result.exams,
          total_count: res.result.total_count,
          total_pages: res.result.total_pages,
          processing: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  associateBatch = (exam) =>{
    this.showDrawer(exam);
    console.log(exam);
    console.log(this.state.associateBatchVisible);
    console.log(authenticationService.currentClassValue);
    console.log(authenticationService.currentClassNameValue);
  }

  handleChange = (pageNumber, pageSize) => {
    selectorService
      .draftExam({ pageNumber: pageNumber, pageSize: pageSize, classId: 1 })
      .then((res) => {
        this.setState({
          data: res.result.exams,
          total_count: res.result.total_count,
          total_pages: res.result.total_pages,
          processing: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let drawerWidget = this.state.associateBatchVisible?<Transfer
    dataSource={this.state.batches}
    listStyle={{
      width: "50%",
      height: 300,
    }}
    targetKeys={this.state.selectedBatches}
    onChange={this.handleChangeBatch}
    render={item => item.title}
    locale={{
      itemUnit: "Batch",
      itemsUnit: "Batches",
      searchPlaceholder: "Search here",
    }}
  />:""
    if (this.state.data.length !== 0)
      return (
        <Layout>
          <Siderc />
          <Layout>
            <Headerc />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <Layout className="createExamLayoutHeader">Live Exams</Layout>
              <Layout className="createExamLayout">
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.data}
                  pagination={{
                    onChange: (page, pageSize) => {
                      this.handleChange(page, pageSize);
                    },

                    total: this.state.total_count,
                  }}
                  renderItem={(item, index) => {
                    let step = 2;
                    if (item.sections_count >= 1) step = 3;
                    if (item.sections_status !== "DRAFT") step = 4;
                    let currentTime = new Date();
                    let loginTime = new Date(item.updated_on);
                    const diff = currentTime.getTime() - loginTime.getTime();
                    // console.log(diff,loginTime,currentTime,item.updated_on);
                    var years = Math.floor(
                      diff / (1000 * 60 * 60 * 24 * 30 * 12)
                    );
                    var months = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30 * 12)) /
                        (1000 * 60 * 60 * 24 * 30)
                    );
                    var days = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30)) /
                        (1000 * 60 * 60 * 24)
                    );

                    var hours = Math.floor(
                      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    var minutes = Math.floor((diff % 3.6e6) / 6e4);
                    let updatedOn =
                      years > 0
                        ? `${years} year(s)/${months} months`
                        : months > 0
                        ? `${months} month(s)/${days} days`
                        : days > 0
                        ? `${days} day(s)/${hours} hours`
                        : minutes > 0
                        ? `${hours} hour(s)${minutes}minutes(s)`
                        : minutes + "minutes";
                    console.log(item);

                    let drawerWidget = this.state.associateBatchVisible?<Transfer
                    dataSource={this.state.batches}
                    listStyle={{
                      width: "50%",
                      height: 300,
                    }}
                    targetKeys={this.state.selectedBatches}
                    onChange={this.handleChangeBatch}
                    render={item => item.title}
                    locale={{
                      itemUnit: "Batch",
                      itemsUnit: "Batches",
                      searchPlaceholder: "Search here",
                    }}
                  />:""
                    return (
                      <List.Item
                        className="DraftExam"
                        key={index}
                        actions={[
                          <Button
                            onClick={(e, newValue) => {
                              console.log(item, e);
                            }}
                            type="primary"
                          >
                            <Link
                              to={{
                                pathname: "",
                                state: { id: item.id, current: step - 1 },
                              }}
                            >
                              View Report
                            </Link>
                          </Button>,<Button
                            onClick={(e, newValue) => {
                             this.associateBatch(item);
                            }}
                            type="primary"
                          >
                            <Link
                              to={{
                                pathname: "",
                                state: { id: item.id, current: step - 1 },
                              }}
                            >
                              Associate Batch
                            </Link>
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                              size="large"
                              shape="square"
                              className="DraftExamImage"
                            />
                          }
                          size="large"
                          bordered="true"
                          title={parse(`<b>${item.name}</b>`)}
                          description={
                            <>
                              <div className="MyExamDiv">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Start Date</th>
                                      <th>End Date</th>
                                      <th>Durations</th>
                                      <th>Questions</th>
                                      <th>Marks</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{item.start_date}</td>
                                      <td>{item.end_date}</td>
                                      <td>{item.exam_duration}</td>
                                      <td>{item.total_questions}</td>
                                      <td>{item.total_marks}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </Layout>
            </Content>
          </Layout>
          {this.state.associateBatchVisible?<Drawer
       title = {"Associate Exam to Batch for  "+ authenticationService.currentClassNameValue + " class"}                  
       width={"100%"}
       placement="right"
       closable={true}
       onClose={this.handleCancel}
       visible={this.state.associateBatchVisible}
       footer={
         <div
           style={{
             textAlign: 'left',
           }}
         >
           <Button onClick={this.handleCancel} type="primary" style={{ marginRight: 8 }}>
             Close
           </Button>
         </div>
       }

     > 
       <div><br />
                <p style={{ fontSize: "15px" }}>Add Batches</p>
                {drawerWidget}
                </div>
     </Drawer>:null}
        </Layout>
      );
    else if (this.state.processing)
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">Live Exams</Layout>
                <Layout className="createExamLayout">
                  <List
                    itemLayout="horizontal"
                    dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    pagination={{
                      onChange: (page, pageSize) => {
                        this.handleChange(page, pageSize);
                      },

                      total: this.state.total_count,
                    }}
                    renderItem={(item, index) => {
                      // let step = 2;
                      // if (item.sections_count >= 1) step = 3;
                      // if (item.sections_status !== "DRAFT") step = 4;
                      // let currentTime = new Date();
                      // let loginTime = new Date(item.updated_on);
                      // const diff = currentTime.getTime() - loginTime.getTime();
                      // // console.log(diff,loginTime,currentTime,item.updated_on);
                      // var years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12));
                      // var months = Math.floor(
                      //   (diff % (1000 * 60 * 60 * 24 * 30 * 12)) / (1000 * 60 * 60 * 24 * 30),
                      // );
                      // var days = Math.floor(
                      //   (diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
                      // );

                      // var hours = Math.floor(diff%(1000*60*60*24) / (1000 * 60 * 60));
                      // var minutes=Math.floor((diff % 3.6e+6) / 6e+4);
                      // let updatedOn=
                      // years > 0
                      //   ? `${years} year(s)/${months} months`
                      //   : months > 0
                      //   ? `${months} month(s)/${days} days`
                      //   : days > 0
                      //   ? `${days} day(s)/${hours} hours`
                      //   :minutes>0? `${hours} hour(s)${minutes}minutes(s)`:minutes+'minutes';
                      return (
                        <List.Item
                          className="DraftExam"
                          key={index}
                          // actions={[
                          //   <Button
                          //     onClick={(e, newValue) => {
                          //       console.log(item, e);
                          //     }}
                          //     type="primary"
                          //   >
                          //     <Link
                          //       to={{
                          //         pathname: "/createExam",
                          //         state: { id: item.id, current: step - 1 },
                          //       }}
                          //     >
                          //       Recreate Exam
                          //     </Link>
                          //   </Button>,
                          // ]}
                        >
                          <Skeleton>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                                  size="large"
                                  shape="square"
                                  className="DraftExamImage"
                                />
                              }
                              size="large"
                              bordered="true"
                              title={parse(`<b>${"item.name"}</b>`)}
                              description={parse(
                                `<b>Progress - Step</b> ${"step"} <b>Updated At:</b> ${"updatedOn"}  <b>Author Name:</b> ${"item.author_name"} <b>Sections Count: </b>${"item.sections_count"}`
                              )}
                            />
                          </Skeleton>
                        </List.Item>
                      );
                    }}
                  />
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </>
      );
    else
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">Live Exams</Layout>
                <Layout className="createExamLayout">
                  <Empty />
                </Layout>

              </Content>
            </Layout>

          </Layout>
        </>
      );
  }
}

export default LiveExam;
