import React from 'react';
import './EditQuestion.css';
import { Layout, Menu, PageHeader} from 'antd';
import 'antd/dist/antd.css';

import Selector from '../Selector';
import { Siderc } from '../../Main/Siderc';
import { authenticationService } from '../../../_services';
import { Headerc } from '../../Main/Headerc';
import LayoutEdit from './LayoutEdit';
import { selectorService } from '../../../services/selectorService';
const { Content, Sider } = Layout;

class EditQuestion extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
// console.log(props.history.location.state)
  this.state = {
    currentUser: authenticationService.currentUserValue,
    top: 'topLeft',
    bottom: 'bottomRight',
    classes: "1" ,
    subject: "",
    category:"",
    questionType:"",
     //complexity:props.history.location.complexity.complexity,
    upload:false,
    id:props.history.location.state,
    // options:props.history.location.options
    options:props.history.location.options
  
    // questiondata:seletorService.getQuestion
  };
  this.handleCreateQuestion = this.handleCreateQuestion.bind(this);
 
}
  componentDidMount(){
    // selectorService.getQuestion().then(data => {
    //   console.log('option aa rha hai',data)
    // });
  }
  handleCreateQuestion(){
    this.setState({
      upload:false
    })
  }

   callbackFunction = (childData,jsonObj) => {
     console.log("Parent recieved Selector Data: "+ JSON.stringify(childData),jsonObj);
     selectorService.getQuestion(this.state.id).then(data1 => {
      console.log('Success1:', data1);
      console.log('Success1:', data1.result);
      
     
   });
   //console.log(data);
     this.setState({
      classes: childData.classes ,
      module_id: childData.module_id,
      module: childData.module,
      questionType: childData.questionType,
      complexity: childData.complexity,
      questiondata:childData.data,
      // options:props.history.location.options
    });
 }
  render() {
    console.log(this.state)
    return (
      <Layout>
        <Siderc />
      <Layout>
      <Headerc />
        <Selector parentCallback = {this.callbackFunction}/>
        <Layout className="SubSection">
          {/* <PageHeader
            className="site-page-header, SubHeader, QuestionHeader"
            title="Edit Questions"
          /> */}

          <Layout>
            <Sider className="site-layout-background">
              <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['dashboard']}
                style={{ height: '100%', borderRight: '0px solid #f0f2f5' }}
              >
                <Menu.Item key="1" onClick={this.handleCreateQuestion}> Edit Question</Menu.Item>
              
              </Menu>
            </Sider>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}>
            {/* <LayoutEdit value={this.state.id} complexity={this.state.complexity} option={this.state.options}/> */}
            <LayoutEdit value={this.state.id} complexity={this.state.complexity}/>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      </Layout>
    )
  };
};

export default EditQuestion;
