import React from 'react';
import './LayoutEdit.css';
import { Input, PageHeader, Radio, Avatar, Space, Button, Form , message} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import RichTextEditor from '../RichTextEditor';
import QuestionTable from '../DraftQuestion/QuestionTable'
import { authenticationService } from '../../../_services';
import TextArea from 'antd/lib/input/TextArea';
import { selectorService } from '../../../services/selectorService';
import axios from 'axios';
import {Link} from 'react-router-dom';

import history from '../../../history';

const alphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
let type = {};
class LayoutEdit extends React.Component {
  constructor(props) {
    super(props);
console.log(this.props.complexity)
  this.state = {
    currentUser: authenticationService.currentUserValue,
    bottom: 'bottomRight',
    classes: '' ,
    module_id: '',
    module:'',
    questionType:'',
    //complexity:this.props.complexity,
    complexity:'',
    top: 'topLeft',
    bottom: 'bottomRight',
    upload:false,
    title:"",
    answer_explanation:'',  
    source:"Manual Entry",
    options:[],
    selectedoptions:'',
    count : 0,
    reset : true,
    edit : true,
    id:this.props.value,
    question:'',
    //id: '',
    author_id: '',
    title: '',
    description: '',
    answer_explanation: '',
    //complexity_code: '',
    source: '',
    type: '',
    topic_id: '',
    status:'',
    //checked:true,
  };
  this.onAddOption = this.onAddOption.bind(this);
  this.onClickRadio = this.onClickRadio.bind(this);
  this.onTextAreaChange = this.onTextAreaChange.bind(this);
  this.onDeleteOption = this.onDeleteOption.bind(this);
  this.onTextAreaExplaination = this.onTextAreaExplaination.bind(this);
  this.handleLoadQuestionType = this.handleLoadQuestionType.bind(this);
  this.onChangeComplexity = this.onChangeComplexity.bind(this);
  this.onSaveQuestion = this.onSaveQuestion.bind(this);
  this.onSubmitQuestion = this.onSubmitQuestion.bind(this);
  this.resetState = this.resetState.bind(this);
  this.loadQuestion = this.loadQuestion.bind(this);
  }

  componentWillMount() {
    this.loadQuestion();
   // window.removeEventListener('load', this.loadQuestion)
  }

  loadQuestion(){
    const currentUser = authenticationService.currentUserValue;
  
    const requestOptions = {
       method: 'GET',
       headers: {
         'Accept': 'application/json',
         'Authorization': 'Bearer '.concat(currentUser.jwtToken)
       }
     };
     if(currentUser.language_code === null){
       currentUser.language_code = "En";
       currentUser.language_name = "English";
     }
     this.state.loading = true;
     return fetch("https://api.odiprojects.com/api-institute/v1/"+currentUser.language_code+"/Questions/"+this.state.id, requestOptions
     ).then(response => {
     console.log(response)
       if (response.ok) {
         return response.json();
       }
       authenticationService.logout();
       window.location.reload(true);
     })
       .then(data => {
           console.log(data.result)
        //  return data;
        this.setState({
            questionType:data.result.type,
            question:data.result.title,
            loading:false,
            complexity:data.result.complexity_code,
            options:data.result.options,
            //id: data.result.id,
            author_id: data.result.author_id,
            title: data.result.title,
            description: data.result.description,
            answer_explanation: data.result.answer_explanation,
            //complexity_code: data.result.complexity_code,
            source: data.result.source,
            type: data.result.type,
            topic_id: data.result.topic_id,
            status:data.result.status
          
        })
       return data.result.title;
       })
       .catch((error) => {
       });

    
  }

  resetState(){
    this.setState({
      currentUser: authenticationService.currentUserValue,
      bottom: 'bottomRight',
      classes: '' ,
      module_id: '',
      module:'',
      questionType:'',
      complexity:this.state.complexity,
      top: 'topLeft',
      bottom: 'bottomRight',
      upload:false,
      title:"",
      answer_explanation:"",
      source:"Manual Entry",
      reset:true,
      options:[],
     // options:this.props.option,
      count : 0,
      question:'',
      //checked:true,
      
    })
  }

  onSubmitQuestion(e){
    e.preventDefault()
    // const content = {
    //   type: this.state.type,
    //   subtopic_id: this.state.subtopic_id,
    //   complexity_code: this.state.complexity_code,
    //   status:this.state.status,
    //   title: this.state.title,
    //   author_id: this.state.author_id,
      
    //   description: this.state.description,
    //   answer_explanation: this.state.answer_explanation,
     
    //   source: this.state.source,
    //   tags: this.state.tags,
    
    //   options: this.state.options
    // }
    // let question = {};
    // content.title = this.state.title;
    // if(this.state.complexity!==''){
    //   content.complexity_code =this.state.complexity;
    //question.type = this.props.selectorState.questionType;
    // if(this.props.selectorState.module==='subcategory'){
    //   question.subtopic_id = this.props.selectorState.module_id;
    // }
    // if(this.state.answer_explanation!==''){
    //   content.answer_explanation = this.state.answer_explanation;
    // }
    // if(this.state.options!==[]){
    //   content.options = this.state.options;
    // }
    // content.status = "PUBLISHED";
    // content.source = this.state.source;
    // const key = 'createQuestion';
    // message.loading({ content: 'Creating Question...', key });
    // selectorService.submitcreateQuestion(content).then(data => {
    //   message.success({ content: 'Question Created!', key, duration: 3 });
    //   this.resetState();
    // });

  //}
  const content = {
    id: this.state.id,
    author_id: this.state.author_id,
    title: this.state.title,
    description: this.state.description,
    answer_explanation: this.state.answer_explanation,
    complexity_code: this.state.complexity,
    source: this.state.source,
    options: this.state.options
  }
  console.log(content);
  const key = 'saveQuestion';
  message.loading({ content: 'Publishing question...', key });
  selectorService.submitcreateQuestion(this.state.id,content).then(data => {
    console.log(data)
    message.success({ content: 'Question Created!', key, duration: 3 });
    this.resetState();
    // this.history.push('/draftQuestions')
    // BrowserRouter.push('/draftQuestions');
    console.log("Question Created!");

  });

  




  }

  onSaveQuestion(e){
    e.preventDefault()
    const content = {
      id: this.state.id,
      author_id: this.state.author_id,
      title: this.state.title,
      description: this.state.description,
      answer_explanation: this.state.answer_explanation,
     complexity_code: this.state.complexity,
      source: this.state.source,
      options: this.state.options
    }
    let question = {};
    question.title = this.state.title;
    if(this.state.complexity!==''){
      question.complexity_code =this.state.complexity;
      //question.type = this.props.selectorState.questionType;
    // if(this.props.selectorState.module==='subcategory'){
    //   question.subtopic_id = this.props.selectorState.module_id;
    // }
    if(this.state.answer_explanation!==''){
      question.answer_explanation = this.state.answer_explanation;
    }
    if(this.state.options!==[]){
      question.options = this.state.options;
    }
    question.status = "DRAFT";
    question.source = this.state.source;
    const key = 'saveQuestion';
    message.loading({ content: 'Saving Question...', key });
    selectorService.savedDraftQuestion(this.state.id,content).then(data => {
      console.log(data)
      message.success({ content: 'Question Draft Saved!', key, duration: 3 });
      this.resetState();
      // history.push('/draftQuestions');
      // this.history.push('/draftQuestions')
      // BrowserRouter.push('/draftQuestions');
      console.log("Draft Saved");

    });
  
    
  }
  };

  callbackRichFunction = (childData) => {
    console.log("Parent recieved Selector Data: "+ childData);
    this.setState({
      title: childData.editorHtml ,
      reset: false,
      question:this.state.question
   });
  }

  onChangeComplexity= (event) =>{
    this.setState({
      complexity: event.target.value
   });

  }
  componentDidMount() {
    window.addEventListener('load', this.handleLoadQuestionType.bind(this));
    this.handleLoadQuestionType();
   
  }
  handleLoadQuestionType = () =>  {
    // this.setState({
    //   options:this.props.option
    // })
    selectorService.loadQuestionTypes().then(data => {
      const result = data.result;
      type =[];
      for (let index = 0; index < result.length; index++) {
        type[(result[index]).code] = (result[index]).name;
  
      }
      this.forceUpdate();
    });
  }


  onTextAreaExplaination= (event) =>{
    this.setState({
      answer_explanation: event.target.value
   });

  }
  
  onDeleteOption = (event, index) => {
    let itemId = this.state.options[index].id;
    const items = this.state.options.filter(item => item.id !== itemId);
    this.setState({ options: items });
    this.forceUpdate();
  }
  onTextAreaChange = (event, index) => {
    let tempOptions =this.state.options;
    tempOptions[index].text = event.target.value;
    this.setState({options:tempOptions});
  }
    
  onAddOption = (event) => {
    
    let option = {text:"",isCorrect:false, id: this.state.count}
    let tempOptions =this.state.options;
    tempOptions.push(option);
    this.setState({options:tempOptions});
    this.state.count++;
   // this.state.options;
}


onClickRadio = (event, index) => {
let tempOptions =this.state.options;
let updatedIsCorrect = !tempOptions[index].isCorrect;
tempOptions.map((option, index) => (
    option.isCorrect = false
    ));
  tempOptions[index].isCorrect = updatedIsCorrect;
  this.setState({options:tempOptions});
  this.forceUpdate();

}
  render() {
      console.log(this.state)
    return (
      <div>
          
       {/* <h1 className='Question'> <span>{type[this.props.selectorState.questionType]}</span></h1> */}
        <Form className="login-form" >
      <div className="QuestionPanel" 
    //    hidden={this.props.selectorState.questionType === ''}
       >
        <PageHeader
        className="QuestionHeader"
        title='Question Details'
      />

        <div className="QuestionContent">        
          <span>Question</span>
          {this.state.loading?"":<RichTextEditor reset = {this.state.reset} edit ={this.state.edit}  placeholder={'Write Question...'} editorHtml={ this.state.question} parentCallback = {this.callbackRichFunction} />}
          <span>Correct Options</span>
          <div hidden={this.state.questionType !== 'TNF'}>
          <Radio.Group name="radiogroup" value={this.state.options&&this.state.options.length>0&&this.state.options[0].isCorrect?true:false}>
            <Radio  value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
          </div>
          <div className='MCQ' 
           hidden={this.state.questionType !== 'MCQ'&&this.state.questionType !== 'MRQ'}
          >
            
          <div className='optionsDiv' id='optionsDiv'>
          {this.state.options.map((option, index) => (
            <div style={{display:"inline-block",padding:"4px"}} key={index}>
              
              <Space align="baseline">
              <Avatar className={option.isCorrect?"radioSelected":""} gap={4} 
              shape="square" size={32} onClick={e =>{this.onClickRadio(e , index)}} 
              style={{ cursor: 'pointer'}}  >{alphabet[index]}
            </Avatar>
              
             
                 <Radio button checked={option.isCorrect} onClick={e =>{this.onClickRadio(e , index)}} >
                   <TextArea className='TextArea' defaultValue={option.text} onBlur={e =>{this.onTextAreaChange(e , index)}}></TextArea>
                   </Radio>
                 <CloseOutlined style={{cursor:'pointer'}} onClick={e =>{this.onDeleteOption(e , index)}}/>
              </Space>
            </div>
          ))}
         
         </div>
         <div className='AddOptionButton'>
          <Button onClick={this.onAddOption} type="primary" style={{ marginRight: 8 }}>+ Add New Option</Button>
          </div>
          </div>
          </div>
          
        </div>
<br/>
<div className="QuestionPanel" 
// hidden={this.props.selectorState.questionType === ''}
><PageHeader
className="QuestionHeader"
title="Other Details" 
/>

<div className="QuestionOtherContent">
<br/>
  <span>Complexity</span>
  <br/>
  <Radio.Group buttonStyle="solid" value={this.state.complexity} onChange={event=>{this.onChangeComplexity(event)}}>
    {this.state.complexity===1?<Radio.Button  value={this.state.complexity} checked={true}>Easy</Radio.Button>:<Radio.Button value="1">Easy</Radio.Button>}
    {this.state.complexity===2?<Radio.Button  value={this.state.complexity}>Medium</Radio.Button>:<Radio.Button value="2">Medium</Radio.Button>}
    {this.state.complexity===3?<Radio.Button  value={this.state.complexity}>Difficult</Radio.Button>:<Radio.Button value="3">Difficult</Radio.Button>}
      
      {/* <Radio.Button value={this.state.complexity} checked={this.state.checked}>Medium</Radio.Button>
      <Radio.Button value="3">Difficult</Radio.Button> */}
  </Radio.Group>
    <br/>
    <br/>
  <span>Explanation</span>
  <TextArea className='TextArea' placeholder="Answer Explanation" value={this.state.answer_explanation} onChange={e =>{this.onTextAreaExplaination(e)}}/>
 
  <div>
    
  </div>
  </div>

</div>
<div className='ButtonPanel' 
//  hidden={this.props.selectorState.questionType === ''}
>
  <Link to='/draftQuestions'>
  <Button onClick={this.onSaveQuestion} type="primary" style={{ marginRight: 8 }}> Save </Button>
  </Link>
  
  <Button 
  disabled={this.state.title===''|| this.state.complexity===''} 
  onClick={this.onSubmitQuestion}
  type="primary" style={{ marginRight: 8 }}> Submit </Button>
</div>
</Form>  
</div>
    )
  };
};

export default LayoutEdit;
