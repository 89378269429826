import React from "react";
import "./AttemptRevision.css";
import { authenticationService } from '../../../../_services';
import { selectorService } from "../../../../services/selectorService";
import { Layout, Avatar, Button, List, Tabs, Card, Collapse, Statistic, Col, Row,Checkbox,Radio } from 'antd';
import { Link } from 'react-router-dom';
import QuestionDetail from "../AttemptExam/QuestionDetail";
import parse from "html-react-parser";
import ReactCardFlip from 'react-card-flip';
import { Siderc } from "../../../Main/Siderc";
import { Headerc } from "../../../Main/Headerc";

const { Content } = Layout;
const { Header } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Countdown } = Statistic;
class AttemptRevision extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      exam_id: 'props.location.state.examId',
      showQuestion: false,
      activeDetailQuestion:{},
      activeQuestionIndex:-1,
      pauseUpdate: false,
      flipStatus:{},
    };
    this.onGetRevisionAttempt = this.onGetRevisionAttempt.bind(this);
    this.callbackFunction = this.callbackFunction.bind(this);
    this.callbackFunctionNext = this.callbackFunctionNext.bind(this);
    this.updateExamStatus = this.updateExamStatus.bind(this);
    this.getExamModelToUpdate = this.getExamModelToUpdate.bind(this);
    this.updateFlipStatus = this.updateFlipStatus.bind(this);
  }

  componentDidMount() {
    this.onGetRevisionAttempt();
    //this.sendExamUpdate();
   
  }

  updateFlipStatus(question,front){
    let status = this.state.flipStatus;
    status[question] = front;
    this.setState({
      flipStatus:status
    });
  }

  getExamModelToUpdate(){
    let tempAllQuestions = this.state.allQuestions;
    let questionsArray = [];
    if(tempAllQuestions === undefined)
      return;
    tempAllQuestions.map((question, index) => {
      let quest = {};
      quest.question_id = question.id;
      quest.answer_duration = 0;
      let options = question.options;
      let answers =[];
      for (let i = 0; i < options.length; i++) {
        /*if(options[i].isCorrect){
          let ans = {}
          ans.id = options[i].id;
          answers.push(ans);
      }*/
      
    }
    quest.answers = answers;
  })
  return questionsArray;
  }



  updateExamStatus(){
    let questionsArray = this.getExamModelToUpdate();

    console.log(questionsArray);
    
    selectorService.updateExamStatus(this.state.attempt_id,questionsArray).then((data) => {
      const result = data.result;
      
      this.setState({
        time_left: result,
      });
      console.log(result);
    });
  }




  onGetRevisionAttempt() {
    var attemptId;

    /*  selectorService.createPracticeAttempt(this.props.location.state.examId).then((data) => {
        const result = data.result;
        attemptId = result.attempt_id;
        let index1 = 0;*/
        let questionsArray = [];
        selectorService.getPracticeAttempt(this.props.location.state.examId).then((data) => {
          const result = data.result;
          let index1 = 0;
          let sections1 = [];
         
        //  sections1 = result.sections.map((item, secIndex) => {
            let quest = [];
            quest = result.questions.map((question, index) => {
              index1 = index1 + 1
              question.index = index;
             // question.sectionIndx = secIndex;
              question.secQIndex = index;
              questionsArray.push(question);
              return question;
            })
           
           // item.questions = quest;
            this.setState({
              attempt_id: attemptId,
              exam_id: result.practice_id,
              exam_language: result.language,
              exam_name: result.practice_name,
              //sections: sections1,
              time_left: result.time_left,
              deadline:  Date.now() + result.time_left*1000,
              allQuestions:questionsArray
            });
          });
         

       // });

     // });
    


  };

  callbackFunction = (question) => {
    //  console.log("Parent recieved Selector Data: "+ childData);
    let q = question;
    let indx = q.index;
    let secQuesIndx = q.secQIndex;
    this.state.sections[q.sectionIndx].questions[secQuesIndx] =q;
    this.state.allQuestions[indx-1] = q;
    let tempSections = this.state.sections;
    let tempAllQuestions = this.state.allQuestions;
    this.setState({
      showQuestion: true,
      sections: tempSections,
      allQuestions: tempAllQuestions
    })
 }

 callbackFunctionNext = (childData) => {
  //  console.log("Parent recieved Selector Data: "+ childData);
  let q = childData;
  let indx = q.index;
  let nxtQuestion = this.state.allQuestions[(indx)];
  this.showDetailQuestion(nxtQuestion);
}
  render() {
    let listItems = "";

    if (this.state.allQuestions !== undefined) {

          let tempQuestions = this.state.allQuestions.map((question) => {
          let isAnswered = false;
          let options = question.options;
          let answered =[];
          for (let i = 0; i < options.length; i++) {
            if(options[i].isCorrect){
              answered.push(options[i]);
          }
       }
           
           return question;
        })
        let questions = tempQuestions;
      let index1 = 0;
       listItems = questions.map((question, index) => {
              let title = <><Avatar className={question.classN}>{index+1}</Avatar><div className='Outerline'>
                <div className={"HeaderQuestionStatusLabel "+question.classN+"Title"}>{question.statusLabel}</div>
                
                </div></>
       

              return( 
                
                <ReactCardFlip isFlipped={this.state.flipStatus[question.id] } flipDirection="vertical">
               <Card title={title}     actions={[
                   question.type_text
                  ]} className={"QuestionCard "+question.classN+"Card"} onClick={() => this.updateFlipStatus(question.id,true)}>
                  <p>{parse(question.question_text)}</p>
        
                </Card>
        
                <Card title={title}     actions={[
                   question.type_text
                  ]} className={"QuestionCard "+question.classN+"Card"} onClick={() => this.updateFlipStatus(question.id, false)}>
                  <p>
                          {question.type_code === "MRQ" ? (
                            <Checkbox.Group
                              options={question.options.map(
                                (x, index) => {
                                  return {
                                    label: x.text,
                                    value: index,
                                    disabled: true,
                                  };
                                }
                              )}
                              disabled
                              defaultValue={[1]}
                            />
                          ) : question.type_code === "MCQ" ||
                          question.type_code === "TNF" ? (
                            <Radio.Group
                              // options={this.state.view.options.map(
                              //   (x, index) => {
                              //     return { label: x.text, value: x.text };
                              //   }
                              // )}
                              disabled
                              value={
                                question.options
                                  .filter((x) => x.isCorrect)
                                  .map((y) => {
                                    console.log(y);
                                    return y.text;
                                  })[0]
                              }
                            >
                              {question.options.map((x, idx) => {
                                return (
                                  <Radio key={idx} value={x.text}>
                                    <span
                                      style={{
                                        color: x.isCorrect
                                          ? "green"
                                          : x.isAttempted
                                          ? "red"
                                          : "grey",
                                      }}
                                    >
                                      {" "}
                                      {x.text}
                                    </span>
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          ) : (
                            <div></div>
                          )}</p>
        
                </Card>
              </ReactCardFlip>
                )
              
    }
  






   


      );
  }
      

    
    return (
      <>
        <Layout>
          <Siderc/>
          <Layout>
          <Headerc />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
               <Layout className="createExamLayoutHeader">Revision: {this.state.exam_name}</Layout>
              <Layout className="MainExamLayout">
               
                <Layout className="createExamLayoutLeft">
                <div className="CardFlipDiv" >
                      { listItems }
                </div>
                  
  
                </Layout>
              </Layout>
            </Content>
          </Layout>
        </Layout>
      </>
    )
  };
};

export default AttemptRevision;
