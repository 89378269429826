import React from 'react';
import './AddQuestion.css';
import { Button, Layout, Menu, PageHeader, Grid } from 'antd';
import MediaQuery from 'react-responsive';
import 'antd/dist/antd.css';
import CreateQuestion from './CreateQuestion';
import UploadQuestion from './UploadQuestion';
import Selector from './Selector';
import { authenticationService } from '../../_services';
import Sidebar from './Sidebar';
import { MenuOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;




class AddQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      top: 'topLeft',
      bottom: 'bottomRight',
      classes: "1",
      subject: "",
      category: "",
      questionType: "",
      complexity: "",
      upload: false,
      collapse: false,
    };
    this.handleCreateQuestion = this.handleCreateQuestion.bind(this);
    this.handleUploadQuestion = this.handleUploadQuestion.bind(this);
  }

  handleCreateQuestion() {
    this.setState({
      upload: false
    })
  }
  handleUploadQuestion() {
    this.setState({
      upload: true
    })
  }
  callbackFunction = (childData) => {
    // console.log("Parent recieved Selector Data: " + childData);
    this.setState({
      classes: childData.classes,
      module_id: childData.module_id,
      module: childData.module,
      questionType: childData.questionType,
      complexity: childData.complexity,
    });
  }

  render() {
    return (
      <>
        <Layout className='add-question-section'>
          <Selector parentCallback={this.callbackFunction} />
          <Layout>
            <MediaQuery maxWidth={992}>
              <Button type='primary' className='slider-trigger-button' onClick={() => this.setState({collapse: !this.state.collapse})}><MenuOutlined /></Button>
            </MediaQuery>
            <PageHeader className="add-question-section-header" title="Add Questions" />
            <Layout className='has-sidebar'>
              <Sidebar className="add-question-sidebar" collapse={this.state.collapse}>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={['createQue']}
                  style={{ height: '100%' }}
                  >
                  <Menu.Item key="createQue" onClick={this.handleCreateQuestion}> Create Question</Menu.Item>
                  <Menu.Item key="uploadQue" onClick={this.handleUploadQuestion}> Upload Question</Menu.Item>
                </Menu>
              </Sidebar>
              <Content className='add-question-component'>
                {!this.state.upload ? <CreateQuestion selectorState={this.state} /> : <UploadQuestion selectorState={this.state} />}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </>
    )
  };
};

export default AddQuestion;
