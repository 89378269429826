import {
  Steps,
  Button,
  message,
  Layout,
  Input,
  List,
  Avatar,
  Skeleton,
  Row,
  Col,
  Empty,
  Checkbox,
  Transfer,
} from "antd";
import { Siderc } from "../Main/Siderc";
import {
  FormOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import React from "react";
import "./CreateBatch.css";
import { Headerc } from "../Main/Headerc";
import { Link, Redirect } from "react-router-dom";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content } = Layout;

class CreateBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batchName:
        props.location.state && props.location.state.name
          ? props.location.state.name
          : "",
      users: [],
      step: props.location.state && props.location.state.name ? 2 : 1,
      mockData: [],
      targetKeys: [],
      batch_id:
        props.location.state && props.location.state.id
          ? props.location.state.id
          : "",
      editing: false,
      updatedName:
        props.location.state && props.location.state.name
          ? props.location.state.name
          : "",
    };
  }
  componentDidMount() {
    selectorService
      .getAllUser()
      .then((res) => {
        let users = res.result.users.filter((x) => x.role === "Student");
        console.log(users);

        this.setState(
          {
            users,
            updatedName: this.state.batchName,
          },
          () => {
            this.getMock();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMock = () => {
    let targetKeys = [];
    let mockData = [];
    if (!isNaN(parseInt(this.state.batch_id))) {
      selectorService
        .getStudentsOfBatch(this.state.batch_id)
        .then((res) => {
          let users = res.result.map((x) => x["id"]);
          for (let i = 0; i < this.state.users.length; i++) {
            const data = {
              key: i.toString(),
              title: `${this.state.users[i].first_name}`,
              description: `${this.state.users[i].email_id}`,
              chosen:
                users.indexOf(this.state.users[i].id) !== -1 ? true : false,
            };
            if (data.chosen) {
              targetKeys.push(data.key);
            }
            mockData.push(data);
          }
          console.log(targetKeys);
          this.setState({ mockData, targetKeys });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      for (let i = 0; i < this.state.users.length; i++) {
        const data = {
          key: i.toString(),
          title: `${this.state.users[i].first_name}`,
          description: `${this.state.users[i].email_id}`,
          chosen: false,
        };
        if (data.chosen) {
          targetKeys.push(data.key);
        }
        mockData.push(data);
      }
      this.setState({ mockData, targetKeys });
    }
  };

  handleChange = (targetKeys, direction, moveKeys) => {
    console.log(targetKeys, direction, moveKeys);
    if (direction === "right") {
      let idList = [];
      idList = moveKeys.map((x) => this.state.users[x].id);
      console.log(idList);
      message.loading({ content: "Adding Students..." });
      selectorService
        .addToBatch({
          idList: idList,
          batch_id: this.state.batch_id,
        })
        .then((res) => {
          message.success("Students added to " + this.state.batchName);
          console.log(res);
          this.setState({
            targetKeys,
          });
        })
        .catch((err) => console.log(err));
    } else {
      let idList = [];
      idList = moveKeys.map((x) => this.state.users[x].id);
      console.log(idList);
      message.loading({ content: "Removing Students..." });
      selectorService
        .deleteFromBatch({
          idList: idList,
          batch_id: this.state.batch_id,
        })
        .then((res) => {
          message.success("Students removed from " + this.state.batchName);
          console.log(res);
          this.setState({
            targetKeys,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  renderItem = (item) => {
    const customLabel = (
      <span className="custom-item">
        {item.title} - {item.description}
      </span>
    );

    return {
      label: customLabel, // for displayed item
      value: item.title, // for title and filter matching
    };
  };

  render() {
    let header =
      this.state.step === 1
        ? "Class "
        : !this.state.editing
        ? this.state.batchName + " "
        : "";
    console.log(this.props);
    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">Create Batch</Layout>
            <Layout className="createExamLayout">
              <div style={{ position: "relative" }}>
                <strong>
                  <h2>
                    <span>
                      <Input
                        value={this.state.updatedName}
                        onChange={(e) =>
                          this.setState({ updatedName: e.target.value })
                        }
                        hidden={!this.state.editing}
                        style={{ width: "10%", float: "left", height: "30px" }}
                        placeholder="Batch Name"
                      />
                    </span>
                    <span style={{ float: "left" }}>{header}Batch</span>
                    <span
                      className="examNameEdit"
                      hidden={this.state.step === 1}
                      style={{
                        cursor: "pointer",
                        width: "5px",
                        float: "left",
                        // position: "absolute",
                        // top: "0",
                        // right: "0",
                      }}
                    >
                      <div onClick={() => this.setState({ editing: true })}>
                        <FormOutlined
                          hidden={this.state.editing}
                          style={{ fontSize: "16px", color: "#1890FF" }}
                          twoToneColor="#1890FF"
                        />
                      </div>
                      <div
                        hidden={!this.state.editing}
                        onClick={() => {
                          this.setState({
                            editing: false,
                            updatedName: this.state.batchName,
                          });
                        }}
                      >
                        <CloseSquareTwoTone />
                      </div>
                      <div
                        onClick={() => {
                          const { updatedName } = this.state;
                          selectorService
                            .updateBatchName({
                              id: this.state.batch_id,
                              name: updatedName,
                              description: "",
                              classId: 1,
                              photo: "",
                              isActive: true,
                            })
                            .then((res) => {
                              console.log(res);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                          this.setState({
                            editing: false,
                            batchName: this.state.updatedName,
                          });
                        }}
                        hidden={!this.state.editing}
                      >
                        <CheckSquareTwoTone />
                      </div>
                    </span>
                  </h2>
                </strong>
              </div>
              <div hidden={this.state.step !== 1}>
                <Input
                  value={this.state.batchName}
                  onChange={(e) => {
                    this.setState({ batchName: e.target.value });
                  }}
                  style={{ width: "80%" }}
                  size="large"
                  placeholder="Create Batch"
                />
                <br />
                <br />
                <Button
                  onClick={() => {
                    if (
                      !this.state.batchName ||
                      this.state.batchName.length === 0
                    ) {
                      message.error("Batch Name is mandatory");
                    } else {
                      selectorService
                        .createBatch({
                          name: this.state.batchName,
                          description: "",
                          class_id: 1,
                          photo: "",
                        })
                        .then((res) => {
                          if (!res) message.error("Some error occured");
                          this.setState({
                            step: this.state.step + 1,
                            batch_id: res.result.id,
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                  style={{ width: "13%" }}
                  type="primary"
                >
                  Create Batch
                </Button>
              </div>
              <div hidden={this.state.step === 1}>
                <br />
                <p style={{ fontSize: "15px" }}>Add Students</p>
                <Transfer
                  dataSource={this.state.mockData}
                  listStyle={{
                    width: "50%",
                    height: 500,
                  }}
                  targetKeys={this.state.targetKeys}
                  onChange={this.handleChange}
                  render={this.renderItem}
                  locale={{
                    itemUnit: "Student",
                    itemsUnit: "Students",
                    searchPlaceholder: "Search here",
                  }}
                />

                <br />
                <Button type="primary">
                  <Link to="/viewbatch">Continue</Link>
                </Button>
              </div>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default CreateBatch;
