import React, { useRef } from 'react';
import { Layout, Menu, Typography } from 'antd';
import 'antd/dist/antd.css';
import { CompassOutlined, DashboardOutlined, KeyOutlined, CalendarOutlined, BookOutlined, BarChartOutlined, ReadOutlined, NotificationOutlined } from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import { authenticationService } from "../../_services";

import OdiSVG from '../assets/image/GrayscalePKLogo.svg'
import './Main.css';
import { set } from 'lodash';


const { SubMenu } = Menu;
const { Sider } = Layout;

const rootSubmenuKeys = ['question', 'quiz', 'practice', 'bataches', 'classes', 'performance'];
const questionItems = ['allQuestions', 'myQuestions', 'addQuestion', 'bookmarkQuestions', 'draftQuestions'];
const quizItems = ['createExam', 'liveExam', 'upcomingExam', 'expiredExams', 'draftExams'];
const practiceItems = ['createPractise', 'livePractise', 'upcomingPractise', 'draftPractise'];
const batchesItems = ['batchCreate', 'batchView'];
const classesItems = ['viewClass', 'createClass', 'viewAllClasses'];

export function Siderc(props) {

	const [state, setState] = React.useState({ current: 'dashboard' });
	const [openKeys, setOpenKeys] = React.useState();
	const set = useRef(false);


	const handleClick = e => {
		setState({ current: e.key });
	}

	React.useEffect(() => {
		let item;
		const key = props.location?.pathname.split('/')[1];
		if (!set.current && props.location?.pathname.split('/')[1] === 'dashboard') {
			setState({ current: 'dashboard' });
			set.current = true;
		}
		if (!set.current) {
			for (item of questionItems) {
				if(set.current) break;
				selectMenuKey(key, item, rootSubmenuKeys[0], set);
			}
		}
		if (!set.current) {
			for (item of quizItems) {
				if(set.current) break;
				selectMenuKey(key, item, rootSubmenuKeys[1], set);
			}
		}
		if (!set.current) {
			for (item of practiceItems) {
				if(set.current) break;
				selectMenuKey(key, item, rootSubmenuKeys[2], set);
			}
		}
		if (!set.current) {
			for (item of batchesItems) {
				if(set.current) break;
				selectMenuKey(key, item, rootSubmenuKeys[3], set);
			}
		}
		if (!set.current) {
			for (item of classesItems) {
				if(set.current) break;
				selectMenuKey(key, item, rootSubmenuKeys[4], set);
			}
		}
		return;
	}, [props.location?.pathname]);

	React.useEffect(() => {
		set.current = false;
	}, [props.location?.pathname]);

	function selectMenuKey(location, item, submenu, set) {
		if (!set.current && location === item) {
			setOpenKeys(submenu);
			setState({ current: props.location?.pathname.split('/')[ props.location?.pathname.split('/').length - 1] });
			set.current = true;
		}
	}

	function subMenuOpen(node) {
		setOpenKeys(node.key);
	}

	return (
		<Sider breakpoint="lg"
			collapsedWidth="0px"
			zeroWidthTriggerStyle={{ position: 'relative', top: 0, height: 64, display: 'grid', placeItems: 'center' }}
			className="sidebar">
			<div className="logo" style={{}}>
				<a className='center-flex ml-6'>
					<img src={OdiSVG} alt="PracticeKea" style={{ height: "50%" }} />
					<Typography.Title level={4} style={{ marginBlock: 0, marginLeft: 10, color: '#fff' }}>PracticeKea</Typography.Title>
				</a>
			</div>
			{authenticationService.currentUserValue?.role_id !== 4 ?

				<Menu
					mode="inline"
					onClick={handleClick}
					selectedKeys={[state.current]}
					openKeys={[openKeys]}
					style={{ height: '100%' }}
				>
					<Menu.Item key="dashboard" icon={<DashboardOutlined />}> <NavLink to="/dashboard">Dashboard</NavLink></Menu.Item>
					<SubMenu key="question" icon={<CompassOutlined />} title="Question" onTitleClick={subMenuOpen}>
						<Menu.Item key="allQuestions">  <NavLink to="/allQuestions">All Questions</NavLink></Menu.Item>
						<Menu.Item key="myQuestions"> <Link to="/myQuestions">My Questions</Link></Menu.Item>
						<Menu.Item key="addQuestion"> <Link to="/addQuestion">Create Question</Link></Menu.Item>
						<Menu.Item key="bookmarkQuestions"><Link to="/bookmarkQuestions">Bookmarked</Link></Menu.Item>
						<Menu.Item key="draftQuestions"> <Link to="/draftQuestions">Draft Questions</Link></Menu.Item>
					</SubMenu>
					<SubMenu key="quiz" icon={<KeyOutlined />} title="Quiz/Exam" onTitleClick={subMenuOpen}>
						<Menu.Item key="createExam"><Link to="/createExam">Create Exam</Link></Menu.Item>
						<Menu.Item key="liveExam"><Link to="/liveExam">Live Exams</Link></Menu.Item>
						<Menu.Item key="upcomingExam"><Link to="/upcomingExam">Upcoming Exams</Link></Menu.Item>
						<Menu.Item key="expiredExams"><Link to="/expiredExams">Expired Exams</Link></Menu.Item>
						<Menu.Item key="draftExams"><Link to="/draftExams">Draft Exams</Link></Menu.Item>
					</SubMenu>

					<SubMenu key="practice" icon={<KeyOutlined />} title="Practice" onTitleClick={subMenuOpen}>
						<Menu.Item key="createPractise"><Link to="/createPractise">Create Practise</Link></Menu.Item>
						<Menu.Item key="draftPractise"><Link to="/draftPractise">Draft Practises</Link></Menu.Item>
						<Menu.Item key="livePractise"><Link to="/livePractise">Live Practises</Link></Menu.Item>
						<Menu.Item key="upcomingPractise"><Link to="/upcomingPractise">Upcoming Practises</Link></Menu.Item>
					</SubMenu>


					<SubMenu key="batches" icon={<NotificationOutlined />} title="Batch" onTitleClick={subMenuOpen}>
						<Menu.Item key="batchCreate" icon={<ReadOutlined />}> <Link to="/createbatch">Create Batch</Link></Menu.Item>
						<Menu.Item key="batchView" icon={<ReadOutlined />}> <Link to="/viewbatch">View All Batch</Link></Menu.Item>
					</SubMenu>
					<SubMenu key="classes" icon={<NotificationOutlined />} title="Classes" onTitleClick={subMenuOpen}>
						<Menu.Item key="viewClass" icon={<ReadOutlined />}> <Link to="/classes">Student Class</Link></Menu.Item>
						<Menu.Item key="createClass" icon={<ReadOutlined />}> <Link to="/class">Create Class</Link></Menu.Item>
						<Menu.Item key="viewAllClasses" icon={<ReadOutlined />}> <Link to="/viewclass">View All Class</Link></Menu.Item>
					</SubMenu>
					<SubMenu key="performance" icon={<BarChartOutlined />} title="Performance" onTitleClick={subMenuOpen}>
						{/* <Menu.Item key="9">option9</Menu.Item>
                <Menu.Item key="10">option10</Menu.Item>
                <Menu.Item key="11">option11</Menu.Item>
                <Menu.Item key="12">option12</Menu.Item> */}
					</SubMenu>
				</Menu> :
				<Menu
					mode="inline"
					onClick={handleClick}
					selectedKeys={[state.current]}
					defaultOpenKeys={['dashboard']}
					style={{ height: '100%', borderRight: '0px solid #f0f2f5' }}
				>
					<Menu.Item key="dashboard" icon={<DashboardOutlined />}> <Link to="/dashboard">Dashboard</Link></Menu.Item>
					<SubMenu key="question" icon={<CompassOutlined />} title="Question">
						<Menu.Item key="4">Bookmarked</Menu.Item>

					</SubMenu>
					<SubMenu key="quiz" icon={<KeyOutlined />} title="Quiz/Exam">
						<Menu.Item key="8"><Link to="/liveExams">Live Exams</Link></Menu.Item>
						<Menu.Item key="9"><Link to="/upcomingExams">Upcoming Exams</Link></Menu.Item>

					</SubMenu>
					<SubMenu key="practice" icon={<BookOutlined />} title="Practice">
						<Menu.Item key="9"><Link to="/practiceExams">Practice Exams</Link></Menu.Item>
					</SubMenu>
					<SubMenu key="calendar" icon={<CalendarOutlined />} title="Calendar">
						<Menu.Item key="14">option9</Menu.Item>
						<Menu.Item key="15">option10</Menu.Item>
						<Menu.Item key="16">option11</Menu.Item>
						<Menu.Item key="17">option12</Menu.Item>
					</SubMenu>
				</Menu>
			}
		</Sider>
	);


}
