import React from "react";
import "./PracticeExams.css";
import { Layout, List, Avatar, Modal, Switch, Button, Row, Col, Card,Collapse, Space } from "antd";
import "antd/dist/antd.css";
import { authenticationService } from "../../../../_services";
import { Siderc } from "../../../Main/Siderc";
import { Headerc } from "../../../Main/Headerc";
import { selectorService } from "../../../../services/selectorService";
import { Link } from "react-router-dom";
const { Content } = Layout;
const { Meta } = Card;
const { Panel } = Collapse;
class PracticeExams extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    currentUser: authenticationService.currentUserValue,
    classes: authenticationService.currentClassValue,
    practiceType: "subject",
    batch: "1",
    data: [],
    total_count: 0,
    total_pages: 0,
    dialogVisible: false,
    selectedExamId:-1,
  };
  this.handleLoadUpcomingPracticeExam = this.handleLoadUpcomingPracticeExam.bind(this);
  this.handlePracticeTypeChange = this.handlePracticeTypeChange.bind(this);
  this.launchDialog = this.launchDialog.bind(this);
  this.closeDialog = this.closeDialog.bind(this);

}

closeDialog(){
  this.setState({
    dialogVisible : false,
  })
}
 launchDialog(examid){
   this.setState({
     dialogVisible : true,
     selectedExamId : examid
   })
  console.log(examid);
};

handlePracticeTypeChange = (event) => {
  let type = event?"subject":"category";
  this.setState({
    practiceType: type,
  });
  this.handleLoadUpcomingPracticeExam(type);
};

  componentDidMount() {
    this.handleLoadUpcomingPracticeExam(this.state.practiceType);
  }

  handleLoadUpcomingPracticeExam = (type) =>  {
    // console.log('jsonObj:', jsonObj);

    selectorService.livePracticeExams(type).then((data1) => {
      // console.log('Success:', data1);
      //data = $.parseJSON(data);
      let result = {};
      if (data1 !== undefined) result = data1.result;
      this.state.data = [];
      let data = [];
      let map = {};
      if (result.practices !== undefined) {
        for (let index = 0; index < result.practices.length; index++) {
          let c = {};
          c.index = index;
          c.id = result.practices[index].id;
          c.name = result.practices[index].name;
          c.image = result.practices[index].image;

          c.complexity = result.practices[index].complexity;
          c.author_name = result.practices[index].author_name;
          c.module_id = result.practices[index].module_id;
          c.subject_name = result.practices[index].module_name;
          c.total_questions = result.practices[index].total_questions;
          c.attempt_count = result.practices[index].attempt_count; 
          let date = new Date(result.practices[index].start_date);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;

          let yyyy = date.getFullYear();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          c.start_date = dd + "/" + mm + "/" + yyyy;
          data.push(c);
          map[c.id] = c;
        }
      }
      this.setState({
        data: data,
        map: map,
        result: result,
        total_count: result.total_count,
        total_pages: result.total_pages,
      });
    });
  
    //console.log(data);
  }

   render() {
    let subjectMap = new Map();
    this.state.data.map((item, index) => {
      let subjectArray = subjectMap.get(item.subject_name);
      if(subjectArray === undefined){
        subjectArray = new Array();       
      } 
      subjectArray.push(item);
      subjectMap.set(item.subject_name,subjectArray);
    });

    let accordions = [...subjectMap.keys()].map((subject_name) =>{ 
      let itemArray = subjectMap.get(subject_name);
    return( 
      <>
      <p className="SubjectHeader"><span className="ant-card-meta-title">{subject_name}</span></p>
      {/*  <List
    grid={{ gutter: 16, column: 4 }}
    dataSource={itemArray}
    renderItem={item => (
      <List.Item>
        <Card
                        cover={ <Avatar
                          src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                          size="medium"
                          shape="square"
                          className="DraftExamImage"
                        />}>
                        <Meta 
                            title= {item.name}
                            description= {
                              <>
                              <Row>
                                <Col>Complexity</Col>
                                <Col className="ColValue">{item.complexity}</Col>
                              </Row>
                              <Row>
                                <Col>Attempt Count</Col>
                                <Col className="ColValue">{item.attempt_count}</Col>
                              </Row>
                              <Row>
                              <Col>Author</Col>
                              <Col className="ColValue">{item.start_date}</Col>
                            </Row>
                            <Row>
                              <Col>Start Date</Col>
                              <Col className="ColValue">{item.start_date}</Col>
                            </Row>
                            </>
                            } />
            </Card>
      </List.Item>
    )}
                          />*/}
    
      <Row gutter={20}>
      {itemArray.map((item, index1) => 
        
           <Col className="CardCol" key={item.id}onClick={e => {this.launchDialog(item.id)}} >
             
            <Card className="CardPractice"
                        cover={ <Avatar
                          src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                          size="medium"
                          shape="square"
                          className="DraftExamImage"
                        />}
                        >
                        <Meta 
                            title= {item.name}
                            description= {
                              <>
                              <Row>
                                <Col>Complexity</Col>
                                <Col className="ColValue">{item.complexity}</Col>
                              </Row>
                              <Row>
                                <Col>Attempt Count</Col>
                                <Col className="ColValue">{item.attempt_count}</Col>
                              </Row>
                              <Row>
                              <Col>Author</Col>
                              <Col className="ColValue">{item.start_date}</Col>
                            </Row>
                            <Row>
                              <Col>Start Date</Col>
                              <Col className="ColValue">{item.start_date}</Col>
                            </Row>
                            </>
                            } />
            </Card>
       </Col>
      )}
      </Row>
      </>
   )
    });
        
    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader"><div><div className="PratciceTitleLeft">Practice Exams</div><div className="PratciceTitleRight">    <Switch checkedChildren="Subject" unCheckedChildren="Topic" defaultChecked onChange={(e) => {
                this.handlePracticeTypeChange(e);
              }} />
            </div></div>  </Layout>
            <Layout className="createExamLayout">

                      <>
                      {accordions}
                      <Modal
                          title="Select Practice Exam"
                          centered
                          visible={this.state.dialogVisible}
                          onCancel={this.closeDialog}
                          width={300}
                          footer={null}
                        >
                          <div className="OuterDiv">
                            <div className="InnerDiv">
                              <Space size={"large"}>

                              <Button type="primary">
                                <Link
                                  to={{
                                    className: "AnchorBlue",
                                    pathname: "/attemptRevision",
                                    state: {
                                      examId: this.state.selectedExamId,
                                    },
                                  }}
                                >
                                  <span>Revision</span>
                                  </Link>
                                  </Button>
                                  </Space>
                                  </div>
                                  <div className="InnerDiv">
                                  <Space  size={"large"}>
                                  <Button type="primary">

                
                                <Link
                                  to={{
                                    className: "AnchorBlue",
                                    pathname: "/attemptPractice",
                                    state: {
                                      examId: this.state.selectedExamId,
                                    },
                                  }}
                                >
                                  <span>Practice</span>
                                  </Link>
                                  </Button>
                                  </Space>
                                  </div>
                                  </div>
                                  
                        </Modal>
                      </>
                  


            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default PracticeExams;
