import { Steps, Button, message, Layout } from 'antd';
// import { Siderc } from '../Main/Siderc';
import React from 'react';
import { authenticationService } from '../../_services';
// import { isEqual } from 'lodash'
// import { Headerc } from '../Main/Headerc';
import CreateExamName from './CreateExamName'
import SelectSubjects from './SelectSubjects';
import ArrangeSections from './ArrangeSections';
import ManageExam from './ManageExam';
import history from '../../history';
import { selectorService } from '../../services/selectorService';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const { Step } = Steps;



function CreateExamcopy(props) {
    const [state, setState] = React.useState({
      current: 0,
      currentUser: authenticationService.currentUserValue,
      attemptsAllowed: null,
      authorId: '',
      description: null,
      endDate: null,
      examStatus: "",
      examTypeId: 1,
      id: '',
      image: '',
      instituteId: '',
      instruction: '',
      isActive: false,
      languageId: '',
      name: "",
      sections: [],
      startDate: null,
      rearrange: true,
    });

    const location = useLocation();

    const ManageExamRef = React.createRef();
    const steps = [
      {
        title: 'Step 1',
        description: "Write a Exam Name",
        content: <CreateExamName selectorState={state} parentCallback={callbackFunctionStep1} />,
      },
      {
        title: 'Step 2',
        description: "Select Sections",
        content: <SelectSubjects selectorState={state} parentCallback={(childData) => { callbackFunctionStep2(childData) }} />,
      },
      {
        title: 'Step 3',
        description: "Arrange Sections",
        content: <ArrangeSections selectorState={state} parentCallback={(childData, e) => { callbackFunctionStep3(childData) }} />,
      },
      {
        title: 'Step 4',
        description: "Manage Exam",
        content: <ManageExam ref={ManageExamRef} selectorState={state} parentCallback={(childData) => { callbackFunctionStep4(childData) }} />,
      }
    ];

  function next() {
    const current = state.current + 1;
    setState({ current });
  }

  function prev() {
    const current = state.current - 1;
    setState({ current });
  }

  function callbackFunctionStep1(childData) {
    // console.log("Parent recieved Selector Data: " + childData);
    setState(prev => ({...prev,
      current: childData.current,
      authorId: childData.authorId,
      examStatus: childData.examStatus,
      examTypeId: childData.examTypeId,
      id: childData.id,
      image: childData.image,
      instituteId: childData.instituteId,
      isActive: childData.isActive,
      languageId: childData.languageId,
      name: childData.name
    }));
  }

  function callbackFunctionStep2(childData) {
    // console.log("Parent recieved Selector Data: " + JSON.stringify(childData), childData.sections);
    setState(prev => ({...prev,
      image: childData.image,
      current: childData.current,
      sections: childData.sections,
      name: childData.name,
    }));
  }

  function callbackFunctionStep3(childData) {
    // console.log("Parent recieved Selector Data: " + JSON.stringify(childData));
    setState(prev => ({...prev,
      current: childData.current,
      sections: childData.sections,
      rearrange: childData.rearrange,
      name: childData.exam
    }));
  }

  React.useEffect(() => {
    // console.log(location);
    let id = location.state && location.state.id;
    let current = location.state && location.state.current;
    // console.log(current, id);
    if (id) {
      selectorService.getExamDetails(id).then((res) => {
        // console.log(res);
        setState(prev => ({...prev,
          attemptsAllowed: res.result.attempts_allowed,
          description: res.result.description,
          endDate: res.result.end_date,
          examStatus: res.result.exam_status,
          examTypeId: res.result.examtype_id,
          id: res.result.id,
          image: `https://s3bucket-for-oa.s3.ap-south-1.amazonaws.com/1/exams/${id}.png`,
          instituteId: res.result.institute_id,
          instruction: res.result.instruction,
          isActive: res.result.isActive,
          languageId: res.result.language_id,
          name: res.result.name,
          sections: res.result.sections,
          startDate: res.result.start_date,
          current: current,
        }))
      })
        .catch((err) => {
          console.log(err);
        })

    }
  }, []);

  function callbackFunctionStep4 () {
    setState(prev => ({...prev,
      current: 0,
      currentUser: authenticationService.currentUserValue,
      attemptsAllowed: null,
      authorId: '',
      description: null,
      endDate: null,
      examStatus: "",
      examTypeId: 1,
      id: '',
      image: '',
      instituteId: '',
      instruction: '',
      isActive: false,
      languageId: '',
      name: "",
      sections: [],
      startDate: null,
      rearrange: true,
    }));
  }

  function handlePublishExam(e) {
    ManageExamRef.current.handleSubmitExam();
    history.push('/dashboard');
  }

    const { current } = state;

    return (
      // <Layout>
      //   <Siderc />
      //   <Layout>
      //     <Headerc />
          <Content
            className="site-layout-background" style={{ padding: 24, margin: 0 }}>
            <Layout className='createExamLayoutHeader'>Create Exam</Layout>
            <Layout className='createExamLayout'>
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} description={item.description} />
                ))}
              </Steps>

              <div className="steps-content" >{steps[current].content}</div>
              <div className="steps-action">
                {current < steps.length - 1}
                {current === steps.length - 1 && (
                  <Button type="primary" onClick={handlePublishExam}>
                    Publish Exam
                  </Button>
                )}
              </div>
            </Layout>
          </Content>
      //   </Layout>
      // </Layout>
    );
}

export default CreateExamcopy;