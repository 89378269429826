import React from 'react';
import { Button, Layout } from 'antd';
// import 'antd/dist/antd.css';
import '../AllQuestions.css';

// import Selector from './Selector';
import QuestionTableNew from './QuestionTableNew';
import { authenticationService } from '../../../_services';
import { selectorService } from '../../../services/selectorService';

//experiment
import SelectorNew from '../SelectorNew';
import { Link } from 'react-router-dom';


function Draft() {
    const [state, setState] = React.useState({
        currentUser: authenticationService.currentUserValue,
        top: 'topLeft',
        bottom: 'bottomRight',
        classes: authenticationService.currentClassValue,
        subject: "",
        category: "",
        questionType: "",
        complexity: ""
    });

    function handleSelection(property, value="") {
        // console.log(property);
        setState(prevVal => ({ ...prevVal,
            [property]: value ,
        }));
        // callbackFunction();
    }

    return (
        <Layout>
            <Layout className="all-questions-section">
                <div className="selector-section">
                    <SelectorNew serviceCall={selectorService.loadSubjects} pathActive={state.classes} handleSelection={handleSelection} property="subject"
                        placeholder="Select a subject" />
                    <SelectorNew serviceCall={selectorService.loadCategory} pathActive={state.subject} handleSelection={handleSelection} property="category" 
                        placeholder="Select a topic" />
                    <SelectorNew serviceCall={selectorService.loadQuestionTypes} handleSelection={handleSelection} property="questionType" 
                        placeholder="Select the question type" />
                    <SelectorNew handleSelection={handleSelection} property="complexity" placeholder="Select a difficulty" />
                </div>
                <Layout className="draft-questions-section">
                    <div>
                        <QuestionTableNew selectorState={state} page={1}/>
                    </div>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default Draft;
