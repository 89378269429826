import React from "react";
import "./LiveExams.css";
import { Layout, List, Avatar, Button, Space, Divider } from "antd";
import "antd/dist/antd.css";
import { authenticationService } from "../../../../_services";
import { Siderc } from "../../../Main/Siderc";
import { Headerc } from "../../../Main/Headerc";
import { selectorService } from "../../../../services/selectorService";
import { Link } from "react-router-dom";
const { Content } = Layout;

class LiveExams extends React.Component {
  state = {
    currentUser: authenticationService.currentUserValue,
    classes: "1",
    batch: "1",
    data: [],
    total_count: 0,
    total_pages: 0,
  };

  componentDidMount() {
    this.handleLoadUpcomingExam();
  }

  handleLoadUpcomingExam() {
    // console.log('jsonObj:', jsonObj);
    const json = {};
    json.pageSize = 10;
    json.pageNumber = 1;
    json.batch = this.state.batch;
    selectorService.loadLiveExams(json).then((data1) => {
      // console.log('Success:', data1);
      //data = $.parseJSON(data);
      let result = {};
      if (data1 !== undefined) result = data1.result;
      this.state.data = [];
      let data = [];
      let map = {};
      if (result.exams !== undefined) {
        for (let index = 0; index < result.exams.length; index++) {
          let c = {};
          c.index = index;
          c.id = result.exams[index].id;
          c.name = result.exams[index].name;
          c.image = result.exams[index].image;

          c.total_marks = result.exams[index].total_marks;
          c.total_questions = result.exams[index].total_questions;
          c.exam_duration = result.exams[index].exam_duration;
          c.attempt_status = result.exams[index].attempt_status;
          let Difference_In_Time =
            new Date(result.exams[index].end_date).getTime() -
            new Date().getTime();
          // To calculate the no. of days between two dates
          c.number_Of_Days = ~~(Difference_In_Time / (1000 * 3600 * 24));
          let date = new Date(result.exams[index].start_date);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;

          let yyyy = date.getFullYear();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          c.start_date = dd + "/" + mm + "/" + yyyy;

          let date1 = new Date(result.exams[index].end_date);
          let dd1 = date1.getDate();
          let mm1 = date1.getMonth() + 1;

          let yyyy1 = date1.getFullYear();
          if (dd1 < 10) {
            dd1 = "0" + dd1;
          }
          if (mm1 < 10) {
            mm1 = "0" + mm1;
          }
          c.end_date = dd1 + "/" + mm1 + "/" + yyyy1;
          data.push(c);
          map[c.id] = c;
        }
      }
      this.setState({
        data: data,
        map: map,
        result: result,
        total_count: result.total_count,
        total_pages: result.total_pages,
      });
    });
    //console.log(data);
  }

  handleChange = (pageNumber) => {
    const json = {};
    json.pageSize = 10;
    json.pageNumber = pageNumber;
    json.batch = this.state.batch;
    selectorService.loadLiveExams(json).then((data1) => {
      // console.log('Success:', data1);
      //data = $.parseJSON(data);
      let result = {};
      if (data1 !== undefined) result = data1.result;
      this.state.data = [];
      let data = [];
      let map = {};
      if (result.exams !== undefined) {
        for (let index = 0; index < result.exams.length; index++) {
          let c = {};
          c.index = index;
          c.id = result.exams[index].id;
          c.name = result.exams[index].name;
          c.image = result.exams[index].image;

          c.total_marks = result.exams[index].total_marks;
          c.total_questions = result.exams[index].total_questions;
          c.exam_duration = result.exams[index].exam_duration;
          c.attempt_status = result.exams[index].attempt_status;
          let Difference_In_Time =
            new Date(result.exams[index].end_date).getTime() -
            new Date().getTime();
          // To calculate the no. of days between two dates
          c.number_Of_Days = ~~(Difference_In_Time / (1000 * 3600 * 24));
          let date = new Date(result.exams[index].start_date);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;

          let yyyy = date.getFullYear();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          c.start_date = dd + "/" + mm + "/" + yyyy;

          let date1 = new Date(result.exams[index].end_date);
          let dd1 = date1.getDate();
          let mm1 = date1.getMonth() + 1;

          let yyyy1 = date1.getFullYear();
          if (dd1 < 10) {
            dd1 = "0" + dd1;
          }
          if (mm1 < 10) {
            mm1 = "0" + mm1;
          }
          c.end_date = dd1 + "/" + mm1 + "/" + yyyy1;
          data.push(c);
          map[c.id] = c;
        }
      }
      this.setState({
        data: data,
        map: map,
        result: result,
        total_count: result.total_count,
        total_pages: result.total_pages,
      });
    });
  };
  render() {
    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">Live Exams</Layout>
            <Layout className="createExamLayout">
              <List
                itemLayout="horizontal"
                dataSource={this.state.data}
                pagination={{
                  onChange: (page) => {
                    this.handleChange(page);
                  },
                  pageSize: 10,
                  total: this.state.total_count,
                }}
                renderItem={(item) => {
                  console.log(item);
                  return (
                    <List.Item className="MyExam">
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                            size="large"
                            shape="square"
                            className="MyExamImage"
                          />
                        }
                        size="large"
                        bordered="true"
                        title={item.name}
                        description={
                          <>
                            <span className="MyExamSpan">
                              {item.number_Of_Days} days to end{" "}
                            </span>
                            <div className="MyExamDiv">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Subject</th>
                                    <th>Durations</th>
                                    <th>Questions</th>
                                    <th>Marks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                    <td>{item.exam_duration}</td>
                                    <td>{item.exam_duration}</td>
                                    <td>{item.total_questions}</td>
                                    <td>{item.total_marks}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        }
                      />
                      <div className="MyExamDiv">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Link
                                  to={{
                                    className: "AnchorBlue",
                                    pathname: "/attemptExam",
                                    state: {
                                      examId: item.id,
                                      examIdType: "exam",
                                    },
                                  }}
                                >
                                  {item.attempt_status === "Pause" ? (
                                    <Button id={item.id} type="primary">
                                      Resume
                                    </Button>
                                  ) : (
                                    <Button id={item.id} type="primary">
                                      Start Exam
                                    </Button>
                                  )}
                                </Link>
                              </td>
                              <td>
                                {item.attempt_status === "Completed" ? (
                                  <Link
                                    to={{
                                      className: "AnchorBlue",
                                      pathname: "/report",
                                      state: { examId: item.id },
                                    }}
                                  >
                                    <Button id={item.id} type="primary">
                                      Report
                                    </Button>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default LiveExams;
