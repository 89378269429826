import React from "react";
import {
  Layout,
  List,
  Avatar,
  Button,
  Space,
  Divider,
  Tabs,
  Row,
  Col,
  Anchor,
  Menu,
  Drawer,
  Radio,
  Checkbox,
  Select,
  Empty,
} from "antd";
import "antd/dist/antd.css";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import parse from "html-react-parser";
import { authenticationService } from "../../../../_services";
import { Siderc } from "../../../Main/Siderc";
import { Headerc } from "../../../Main/Headerc";
import { selectorService } from "../../../../services/selectorService";
// import { Link } from "react-router-dom";
import Plot from "react-plotly.js";
import { DashboardOutlined, PieChartOutlined } from "@ant-design/icons";
import "./Report.css";
const { Content } = Layout;
const { TabPane } = Tabs;

class Report extends React.Component {
  state = {
    currentUser: authenticationService.currentUserValue,
    classes: "1",
    batch: "1",
    data: [],
    total_count: 0,
    total_pages: 0,
    sections: [],
    view: {},
    visible: false,
    typeDropdown: [],
    typeDifficulty: [],
  };

  componentDidMount() {
    this.handleLoadReportForExam();
  }

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleChange(value) {
    this.setState({ value });
  }

  handleLoadReportForExam() {
    selectorService
      .loadReport(this.props.location.state.examId)
      .then((data) => {
        console.log(data);
        const result = data.result;
        let sections1 = [];
        let total_least_mark = 0;
        let total_max_mark = 0;
        let totalMarksReceived = 0;
        let totalMarks = 0;
        let total_correct_answer = 0;
        let total_wrong_answer = 0;
        let total_notAttempted_answer = 0;
        for (let index = 0; index < result.sections.length; index++) {
          let section = result.sections[index];
          let section_correct_answer = 0;
          let section_wrong_answer = 0;
          let section_notAttempted_answer = 0;
          let totalSectionMarks = 0;
          let easy_correct = 0;
          let easy_wrong_answer = 0;
          let easy_notAttempted_answer = 0;
          let medium_correct = 0;
          let medium_wrong_answer = 0;
          let medium_notAttempted_answer = 0;
          let hard_correct = 0;
          let hard_wrong_answer = 0;
          let hard_notAttempted_answer = 0;
          let total_MCQ = 0,
            total_MRQ = 0,
            total_TF = 0;
          let total_MCQ_correct = 0,
            total_MCQ_attempt = 0,
            total_MRQ_correct = 0,
            total_MRQ_attempt = 0,
            total_TF_correct = 0,
            total_TF_attempt = 0;
          totalMarks = totalMarks + result.sections[index].total_marks;
          if (total_max_mark < result.sections[index].total_marks) {
            total_max_mark = result.sections[index].total_marks;
          }
          if (total_least_mark > result.sections[index].total_nMarks) {
            total_least_mark = result.sections[index].total_nMarks;
          }

          let questions = result.sections[index].questions;
          for (let index1 = 0; index1 < questions.length; index1++) {
            if (questions[index1].isCorrect) {
              total_correct_answer++;
              section_correct_answer++;
              totalSectionMarks =
                totalSectionMarks + questions[index1].correct_marks;
              if (section.complexity_code === 1) easy_correct++;
              else if (section.complexity_code === 2) {
                medium_correct++;
              } else {
                hard_correct++;
              }
            } else if (questions[index1].isAttempted) {
              total_wrong_answer++;
              section_wrong_answer++;
              totalSectionMarks =
                totalSectionMarks - questions[index1].wrong_marks;
              if (section.complexity_code === 1) easy_wrong_answer++;
              else if (section.complexity_code === 2) {
                medium_wrong_answer++;
              } else {
                hard_wrong_answer++;
              }
            } else {
              total_notAttempted_answer++;
              section_notAttempted_answer++;
              if (section.complexity_code === 1) easy_notAttempted_answer++;
              else if (section.complexity_code === 2) {
                medium_notAttempted_answer++;
              } else {
                hard_notAttempted_answer++;
              }
            }
            if (questions[index1].type_code === "MCQ") {
              total_MCQ++;
              if (
                questions[index1].isAttempted &&
                questions[index1].isCorrect
              ) {
                total_MCQ_correct++;
                total_MCQ_attempt++;
              } else if (questions[index1].isAttempted) total_MCQ_attempt++;
            } else if (questions[index1].type_code === "MRQ") {
              total_MRQ++;
              if (
                questions[index1].isAttempted &&
                questions[index1].isCorrect
              ) {
                total_MRQ_correct++;
                total_MRQ_attempt++;
              } else if (questions[index1].isAttempted) total_MRQ_attempt++;
            } else {
              total_TF++;
              if (
                questions[index1].isAttempted &&
                questions[index1].isCorrect
              ) {
                total_TF_correct++;
                total_TF_attempt++;
              } else if (questions[index1].isAttempted) total_TF_attempt++;
            }
          }
          section.total_MCQ_attempt = total_MCQ_attempt;
          section.total_MRQ_attempt = total_MRQ_attempt;
          section.total_TF_attempt = total_TF_attempt;
          section.total_MCQ_correct = total_MCQ_correct;
          section.total_MRQ_correct = total_MRQ_correct;
          section.total_TF_correct = total_TF_correct;
          section.total_correct_answer = section_correct_answer;
          section.total_wrong_answer = section_wrong_answer;
          section.total_notAttempted_answer = section_notAttempted_answer;
          section.totalSectionMarks = totalSectionMarks;
          section.easy = {
            total: easy_correct + easy_notAttempted_answer + easy_wrong_answer,
            correct: easy_correct,
            wrong: easy_wrong_answer,
            notAttempted: easy_notAttempted_answer,
          };
          section.medium = {
            total:
              medium_correct + medium_notAttempted_answer + medium_wrong_answer,
            correct: medium_correct,
            wrong: medium_wrong_answer,
            notAttempted: medium_notAttempted_answer,
          };
          section.hard = {
            total: hard_notAttempted_answer + hard_wrong_answer + hard_correct,
            correct: hard_correct,
            wrong: hard_wrong_answer,
            notAttempted: hard_notAttempted_answer,
          };
          totalMarksReceived = totalMarksReceived + totalSectionMarks;
          section.total_MCQ = total_MCQ;
          section.total_MRQ = total_MRQ;
          section.total_TF = total_TF;
          sections1.push(section);
        }
        this.setState({
          examName: result.exam_name,
          sections: sections1,
          totalMarksReceived: totalMarksReceived,
          totalMarks: totalMarks,
          total_least_mark: total_least_mark,
          total_max_mark: total_max_mark,
          total_correct_answer: total_correct_answer,
          total_wrong_answer: total_wrong_answer,
          total_notAttempted_answer: total_notAttempted_answer,
        });
        console.log(data);
      });
  }
  render() {
    const { Link } = Anchor;
    const { Option } = Select;
    let answerKey = [];
    let secNames = [];
    let secMarks = [];
    this.state.sections.map((item, secIndex) => {
      secNames.push(item.subject_name);
      secMarks.push(item.totalSectionMarks);
    });
    let percentage = 0;
    if (this.state.total_correct_answer !== 0) {
      percentage =
        (this.state.total_correct_answer * 100) /
        (this.state.total_correct_answer +
          this.state.total_wrong_answer +
          this.state.total_notAttempted_answer);
    }

    const tabs = (
      <Tabs defaultActiveKey="1">
        {this.state.sections.map((item, secIndex) => {
          console.log(item);
          return (
            <TabPane
              tab={
                <div className="ReportTabHeader">
                  <div>{item.subject_name}</div>
                  <div>
                    {item.totalSectionMarks}/{item.total_marks}
                  </div>
                </div>
              }
              key={secIndex}
            >
              <Row>
                <Col span={3}>
                  <div>
                    <Anchor>
                      {/* <Link href="#components-anchor-overall" title="Basic demo" />
                  <Link
                    href="#components-anchor-difficulty"
                    title="Static demo"
                  /> */}
                      {/* <Menu style={{ border: "none" }}>
                        <Menu.Item key="Report"> */}
                      <div style={{ height: "30px", fontSize: "15px" }}>
                        <Link
                          style={{ fontSize: "40px", padding: "50px" }}
                          href="/report#components-anchor-overall"
                          title="Overall"
                        />
                      </div>
                      {/* Overall
                      </Link> */}
                      {/* </Menu.Item>

                        <Menu.Item key="10"> */}{" "}
                      <div style={{ height: "30px", fontSize: "15px" }}>
                        <Link
                          style={{ fontSize: "40px", padding: "10px" }}
                          href="/report#components-anchor-difficulty"
                          title="Difficulty Levels"
                        />
                      </div>
                      {/* Difficulty Levels
                      </Link> */}
                      {/* </Menu.Item>
                        <Menu.Item key="11"> */}{" "}
                      <div style={{ height: "30px", fontSize: "15px" }}>
                        <Link
                          style={{ fontSize: "40px", padding: "10px" }}
                          href="/report#components-anchor-type"
                          title="Question Type"
                        />
                      </div>
                      {/* Answer Key
                      </Link> */}
                      {/* </Menu.Item>
                        <Menu.Item key="12"> */}{" "}
                      <div style={{ height: "30px", fontSize: "15px" }}>
                        <Link
                          style={{ fontSize: "40px", padding: "10px" }}
                          href="/report#components-anchor-answer"
                          title="Answer Key"
                        />
                      </div>
                      {/* <div style={{ height: "1px", fontSize: "20px" }}>
                        <Link />
                      </div>
                      <div style={{ height: "1px", fontSize: "20px" }}>
                        <Link />
                      </div> */}
                      {/* Answer Key
                      </Link> */}
                      {/* </Menu.Item>
                      </Menu> */}
                    </Anchor>
                  </div>
                </Col>
                <Col span={20} offset={1}>
                  <div className="OverallReport">
                    <div>
                      <span
                        id="components-anchor-overall"
                        className="SectionHeaderReport"
                      >
                        Overall
                      </span>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td className="firstColumnReport DifficultCard">
                            <div className="PlotCardSection">
                              <div className="ReportPlotSectionHeader">
                                <div>
                                  <DashboardOutlined />
                                  Score
                                </div>
                              </div>
                              <div className="ReportPlotSectionHeader">
                                <div>
                                  {item.totalSectionMarks}/{item.total_marks}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="DifficultCard">
                            <div className="PlotCard">
                              <div className="ReportPlotHeader">
                                <div>
                                  <PieChartOutlined />
                                  Accuracy
                                </div>
                                <div>
                                  {(item.total_correct_answer * 100) /
                                    (item.total_correct_answer +
                                      item.total_wrong_answer +
                                      item.total_notAttempted_answer)}
                                  %
                                </div>
                              </div>
                              <div className="ReportPlot">
                                <Plot
                                  data={[
                                    {
                                      type: "pie",
                                      values: [
                                        item.total_correct_answer,
                                        item.total_wrong_answer,
                                        item.total_notAttempted_answer,
                                      ],
                                      labels: [
                                        "Correct",
                                        "Incorrect",
                                        "Not Attempted",
                                      ],
                                    },
                                  ]}
                                  layout={{
                                    width: 350,
                                    yaxis: {
                                      range: [
                                        item.total_nMarks,
                                        item.totalMarks,
                                      ],
                                    },
                                    showlegend: false,
                                    height: 274,
                                  }}
                                  config={{ displayModeBar: false }}
                                />{" "}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ width: "100%" }}>
                    <span
                      id="components-anchor-difficulty"
                      className="SectionHeaderReport"
                    >
                      Difficulty Levels
                    </span>
                    <p>
                      Performance across questions of different difficulty
                      levels like Easy, Medium & Hard
                    </p>
                    <br />
                    <div style={{ width: "100%", minWidth: "1000px" }}>
                      {/* <table>
                        <tbody style={{ backgroundColor: "#F5F7FA" }}>
                          <tr>
                            <td className="DifficultCard"> */}
                      <Row style={{ backgroundColor: "#F5F7FA" }}>
                        <Col className="DifficultCard">
                          <div
                            className="PlotCard"
                            style={{
                              backgroundColor: "white",
                              width: "30%",
                            }}
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <div>Easy</div>
                              <div>{item.easy.total}</div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.easy.correct,
                                      item.easy.wrong,
                                      item.easy.notAttempted,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      item.easy.total !== 0
                                        ? item.easy.total
                                        : 1,
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.easy.correct * 100) /
                                          item.easy.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />{" "}
                            </div>
                          </div>
                        </Col>
                        {/* </td>
                            <td className="DifficultCard"> */}{" "}
                        <Col className="DifficultCard">
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "30%",
                            }}
                            className="PlotCard"
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <div>Medium</div>
                              <div>{item.medium.total}</div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.medium.correct,
                                      item.medium.wrong,
                                      item.medium.notAttempted,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      item.medium.total !== 0
                                        ? item.medium.total
                                        : 1,
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.medium.correct * 100) /
                                          item.medium.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />
                            </div>
                          </div>
                        </Col>
                        {/* </td>
                            <td className="DifficultCard"> */}
                        <Col className="DifficultCard">
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "30%",
                            }}
                            className="PlotCard "
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <div>Hard</div>
                              <div>{item.hard.total}</div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.hard.correct,
                                      item.hard.wrong,
                                      item.hard.notAttempted,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      item.hard.total !== 0
                                        ? item.hard.total
                                        : 1,
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.hard.correct * 100) /
                                          item.hard.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />{" "}
                            </div>
                          </div>{" "}
                        </Col>
                        {/* </td>
                          </tr>
                        </tbody>
                      </table> */}
                      </Row>
                    </div>
                  </div>
                  <br />
                  <div style={{ width: "100%" }}>
                    <span
                      id="components-anchor-type"
                      className="SectionHeaderReport"
                    >
                      Question Type
                    </span>
                    <p>
                      Performance across questions types i.e. MCQ,MRQ and True
                      and False.
                    </p>
                    <br />
                    <div style={{ width: "100%", minWidth: "1000px" }}>
                      {/* <table>
                        <tbody style={{ backgroundColor: "#F5F7FA" }}>
                          <tr>
                            <td className="DifficultCard"> */}
                      <Row style={{ backgroundColor: "#F5F7FA" }}>
                        <Col className="DifficultCard">
                          <div
                            className="PlotCard"
                            style={{
                              backgroundColor: "white",

                              // width: "10%",
                            }}
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <h3>MCQ</h3>
                              <p>{item.total_MCQ} Questions</p>
                            </div>
                            <div style={{ position: "relative" }}>
                              <br />
                              <h3
                                style={{
                                  width: "50%",
                                  margin: "0",
                                  float: "left",
                                }}
                              >
                                <strong> Accuracy</strong>
                              </h3>
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                {isNaN(
                                  (
                                    item.total_MCQ_correct /
                                    item.total_MCQ_attempt
                                  ).toFixed(2) * 100
                                )
                                  ? 0
                                  : (
                                      item.total_MCQ_correct /
                                      item.total_MCQ_attempt
                                    ).toFixed(2) * 100}
                                %
                              </div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.total_MCQ_correct,
                                      item.total_MCQ_attempt -
                                        item.total_MCQ_correct,
                                      item.total_MCQ - item.total_MCQ_attempt,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      Math.max(
                                        1,
                                        item.total_MCQ_correct,
                                        item.total_MCQ_attempt -
                                          item.total_MCQ_correct,
                                        item.total_MCQ - item.total_MCQ_attempt
                                      ),
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.easy.correct * 100) /
                                          item.easy.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />{" "}
                            </div>
                          </div>
                          {/* </td>
                            <td className="DifficultCard"> */}
                        </Col>
                        <Col className="DifficultCard">
                          {" "}
                          <div
                            style={{
                              backgroundColor: "white",

                              // width: "10%",
                            }}
                            className="PlotCard"
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <h3>MRQ</h3>
                              <p>{item.total_MRQ} Questions</p>
                            </div>
                            <div style={{ position: "relative" }}>
                              <br />
                              <h3
                                style={{
                                  width: "50%",
                                  margin: "0",
                                  float: "left",
                                }}
                              >
                                <strong> Accuracy</strong>
                              </h3>
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                {isNaN(
                                  (
                                    item.total_MRQ_correct /
                                    item.total_MRQ_attempt
                                  ).toFixed(2) * 100
                                )
                                  ? 0
                                  : (
                                      item.total_MRQ_correct /
                                      item.total_MRQ_attempt
                                    ).toFixed(2) * 100}
                                %
                              </div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.total_MRQ_correct,
                                      item.total_MRQ_attempt -
                                        item.total_MRQ_correct,
                                      item.total_MRQ - item.total_MRQ_attempt,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      Math.max(
                                        1,
                                        item.total_MRQ_correct,
                                        item.total_MRQ_attempt -
                                          item.total_MRQ_correct,
                                        item.total_MRQ - item.total_MRQ_attempt
                                      ),
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.medium.correct * 100) /
                                          item.medium.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />{" "}
                            </div>
                          </div>{" "}
                        </Col>
                        {/* </td>
                            <td className="DifficultCard"> */}
                        <Col className="DifficultCard">
                          <div
                            style={{
                              backgroundColor: "white",

                              // width: "10%",
                            }}
                            className="PlotCard "
                          >
                            <div className="ReportPlotHeader DifficultCardHeader">
                              <h3>True&False</h3>
                              <p>{item.total_TF} Questions</p>
                            </div>
                            <div style={{ position: "relative" }}>
                              <br />
                              <h3
                                style={{
                                  width: "50%",
                                  margin: "0",
                                  float: "left",
                                }}
                              >
                                <strong> Accuracy</strong>
                              </h3>
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                {isNaN(
                                  (
                                    item.total_TF_correct /
                                    item.total_TF_attempt
                                  ).toFixed(2) * 100
                                )
                                  ? 0
                                  : (
                                      item.total_TF_correct /
                                      item.total_TF_attempt
                                    ).toFixed(2) * 100}
                                %
                              </div>
                            </div>
                            <div className="ReportPlot">
                              <Plot
                                data={[
                                  {
                                    type: "bar",
                                    x: ["Correct", "Incorrect", "Not Ans"],
                                    y: [
                                      item.total_TF_correct,
                                      item.total_TF_attempt -
                                        item.total_TF_correct,
                                      item.total_TF - item.total_TF_attempt,
                                    ],
                                    orientation: "v",
                                  },
                                ]}
                                layout={{
                                  width: 300,
                                  yaxis: {
                                    range: [
                                      0,
                                      Math.max(
                                        1,
                                        0,
                                        item.total_TF_correct,
                                        item.total_TF_attempt -
                                          item.total_TF_correct,
                                        item.total_TF - item.total_TF_attempt
                                      ),
                                    ],
                                  },
                                  showlegend: false,
                                  height: 230,
                                  title: () => (
                                    <div>
                                      <div>Accuracy</div>
                                      <div>
                                        {(item.hard.correct * 100) /
                                          item.hard.total}
                                      </div>
                                    </div>
                                  ),
                                }}
                                config={{ displayModeBar: false }}
                              />{" "}
                            </div>
                          </div>{" "}
                          {/* </td>
                          </tr>
                        </tbody>
                      </table> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <br />
                  <div style={{ width: "100%" }}>
                    <span
                      id="components-anchor-answer"
                      className="SectionHeaderReport"
                    >
                      Answer Key
                    </span>
                    <p>
                      <span style={{ float: "left", marginLeft: "10px" }}>
                        {item.total_correct_answer}
                        <div>
                          <CheckCircleTwoTone twoToneColor="#52c41a" /> Correct
                        </div>
                      </span>
                      <span style={{ float: "left", marginLeft: "10px" }}>
                        {item.total_wrong_answer}
                        <div>
                          <CloseCircleTwoTone twoToneColor="red" /> Incorrect
                        </div>
                      </span>
                      <span style={{ float: "left", marginLeft: "10px" }}>
                        {item.total_notAttempted_answer}
                        <div>
                          <MinusCircleTwoTone twoToneColor="grey" /> Not
                          Answered
                        </div>
                      </span>
                      <span style={{ float: "right", width: "20%" }}>
                        <div>
                          <Select
                            maxTagCount={1}
                            mode="tags"
                            showArrow
                            style={{ width: "100%", height: "5%" }}
                            placeholder="Question Type"
                            onChange={(value) => {
                              this.setState({ typeDropdown: value });
                            }}
                          >
                            <Option key="MCQ">MCQ</Option>
                            <Option key="MRQ">MRQ</Option>
                            <Option key="T&F">T&F</Option>
                          </Select>
                        </div>
                      </span>
                      <span style={{ float: "right", width: "20%" }}>
                        <div>
                          <Select
                            maxTagCount={1}
                            mode="tags"
                            showArrow
                            style={{ width: "100%", height: "5%" }}
                            placeholder="Difficulty Level"
                            onChange={(value) => {
                              let typeDifficulty = value.map((x) =>
                                parseInt(x)
                              );
                              this.setState({ typeDifficulty });
                            }}
                          >
                            <Option key="1">Easy</Option>
                            <Option key="2">Medium</Option>
                            <Option key="3">Hard</Option>
                          </Select>
                        </div>
                      </span>
                    </p>
                    <br />
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        backgroundColor: "#F5F7FA",
                        margin: "10px",
                      }}
                    >
                      <br />
                      <Row>
                        <Col span={18}>
                          <Row>
                            {
                              (answerKey = item.questions
                                .filter((y, i) => {
                                  if (
                                    this.state.typeDropdown &&
                                    this.state.typeDropdown.length === 0
                                  ) {
                                    return 1;
                                  }
                                  return this.state.typeDropdown.includes(
                                    y.type_code
                                  )
                                    ? 1
                                    : 0;
                                })
                                .filter((z, i) => {
                                  if (
                                    this.state.typeDifficulty &&
                                    this.state.typeDifficulty.length === 0
                                  ) {
                                    return 1;
                                  }
                                  return this.state.typeDifficulty.includes(
                                    z.complexity_code
                                  )
                                    ? 1
                                    : 0;
                                })
                                .map((x, index) => {
                                  return (
                                    <Col
                                      style={{
                                        margin: "2%",
                                        boxSizing: "border-box",
                                      }}
                                      span={7}
                                    >
                                      <div
                                        onClick={() => {
                                          this.setState({
                                            view: { ...x, index: index },
                                            visible: true,
                                          });
                                        }}
                                        key={index}
                                        style={{
                                          width: "100%",
                                          height: "300px",
                                          backgroundColor: "white",
                                          borderRadius: "16px",
                                          padding: "15px",
                                          border:
                                            "1px solid " +
                                            (x.isCorrect
                                              ? "green"
                                              : x.isAttempted
                                              ? "red"
                                              : "grey"),
                                          position: "relative",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div>
                                          <strong style={{ fontSize: "20px" }}>
                                            {index + 1}
                                          </strong>
                                          <span
                                            style={{
                                              float: "right",
                                              color: x.isCorrect
                                                ? "green"
                                                : x.isAttempted
                                                ? "red"
                                                : "grey",
                                            }}
                                          >
                                            {x.isCorrect
                                              ? "Correct"
                                              : x.isAttempted
                                              ? "Incorrect"
                                              : "Not Answered"}
                                          </span>
                                        </div>
                                        {parse(x.question_text)}
                                        <span
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            bottom: "10px",
                                            backgroundColor: "#EAECF1",
                                          }}
                                        >
                                          <PlusCircleOutlined /> {x.type_code}
                                        </span>
                                      </div>
                                    </Col>
                                  );
                                }))
                            }
                            {answerKey.length !== 0 ? (
                              <></>
                            ) : (
                              <div style={{ width: "100%" }}>
                                <Empty />
                              </div>
                            )}
                          </Row>
                        </Col>
                        <Col span={5}>
                          All Questions
                          <hr />
                          {item.questions
                            .filter((y, i) => {
                              if (
                                this.state.typeDropdown &&
                                this.state.typeDropdown.length === 0
                              )
                                return 1;
                              return this.state.typeDropdown.includes(
                                y.type_code
                              )
                                ? 1
                                : 0;
                            })
                            .filter((z, i) => {
                              if (
                                this.state.typeDifficulty &&
                                this.state.typeDifficulty.length === 0
                              ) {
                                return 1;
                              }
                              return this.state.typeDifficulty.includes(
                                z.complexity_code
                              )
                                ? 1
                                : 0;
                            })
                            .map((x, index) => {
                              return (
                                <Avatar
                                  onClick={() => {
                                    this.setState({
                                      visible: true,
                                      view: { ...x, index: index },
                                    });
                                  }}
                                  style={{
                                    margin: "5px",
                                    backgroundColor: x.isCorrect
                                      ? "green"
                                      : item.isAttempted
                                      ? "red"
                                      : "rgb(162, 164, 169)",
                                    cursor: "pointer",
                                  }}
                                >
                                  {index + 1}
                                </Avatar>
                              );
                            })}
                        </Col>
                      </Row>
                      <Drawer
                        title={"Question No : " + (this.state.view.index + 1)}
                        width={640}
                        placement="right"
                        closable={false}
                        onClose={this.handleCancel}
                        visible={this.state.visible}
                        // footer={
                        //   <div
                        //     style={{
                        //       textAlign: "left",
                        //     }}
                        //   >
                        //     <Button
                        //       onClick={this.onPreviousClick}
                        //       type="primary"
                        //       style={{ marginRight: 8 }}
                        //     >
                        //       Previous Question
                        //     </Button>
                        //     <Button onClick={this.onNextClick} type="primary">
                        //       Next Question
                        //     </Button>
                        //   </div>
                        // }
                      >
                        <Space direction="vertical">
                          <h2>
                            <span className="QuestionHeader">
                              {!this.state.view.question_text
                                ? this.state.view.question_text
                                : parse(this.state.view.question_text)}
                            </span>
                          </h2>

                          {this.state.view.type_code === "MRQ" ? (
                            <Checkbox.Group
                              options={this.state.view.options.map(
                                (x, index) => {
                                  return {
                                    label: x.text,
                                    value: index,
                                    disabled: true,
                                  };
                                }
                              )}
                              disabled
                              defaultValue={[1]}
                            />
                          ) : this.state.view.type_code === "MCQ" ||
                            this.state.view.type_code === "TNF" ? (
                            <Radio.Group
                              // options={this.state.view.options.map(
                              //   (x, index) => {
                              //     return { label: x.text, value: x.text };
                              //   }
                              // )}
                              disabled
                              value={
                                this.state.view.options
                                  .filter((x) => x.isCorrect)
                                  .map((y) => {
                                    console.log(y);
                                    return y.text;
                                  })[0]
                              }
                            >
                              {this.state.view.options.map((x, idx) => {
                                return (
                                  <Radio key={idx} value={x.text}>
                                    <span
                                      style={{
                                        color: x.isCorrect
                                          ? "green"
                                          : x.isAttempted
                                          ? "red"
                                          : "grey",
                                      }}
                                    >
                                      {" "}
                                      {x.text}
                                    </span>
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          ) : (
                            <div></div>
                          )}

                          <span className="drawerHeader">Complexity</span>
                          <Avatar
                            className="complexity"
                            shape="square"
                            hidden={this.state.view.complexity_code !== 1}
                            style={{
                              backgroundColor: "#55B2FF",
                              verticalAlign: "middle",
                              width: "120px",
                              height: "40px",
                              lineHeight: "40px",
                            }}
                            size="large"
                          >
                            {" "}
                            Easy
                          </Avatar>
                          <Avatar
                            className="complexity"
                            shape="square"
                            hidden={this.state.view.complexity_code !== 2}
                            style={{
                              backgroundColor: "#52C41A",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            Medium
                          </Avatar>
                          <Avatar
                            className="complexity"
                            shape="square"
                            hidden={this.state.view.complexity_code !== 3}
                            style={{
                              backgroundColor: "#F8515B",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            Difficult
                          </Avatar>
                          <br />
                          <span className="drawerHeader">Author</span>
                          <Avatar
                            className="author"
                            shape="square"
                            style={{
                              backgroundColor: "#55B2FF",
                              verticalAlign: "middle",
                            }}
                            size="large"
                          >
                            {" "}
                            {this.state.view.author_name}
                          </Avatar>
                          <br />
                          <span className="drawerHeader">Explanation</span>
                          <p>
                            <span className="explanation">
                              {this.state.view.answer_explanation}
                            </span>
                          </p>
                        </Space>
                      </Drawer>
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          );
        })}
      </Tabs>
    );

    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">
              {this.state.examName} - Report
            </Layout>
            <Layout className="createExamLayout">
              <div
                className="OverallReport"
                style={{ backgroundColor: "#F5F7FA" }}
              >
                {/* <table>
                  <tbody> */}
                <Row>
                  {/* <tr> */}
                  <Col span={8} offset={3}>
                    {/* <td> */}
                    <div
                      className="PlotCard"
                      style={{
                        margin: "5%",
                        // margin: "10%",
                        padding: "5%",
                        backgroundColor: "white",
                        alignContent: "center",
                      }}
                    >
                      <div className="ReportPlotHeader">
                        <div>
                          <DashboardOutlined />
                          Score
                        </div>
                        <div>
                          {this.state.totalMarksReceived}/
                          {this.state.totalMarks}
                        </div>
                      </div>
                      <div className="ReportPlot">
                        <Plot
                          data={[
                            {
                              type: "bar",
                              x: secNames,
                              y: secMarks,
                              orientation: "v",
                            },
                          ]}
                          layout={{
                            width: 250,
                            yaxis: {
                              range: [
                                this.state.total_least_mark,
                                this.state.total_max_mark,
                              ],
                            },
                            showlegend: false,
                            height: 274,
                            title: () => (
                              <div>
                                <div>
                                  <DashboardOutlined />
                                  Score
                                </div>
                                <div>
                                  {this.state.totalMarksReceived}/
                                  {this.state.totalMarks}
                                </div>
                              </div>
                            ),
                          }}
                          config={{ displayModeBar: false }}
                        />{" "}
                      </div>
                    </div>
                    {/* </td> */}
                  </Col>
                  <Col span={8} offset={1}>
                    {/* <td> */}
                    <div
                      className="PlotCard"
                      style={{
                        margin: "5%",
                        // marginLeft: "15%",
                        padding: "5%",
                        backgroundColor: "white",
                      }}
                    >
                      <div className="ReportPlotHeader">
                        <div>
                          <PieChartOutlined />
                          Accuracy
                        </div>
                        <div>{percentage}%</div>
                      </div>
                      <div className="ReportPlot">
                        <Plot
                          data={[
                            {
                              type: "pie",
                              values: [
                                this.state.total_correct_answer,
                                this.state.total_wrong_answer,
                                this.state.total_notAttempted_answer,
                              ],
                              labels: ["Correct", "Incorrect", "Not Attempted"],
                            },
                          ]}
                          layout={{
                            width: 250,
                            yaxis: {
                              range: [
                                this.state.total_least_mark,
                                this.state.total_max_mark,
                              ],
                            },
                            showlegend: false,
                            height: 274,
                          }}
                          config={{ displayModeBar: false }}
                        />{" "}
                      </div>
                    </div>
                    {/* </td> */}
                  </Col>
                  {/* </tr> */}
                </Row>
                {/* </tbody> */}
                {/* </table> */}
              </div>
              <div className="SectionTabPane">{tabs}</div>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default Report;
