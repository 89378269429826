import React from 'react';
import './SelectSubjects.css';
import { Button, Form, Space, message, Input, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { FormOutlined, CheckSquareTwoTone, CloseSquareTwoTone, UploadOutlined } from '@ant-design/icons';
import { authenticationService } from '../../_services';
import { selectorService } from '../../services/selectorService';
import { S3Bucket } from '../../services/S3Bucket';

let subject = [];

class SelectSubjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      classId: authenticationService.currentClassValue,
      examName: props.selectorState.name,
      update: false,
      examId: props.selectorState.examId,
      sections: [],
      currentUser: authenticationService.currentUserValue,
      authorId: props.selectorState.authorId,
      examStatus: props.selectorState.examStatus,
      examTypeId: props.selectorState.examTypeId,
      id: props.selectorState.id,
      image: props.selectorState.image,
      instituteId: props.selectorState.instituteId,
      isActive: props.selectorState.isActive,
      languageId: props.selectorState.languageId,
      subjects: [],
      editing: false,
      updatedName: props.selectorState.name,
      number: 0,
      file: "",
      uploading: false,

    };

    this.uploadProps = {
      onRemove: (file) => {
        this.setState(prev => ({...prev, file: ""}));
      },
      beforeUpload: async (file) => {
        this.setState(prevVal => {
          return {
            ...prevVal,
            file,
            uploading: true,
          }
        });
  
        // uploading to S3 bucket and getting a link in return
        const linkReturn = await S3Bucket.examImageUpload(this.state.id, file);
        // updating the image variable
        this.setState(prev => ({...prev, image: linkReturn}));
        message.success("Image successfully changed!")

        return false;
      },
      onchange: (err, event) => console.log('event'),
      file: this.state.file,
    };

    this.addImageDefault = () => {
      this.setState (prev => {
        return { ...prev, image: 'https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg' }
      });
    }

    this.handleSubjectSelection = this.handleSubjectSelection.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.sendData = this.sendData.bind(this);
    this.handleLoadSubjects = this.handleLoadSubjects.bind(this);
    this.handleSubjectButtonClick = this.handleSubjectButtonClick.bind(this);
    this.checkType = this.checkType.bind(this);
  }

  handleSubjectButtonClick(index) {

    let tempSubjects = this.state.subjects;
    tempSubjects[index].buttonState = !tempSubjects[index].buttonState;
    let array = this.state.subjects.filter(x => x.buttonState);
    this.setState({ subjects: tempSubjects, number: array.length });
    // this.forceUpdate();
  }

  componentDidMount() {
    this.handleLoadSubjects();
  }
  onTextChange = (event) => {
    this.setState({
      examName: event.target.value,
      update: true
    });
  }
  handleLoadSubjects = () => {
    selectorService.loadSubjects(this.state.classId).then(data => {
      // console.log('Success:', data);
      const result = data.result;
      this.state.subjects = [];
      for (let index = 0; index < result.length; index++) {
        let c = {};
        c.label = (result[index]).name;
        c.value = (result[index]).id;
        c.index = index;
        c.buttonState = false;
        this.state.subjects.push(c);
      }
      this.forceUpdate();
    });
  }
  handleSubjectSelection() {
    if (this.state.number === 0) {
      message.warning('Select subjects to continue');
    }
    else {
      let subjectsId = [];
      this.state.subjects.forEach(subject => {
        if (subject.buttonState) {
          subjectsId.push(subject.value);
          this.state.sections.push(subject);
        }
      });
      let json = {};
      json.idList = subjectsId;
      json.name = this.state.examName;
      message.loading({ content: 'Attaching Sections...' });
      selectorService.attachSectionToExam(this.state.id, json).then(data => {
        let result = {};
        result.current = 2;
        result.sections = this.state.sections;
        result.image = this.state.image;
        // console.log(this.state.examName);
        result.name = this.state.examName;
        message.success({ content: 'Sections Attached!', duration: 3 });
        this.sendData(result);
      });
    }

  }
  sendData = (result) => {
    // console.log(result);
    this.props.parentCallback(result);
  }

  checkType = (file) => {
    if (file.type === "image/png") {
      return true;
    } else {
      message.error("Please select PNG files for Exam Icon");
      return false;
    }
  }

  render() {
    return (
      <div className='SelectSubjects'>
        <Form className="exam-form">
          <div className='exam'><table className='examTable'>
            <tbody>
              <tr>
                <td>
                  <div className='examNameOuter'>
                    <div>
                      <img onError={this.addImageDefault} width={50} height={50} src={`${this.state.image}?${Date.now()}`}></img>
                    </div>

                    <div className='examName'>
                      <div>
                        <span>Exam Name</span>
                      </div>
                      <div>
                        <span hidden={this.state.editing} className='examNameSpan'>{this.state.examName}</span>
                        <span> <Input value={this.state.updatedName} onChange={(e) => this.setState({ updatedName: e.target.value })}
                          hidden={!this.state.editing} style={{ width: '60%' }} placeholder="Exam Name" />
                        </span>
                      </div>
                    </div>

                    <div className='center-flex'>
                      <ImgCrop beforeCrop={this.checkType}>
                        <Upload {...this.uploadProps} maxCount={1} showUploadList={false}>
                          <Button type="primary" icon={<UploadOutlined />}>Change Icon</Button>
                        </Upload>
                      </ImgCrop>
                    </div>

                  </div>
                </td>
                <td className='examNameEdit' style={{ cursor: 'pointer', width: '5px' }} >
                  <div onClick={() => this.setState({ editing: true })}>
                    <FormOutlined hidden={this.state.editing} style={{ fontSize: '16px', color: '#1890FF' }} twoToneColor='#1890FF' />
                  </div>
                  <div hidden={!this.state.editing} onClick={() => { this.setState({ editing: false, updatedName: this.state.examName }) }}>
                    <CloseSquareTwoTone />
                  </div>
                  <div onClick={() => {
                    const { examId, updatedName, image, authorId, examTypeId, id } = this.state;
                    selectorService.updateExam({ id, examId, name: updatedName, image, authorId, examTypeId })
                      .then((res) => { }).catch((err) => { console.log(err) });
                    this.setState({ editing: false, examName: this.state.updatedName });
                  }} hidden={!this.state.editing}>
                    <CheckSquareTwoTone />
                  </div>
                </td>
              </tr>
            </tbody>
          </table></div>


          <br />
          <span>Select Subject</span>
          <br />
          <div className='subjectButtons'>
            <Space>
              {this.state.subjects.map((subject, index) => {
                return (
                  <Button
                    onClick={() => this.handleSubjectButtonClick(index)}
                    className={subject.buttonState ? 'subjectButtonTrue' : 'subjectButtonFalse'}
                    style={{ color: subject.buttonState ? '#1890FF' : '#cccccc', borderColor: subject.buttonState ? '#1890FF' : '#cccccc' }}
                    key={index}
                    title={subject.label} >{subject.label}</Button>
                )
              })}
            </Space>
          </div>
          <br />
          <Button type="primary" onClick={e => { this.handleSubjectSelection() }}>Continue</Button>
        </Form>
      </div>
    )
  };
};

export default SelectSubjects;
