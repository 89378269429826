import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Layout } from 'antd';

import { history } from './_helpers';
import { authenticationService } from './_services';

import { PrivateRoute } from './component/PrivateRoute';
import Login from './component/Login/Login';
import Dashboard from './component/Dashboard/Dashboard';

// import 'antd/dist/antd.less'; // imported inside App.less
import './App.less';

// import AllQuestions from './component/Questions/AllQuestions';
import CreateAccount from './component/Login/CreateAccount';
// import MyQuestions from './component/Questions/MyQuestions';
// import Draft from './component/Questions/DraftQuestion/Draft'
import Classes from './component/Usergroup/Classes';
import Class from './component/Class/CreateClass';
import ViewClass from './component/Class/ViewClass';
// import AddQuestion from './component/Questions/AddQuestion';
// import Bookmark from './component/Questions/Bookmark'
// import CreateQuestion from './component/Questions/CreateQuestion'
// import UploadQuestion from './component/Questions/UploadQuestion'
import CreateBatch from './component/batches/CreateBatch';
import ViewBatch from './component/batches/ViewBatch';
// import CreateExam from './component/Exams/CreateExam';
import DraftExam from './component/Exams/DraftExam';
import CreatePractise from './component/Exams/createPractise';
import DraftPractice from './component/Practice/DraftPractice';
import LivePractice from './component/Practice/LivePractice';
import UpcomingPractice from './component/Practice/UpcomingPractice';

import AssociateBatchPractice from './component/Practice/PracticeAssociateBatch';


import LiveExam from './component/Exams/LiveExam';
import UpcomingExam from './component/Exams/UpcomingExam';
import AssociateBatch from './component/Exams/AssociateBatch';
import ExpiredExam from './component/Exams/ExpiredExam';

import EditQuestion from './component/Questions/EditQuestion/EditQuestion';
import UpcomingExams from './component/Student/Exams/UpcomingExams/UpcomingExams';
import LiveExams from './component/Student/Exams/LiveExams/LiveExams';
import AttemptExam from './component/Student/Exams/AttemptExam/AttemptExam';
import Report from './component/Student/Exams/Report/Report';
import PracticeExams from './component/Student/Exams/PracticeExam/PracticeExams';
import AttemptRevision from './component/Student/Exams/AttemptPractice/AttemptRevision';
import AttemptPractice from './component/Student/Exams/AttemptPractice/AttemptPractice';
import Authenticate from './component/Login/Authenticate';
import ResetPassword from './component/Login/ResetPassword';
import FirebaseAction from './component/Login/FirebaseAction';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  logout() {
    authenticationService.logout();
    history.push('/login');
  }

  render() {
    return (
      <Layout className='logged-in-view'>
        <BrowserRouter history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/createAccount" component={CreateAccount} />
            <Route exact path="/action" component={FirebaseAction} />
            <Route exact path="/Student"  component={Authenticate} />
            <PrivateRoute exact path="/classes" comp={Classes} />
            <PrivateRoute exact path="/class" comp={Class} />
            <PrivateRoute exact path="/editQuestion" comp={EditQuestion} />
            <PrivateRoute exact path="/draftExams" comp={DraftExam} />
            <PrivateRoute exact path="/liveExam" comp={LiveExam} />
            <PrivateRoute exact path="/expiredExams" comp={ExpiredExam} />
            <PrivateRoute exact path="/upcomingExam" comp={UpcomingExam} />
            <PrivateRoute exact path="/liveExams" comp={LiveExams} /> 
            <PrivateRoute exact path="/practiceExams" comp={PracticeExams} />              
            <PrivateRoute exact path="/upcomingExams" comp={UpcomingExams} /> 
            <PrivateRoute exact path="/attemptRevision" comp={AttemptRevision} />
            <PrivateRoute exact path="/attemptPractice" comp={AttemptPractice} />
            <PrivateRoute exact path="/attemptExam" comp={AttemptExam} />   
            <PrivateRoute exact path="/createbatch" comp={CreateBatch} />
            <PrivateRoute exact path="/associatebatch" comp={AssociateBatch} />
            <PrivateRoute exact path="/viewbatch" comp={ViewBatch} />     
            <PrivateRoute exact path="/report" comp={Report} />
            <PrivateRoute exact path="/viewclass" comp={ViewClass} /> 
            <PrivateRoute exact path="/createPractise" comp={CreatePractise} /> 
            <PrivateRoute exact path="/draftPractise" comp={DraftPractice} />   
            <PrivateRoute exact path="/livePractise" comp={LivePractice} />   
            <PrivateRoute exact path="/upcomingPractise" comp={UpcomingPractice} /> 
            <PrivateRoute exact path="/associatePracticeBatch" comp={AssociateBatchPractice} />   
            {/* Always have non-exact path at the very end to eliminate conflicts with other 'exact' routes */}
            {/* <PrivateRoute exact path="/createExam" comp={CreateExam} /> do not uncomment this */}
            {/* <PrivateRoute exact path="/draftQuestions" comp={Draft} /> do not uncomment this */}
            {/* <PrivateRoute exact path="/bookmarkQuestions" comp={Bookmark} /> do not uncomment this */}
            {/* <PrivateRoute exact path="/myQuestions" comp={MyQuestions} />  do not uncomment this */}
            {/* <PrivateRoute exact path="/addQuestion" comp={AddQuestion} />  do not uncomment this */}
            <PrivateRoute path="/" comp={Dashboard} />
          </Switch>
        </BrowserRouter>
      </Layout>
    );
  }
};


export default App;
