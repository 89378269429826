import React from 'react';
import { Link } from 'react-router-dom';
import './Selector.css';
import { Cascader, Dropdown, Menu } from 'antd';
import { selectorService } from '../../services/selectorService';
import { history } from '../../_helpers';
import { UploadOutlined } from '@ant-design/icons'
import equal from 'fast-deep-equal';
import { render } from '@testing-library/react';
let subject = [

];

let category = [

];



let type = [


];

const menu = (
	<Menu>
		<Menu.Item key="1">
			DOC
		</Menu.Item>
		<Menu.Item key="2">
			XLS
		</Menu.Item>
		<Menu.Item key="3">
			CSV
		</Menu.Item>
	</Menu>
);
let complexity = [
	{
		label: 'Easy',
		value: 1
	},
	{
		label: 'Medium',
		value: 2
	},
	{
		label: 'Difficult',
		value: 3
	}

];



function onChangeSubject(value, context) {
	console.log("Child on change subject: " + value);

	loadCategory(value, context);

}





function onChangeQuestionType(value, context) {
	selectorService.loadQuestionTypes();
}

function loadCategory(value, context) {
	selectorService.loadCategory(value[0]).then(data => {
		const result = data.result;
		category = [];
		for (let index = 0; index < result.length; index++) {
			let c = {};
			c.label = (result[index]).name;
			c.value = (result[index]).id;
			category.push(c);
		}
		context.forceUpdate();
	});
}

class Selector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			classes: 1,
			module_id: "",
			module: "",
			questionType: "",
			complexity: "",
			data: null,
			page: props.page,
			subject: []
		}
		this.sendData = this.sendData.bind(this);
		this.onChangeSubjectData = this.onChangeSubjectData.bind(this);
		this.onChangeQuestionTypeData = this.onChangeQuestionTypeData.bind(this);
		this.onChangeComplexityData = this.onChangeComplexityData.bind(this);
		this.onChangeCategoryData = this.onChangeCategoryData.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleCreateQuestion = this.handleCreateQuestion.bind(this);
	};

	componentDidMount() {
		window.addEventListener('load', this.handleLoad.bind(this));
		//  this.onChangeQuestionTypeData(['MCQ']);
		this.handleLoad();
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props.selectorState, prevProps.selectorState)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
		{
			this.handleLoad();
		}
	}
	componentWillUnmount() {
		window.removeEventListener('load', this.handleLoad)
	}

	handleCreateQuestion() {
		history.push('/createQuestion');
	}


	handleLoad = () => {
		selectorService.loadSubjects(1).then(data => {
			// console.log('Success:', data);
			const result = data?.result;
			subject = [];
			for (let index = 0; index < result?.length; index++) {
				let c = {};
				c.label = (result[index]).name;
				c.value = (result[index]).id;
				subject.push(c);
			}
			this.forceUpdate();
		});

		selectorService.loadQuestionTypes().then(data => {
			const result = data?.result;
			type = [];
			for (let index = 0; index < result?.length; index++) {
				let c = {};
				c.label = (result[index]).name;
				c.value = (result[index]).code;
				type.push(c);

			}
			// console.log(window.location.pathname,data);
			if (window.location.pathname === '/addQuestion') {
				this.onChangeQuestionTypeData(['MCQ']);
			}
			else {
				this.onChangeQuestionTypeData([]);
			}
			this.forceUpdate();
		});

	}
	onChangeSubjectData = (event) => {
		// console.log('here', event);
		if (event === undefined || event.length === 0) {
			this.state.module = '';
			this.state.module_id = '';
			this.sendData();
			// this.refs["category"].state.value = [];
			category = [];
		} else {
			console.log("Child sending selector Call: " + event[0]);
			this.state.module = 'subject';
			this.state.module_id = event[0];
			this.state.subject = event;
			// this.refs["category"].state.value = [];
			this.sendData();
			onChangeSubject(event, this);
		}

	}


	onChangeQuestionTypeData = (event) => {
		// console.log(event);
		if (event === undefined || event.length === 0) {
			this.state.questionType = '';
			this.sendData();
		} else {
			// console.log("Child sending selector Call: " + event[0]);
			this.state.questionType = event[0];
			this.sendData();
			onChangeQuestionType(event, this);
		}

	}

	onChangeComplexityData = (event) => {
		if (event === undefined || event.length === 0) {
			this.state.complexity = '';
			this.sendData();
		} else {
			console.log("Child sending selector Call: " + event[0]);
			this.state.complexity = event[0];
			this.sendData();
		}

	}

	onChangeCategoryData = (event) => {
		if (event === undefined || event.length === 0) {
			this.state.module = '';
			this.state.module_id = '';
			this.onChangeSubjectData(this.state.subject);
			// this.sendData();
		} else {
			console.log("Child sending selector Call: " + event[0]);
			this.state.module = 'category';
			this.state.module_id = event[0];
			this.sendData();
		}

	}

	sendData = () => {
		this.props.parentCallback(this.state);
	}

	render() {
		if(!subject && !type) { return <h4>Waiting for authentication</h4> }

		return (
			<div className="selector">
				<div className="selector-item">
					<Cascader size="small" ref="subject" key="subject" id="subject" options={subject} placeholder="Subject" 
					onChange={this.onChangeSubjectData.bind(this)} />
				</div>
				<div className="selector-item">
					<Cascader size="small" id="topic" key="topic" ref="category" options={category} 
					placeholder="Topic" onChange={this.onChangeCategoryData.bind(this)} />
				</div>
				<div className="selector-item">
					<Cascader defaultValue={window.location.pathname === '/addQuestion' ? ["Multiple Choice Questions"] : []} size="small" 
					key="type" options={type} placeholder="Question Type" onChange={this.onChangeQuestionTypeData.bind(this)} />
				</div>
				<div className="selector-item" hidden={(this.state.page !== 'allQuestions')}>
					<Cascader size="small" key="complexity" options={complexity} placeholder="Complexity" 
					onChange={this.onChangeComplexityData.bind(this)} />
				</div>
				<div className="selector-item" hidden={(this.state.page !== 'allQuestions')}>
					<Dropdown.Button className="selectorLeftButton" size={"small"} type="primary" overlay={menu} icon={<UploadOutlined />}>
						<Link to="/addQuestion"> + New</Link>
					</Dropdown.Button>
				</div>
			</div>
		)
	};
};

export default Selector;
