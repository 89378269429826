import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './_services/i18n-config';
import App from './App';

ReactDOM.render(
    <Suspense fallback="<p>Patience is a virtue</p>">
        <App />
    </Suspense>,
    document.getElementById('root')
);


