import {
  Steps,
  Button,
  message,
  Layout,
  List,
  Avatar,
  Skeleton,
  Empty,
  Row,
  Col,
  Checkbox,
} from "antd";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import "../Exams/DraftExam.css";
import { Siderc } from "../Main/Siderc";
import React from "react";
import { Headerc } from "../Main/Headerc";
import { Link } from "react-router-dom";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content } = Layout;

class AssociateBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          start_date: this.props.location.state.start_date,
          total_questions: this.props.location.state.total_questions,
          name: this.props.location.state.name,
          id: this.props.location.state.id,
          author_name: this.props.location.state.author_name,
        },
      ],
      total_count: 0,
      total_pages: 0,
      processing: true,
      batches: [],
      selectedId: [],
      response: [],
      resp: [],
    };
  }

  componentDidMount() {
    selectorService
      .getAllBatch({ class_id: 1 })
      .then((res) => {
        let batches = res.result;
        console.log(batches);
        // selectorService()

        return batches;
      })
      .then((batches) => {
        selectorService
          .getExamBatch({ id: this.state.data[0].id })
          .then((response) => {
            console.log(response);
            this.setState({
              batches,
              selectedId: response.result.idList,
              resp: response.result.idList,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //   componentDidMount() {
  //     selectorService
  //       .draftExam({ pageSize: 10, pageNumber: 1, classId: 1 })
  //       .then((res) => {
  //         console.log(res);
  //         this.setState({
  //           data: res.result.exams,
  //           total_count: res.result.total_count,
  //           total_pages: res.result.total_pages,
  //           processing: false,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }

  //   handleChange = (pageNumber, pageSize) => {
  //     selectorService
  //       .upcomingExam({ pageNumber: pageNumber, pageSize: pageSize, classId: 1 })
  //       .then((res) => {
  //         this.setState({
  //           data: res.result.exams,
  //           total_count: res.result.total_count,
  //           total_pages: res.result.total_pages,
  //           processing: false,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  render() {
    console.log(this.state);
    if (this.state.data.length !== 0)
      return (
        <Layout>
          <Siderc />
          <Layout>
            <Headerc />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <Layout className="createExamLayoutHeader">Batches</Layout>
              <Layout className="createExamLayout">
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.data}
                  pagination={false}
                  renderItem={(item, index) => {
                    let currentTime = new Date();
                    let loginTime = new Date(item.start_date);
                    const diff = currentTime.getTime() - loginTime.getTime();
                    // console.log(diff,loginTime,currentTime,item.updated_on);
                    var years = Math.floor(
                      diff / (1000 * 60 * 60 * 24 * 30 * 12)
                    );
                    var months = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30 * 12)) /
                        (1000 * 60 * 60 * 24 * 30)
                    );
                    var days = Math.floor(
                      (diff % (1000 * 60 * 60 * 24 * 30)) /
                        (1000 * 60 * 60 * 24)
                    );

                    var hours = Math.floor(
                      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    var minutes = Math.floor((diff % 3.6e6) / 6e4);
                    let updatedOn =
                      years > 0
                        ? `${years} year(s)/${months} months`
                        : months > 0
                        ? `${months} month(s)/${days} days`
                        : days > 0
                        ? `${days} day(s)/${hours} hours`
                        : minutes > 0
                        ? `${hours} hour(s)${minutes}minutes(s)`
                        : minutes + "minutes";
                    console.log(item);
                    return (
                      <List.Item
                        className="DraftExam"
                        key={index}
                        actions={[
                          <Button
                            onClick={(e, newValue) => {
                              selectorService
                                .addBatchToPractice({
                                  id: this.state.data[0].id,
                                  idList: this.state.selectedId,
                                })
                                .then((res) => {
                                  if (!res) {
                                    message.error("Some error occured");
                                  } else {
                                    message.success(
                                      "Batches added successfully"
                                    );
                                  }
                                });
                            }}
                            type="primary"
                          >
                            <Link to={{ pathname: "/upcomingExam" }}>
                              Update
                            </Link>
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                              size="large"
                              shape="square"
                              className="DraftExamImage"
                            />
                          }
                          size="large"
                          bordered="true"
                          title={item.name}
                          description={
                            <>
                              <div className="MyExamDiv">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Last Update</th>
                                      <th>Author Name</th>
                                      <th>Total Questuons</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{updatedOn}</td>
                                      <td>{item.author_name}</td>
                                      <td>{item.total_questions}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
                <br />
                <div className="MyExamDiv">
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={this.state.selectedId}
                    onChange={(checkedValues) => {
                      console.log("checked = ", checkedValues);

                      let deletedId = this.state.selectedId.filter(
                        (x) =>
                          !checkedValues.includes(x) &&
                          !this.state.response.includes(x) &&
                          this.state.resp.includes(x)
                      )[0];
                      if (!isNaN(parseInt(deletedId))) {
                        selectorService
                          .deleteExamBatch({
                            id: this.state.data[0].id,
                            idList: [deletedId],
                          })
                          .then((res) => {
                            console.log(res);
                            if (!res.result) {
                              message.error("Some error occured");
                            } else {
                              this.setState({
                                selectedId: checkedValues,
                                response: this.state.response.concat([
                                  deletedId,
                                ]),
                              });
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      } else this.setState({ selectedId: checkedValues });
                    }}
                  >
                    <Row>
                      {this.state.batches.map((x, idx) => {
                        if (idx % 3 === 0)
                          return (
                            <Col span={7}>
                              <div
                                key={idx}
                                style={{
                                  minHeight: "7vh",
                                  backgroundColor: "#F5F6F7",
                                  marginBottom: "10%",
                                  padding: "2vh",
                                  width: "100%",
                                }}
                              >
                                <Checkbox
                                  style={{ width: "100%" }}
                                  value={x.id}
                                  // disabled={true}
                                >
                                  <strong>{x.name}</strong>
                                  <span
                                    style={{ marginLeft: "2%", width: "100%" }}
                                  >
                                    {"- (" + x.student_count + " Students)"}
                                  </span>
                                  {/* <Link
                                      onClick={() => {
                                        selectorService
                                          .deleteBatch(this.state.batches[idx].id)
                                          .then((res) => {
                                            let batches = this.state.batches.filter(
                                              (x) =>
                                                x.id !==
                                                this.state.batches[idx].id
                                            );
                                            this.setState({ batches });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      }}
                                      style={{ float: "right", marginLeft: "2%" }}
                                      to="#"
                                    >
                                      <DeleteTwoTone />
                                    </Link>
  
                                    <Link
                                      style={{ float: "right" }}
                                      to={{
                                        pathname: "/createbatch",
                                        state: this.state.batches[idx],
                                      }}
                                    >
                                      <EditTwoTone />
                                    </Link> */}
                                </Checkbox>
                              </div>
                            </Col>
                          );
                        else
                          return (
                            <Col span={7} offset={1}>
                              <div
                                key={idx}
                                style={{
                                  minHeight: "7vh",
                                  backgroundColor: "#F5F6F7",
                                  marginBottom: "10%",
                                  padding: "2vh",
                                }}
                              >
                                <Checkbox
                                  style={{ width: "100%" }}
                                  value={x.id}
                                >
                                  <strong>{x.name}</strong>
                                  <span style={{ marginLeft: "2%" }}>
                                    {"- (" + x.student_count + " Students)"}
                                  </span>
                                  {/* <Link
                                      onClick={() => {
                                        selectorService
                                          .deleteBatch(this.state.batches[idx].id)
                                          .then((res) => {
                                            let batches = this.state.batches.filter(
                                              (x) =>
                                                x.id !==
                                                this.state.batches[idx].id
                                            );
                                            this.setState({ batches });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      }}
                                      style={{ float: "right", marginLeft: "2%" }}
                                      to="#"
                                    >
                                      <DeleteTwoTone />
                                    </Link>
                                    <Link
                                      style={{ float: "right" }}
                                      to={{
                                        pathname: "/createbatch",
                                        state: this.state.batches[idx],
                                      }}
                                    >
                                      <EditTwoTone />
                                    </Link> */}
                                </Checkbox>
                              </div>
                            </Col>
                          );
                      })}
                    </Row>
                  </Checkbox.Group>
                </div>
              </Layout>
            </Content>
          </Layout>
        </Layout>
      );
    else if (this.state.processing)
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">Batches</Layout>
                <Layout className="createExamLayout">
                  <List
                    itemLayout="horizontal"
                    dataSource={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    pagination={{
                      onChange: (page, pageSize) => {
                        this.handleChange(page, pageSize);
                      },

                      total: this.state.total_count,
                    }}
                    renderItem={(item, index) => {
                      return (
                        <List.Item
                          className="DraftExam"
                          key={index}
                          // actions={[
                          //   <Button
                          //     onClick={(e, newValue) => {
                          //       console.log(item, e);
                          //     }}
                          //     type="primary"
                          //   >
                          //     <Link
                          //       to={{
                          //         pathname: "/createExam",
                          //         state: { id: item.id, current: step - 1 },
                          //       }}
                          //     >
                          //       Recreate Exam
                          //     </Link>
                          //   </Button>,
                          // ]}
                        >
                          <Skeleton>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src="https://cdn.xl.thumbs.canstockphoto.com/exam-written-on-a-chalkboard-books-pencils-and-an-apple-on-foreground-picture_csp2468961.jpg"
                                  size="large"
                                  shape="square"
                                  className="DraftExamImage"
                                />
                              }
                              size="large"
                              bordered="true"
                              title={parse(`<b>${"item.name"}</b>`)}
                              description={parse(
                                `<b>Progress - Step</b> ${"step"} <b>Updated At:</b> ${"updatedOn"}  <b>Author Name:</b> ${"item.author_name"} <b>Sections Count: </b>${"item.sections_count"}`
                              )}
                            />
                          </Skeleton>
                        </List.Item>
                      );
                    }}
                  />
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </>
      );
    else
      return (
        <>
          <Layout>
            <Siderc />
            <Layout>
              <Headerc />

              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                }}
              >
                <Layout className="createExamLayoutHeader">Batches</Layout>
                <Layout className="createExamLayout">
                  <Empty />
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </>
      );
  }
}

export default AssociateBatch;
