import React from "react";
import { selectorService } from "../../services/selectorService";
import "antd/dist/antd.css";
import "./QuestionTable.css";
import parse from "html-react-parser";
import { Table, Space, Avatar, Menu, Dropdown, Drawer, Button, Checkbox, Radio, Pagination, Skeleton, Popconfirm, Empty, Typography } from "antd";
import equal from "fast-deep-equal";
import { EyeOutlined, EyeFilled, StarOutlined, StarFilled, OrderedListOutlined, TeamOutlined, MoreOutlined, PlusOutlined, 
  MinusOutlined, UserAddOutlined, UserDeleteOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
// import AllQuestionNew from "./AllQuestionNew";
import { uniqueId } from "lodash";

let subject = [];

let category = [];

let subcategory = [];

let type = [];

let complexity = [];

const data = [];

const bottomOptions = [
  { label: "bottomLeft", value: "bottomLeft" },
  { label: "bottomCenter", value: "bottomCenter" },
  { label: "bottomRight", value: "bottomRight" },
  { label: "none", value: "none" },
];

class QuestionTableNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bottom: "bottomRight",
      classes: props.selectorState.classes,
      module_id: props.selectorState.category? props.selectorState.category : props.selectorState.subject,
      module: props.selectorState.category? 'category' : props.selectorState.subject? 'subject' : "",
      questionType: props.selectorState.questionType,
      complexity: props.selectorState.complexity,
      selectedRowKeys: [],
      data: [],
      map: {},
      visible: false,
      view: {},
      currentIndex: -1,
      result: {},
      page: 1,
      pageSize: 10,
      disabled: true,
      processing: false,
      pop: false,
    };

    this.confirm = (
      <Popconfirm
        // disabled={this.state.disabled}
        title="Are"
        okText="Yes"
        cancelText="No"
        onCancel={() => {}}
        onConfirm={() => {}}
      ></Popconfirm>
    );

    this.columns = [
      {
        title: () => {
          return (
            <>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length > 0 ? false : true
                      }
                      onClick={this.attachSubCategory.bind(this)}
                    >
                      <span>
                        <PlusOutlined style={{ padding: "3px" }} />
                        Attach Subcategory
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length > 0 ? false : true
                      }
                      onClick={this.detachSubCategory.bind(this)}
                    >
                      <span>
                        <MinusOutlined style={{ padding: "3px" }} />
                        Detach Subcategory
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length > 0 ? false : true
                      }
                      onClick={this.attachTag.bind(this)}
                    >
                      <span>
                        <UserAddOutlined style={{ padding: "3px" }} />
                        Attach Tag
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length > 0 ? false : true
                      }
                      onClick={this.detachTag.bind(this)}
                    >
                      <span>
                        <UserDeleteOutlined style={{ padding: "3px" }} />
                        Detach Tag
                      </span>
                    </Menu.Item>

                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length == 1 ? false : true
                      }
                      onClick={this.editQuestions.bind(this)}
                    >
                      <span>
                        <EditOutlined style={{ padding: "3px" }} />
                        Edit
                      </span>
                    </Menu.Item>
                    <Menu.Item
                      key="tableMenu1"
                      disabled={
                        this.state.selectedRowKeys.length > 0 ? false : true
                      }
                      onClick={() => this.setState({ pop: true })}
                    >
                      <DeleteOutlined style={{ padding: "3px" }} />
                      Delete
                      {this.state.pop ? this.confirm : null}
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <a
                  className="ant-dropdown-link"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <Typography.Text strong>Actions</Typography.Text> <MoreOutlined />
                </a>
              </Dropdown>
            </>
          );
        },
        dataIndex: "isBookmarked edit key",
        key: "action",
        width: "8%",
        render: (bookmarked, edit, key) => {
          if (!this.state.processing)
            return (
              <div>
                <span
                  style={{ display: "inline" }}
                  onClick={this.onChangeBookMark.bind(this, edit)}
                  hidden={window.location.pathname === "/bookmarkQuestions"}
                >
                  <StarOutlined
                    style={{ padding: "3px" }}
                    hidden={edit.isBookmarked}
                  />
                  <StarFilled
                    style={{ padding: "3px", color: "#FADB14" }}
                    hidden={!edit.isBookmarked}
                  />
                </span>
                <EyeFilled
                  style={{ padding: "3px" }}
                  hidden={this.state.currentIndex !== edit.index}
                />{" "}
                <EyeOutlined
                  style={{ padding: "3px" }}
                  onClick={this.onView.bind(this, edit)}
                  hidden={this.state.currentIndex === edit.index}
                />
              </div>
            );
          else return <Skeleton.Button style={{width: '50px' }} active />;
        },
      },
      {
        title: () => (<><Typography.Text strong>All Questions</Typography.Text></>),
        dataIndex: "title",
        key: "title",
        render: (text) => {
          if (!this.state.processing)
            return (
              <a className="Anchor">
                <span
                  title={text}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "50ch",
                    display: "block",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography.Text>{text}</Typography.Text>
                </span>
              </a>
            );
          else return <Skeleton.Input style={{ width: "300px" }} active />;
        },
      },
      {
        title: () => (<><Typography.Text strong>Topics</Typography.Text></>),
        dataIndex: "topic_name",
        key: "topic_name",
        width: '15%',
        render: (text) => {
          if (!this.state.processing)
            return (
              <a className="AnchorBlue">
                <span >{text}</span>
              </a>
            );
          else return <Skeleton.Input style={{ width: "200px" }} active />;
        },
      },
      {
        title: () => (<Typography.Text strong>Type</Typography.Text>),
        dataIndex: "type",
        key: "type",
        width: '5%',
        render: (text) => {
          if (!this.state.processing)
            return (
              <Space size="middle">
                <OrderedListOutlined hidden={text !== "MCQ"} />
                <TeamOutlined
                  hidden={
                    typeof text !== "string"
                      ? true
                      : text.substring(0, 2) !== "SQ*"
                  }
                />
              </Space>
            );
          else return <Skeleton.Button active />;
        },
      },
      {
        title: () => (<><Typography.Text strong>Complexity</Typography.Text></>),
        dataIndex: "complexity",
        key: "complexity",
        width: '9%',
        render: (text) => {
          if (!this.state.processing)
            return (
              <a>
                <Avatar
                  hidden={text !== 1}
                  style={{
                    backgroundColor: "#55B2FF",
                    verticalAlign: "middle",
                  }}
                  size="small"
                >
                  {" "}
                  E
                </Avatar>
                <Avatar
                  hidden={text !== 2}
                  style={{
                    backgroundColor: "#52C41A",
                    verticalAlign: "middle",
                  }}
                  size="small"
                >
                  M
                </Avatar>
                <Avatar
                  hidden={text !== 3}
                  style={{
                    backgroundColor: "#F8515B",
                    verticalAlign: "middle",
                  }}
                  size="small"
                >
                  D
                </Avatar>
              </a>
            );
          else return <Skeleton.Button active />;
        },
      },
    ];
    this.handleLoad = this.handleLoad.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    // console.log("getDerivedStateFromProps"+props);
    return {
      classes: props.selectorState.classes,
      module_id: props.selectorState.category? props.selectorState.category : props.selectorState.subject,
      module: props.selectorState.category? 'category' : props.selectorState.subject? 'subject' : "",
      questionType: props.selectorState.questionType,
      complexity: props.selectorState.complexity,
    };
  }
  removeTags(str) {
    if (str === null || str === "") return "";
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  onView(rowData) {
    selectorService.getQuestion(rowData.key).then((data1) => {
      // console.log('Success:', data1);
      //data = $.parseJSON(data);
      let result = {};
      if (data1 !== undefined) result = data1.result;
      this.state.view = {};
      let c = {};
      c.index = rowData.index;
      c.key = result.id;
      c.isActive = result.isActive;
      c.isBookmarked = result.isBookmarked;
      c.type = result.type;
      if (result.options !== null) {
        c.option = [];
        let checkedValue = [];
        let singleValue;
        for (let index = 0; index < result.options.length; index++) {
          let opt = {};
          opt.label = result.options[index].text;
          opt.value = result.options[index].id;
          c.option.push(opt);
          if (result.options[index].isCorrect) {
            checkedValue.push(opt.value);
            if (c.type !== "MRQ") {
              c.singleValue = opt.value;
            }
          }
        }
        c.checkedValue = checkedValue;
      }
      c.options = result.options;
      c.source = result.source;
      c.status = result.status;

      c.subject_id = rowData.subject_id;
      c.subject_name = result.subject_name;
      c.tags = result.tags;
      c.title = result.title;
      c.status = result.status;
      c.title = result.title;
      c.topic_id = result.topic_id;
      c.topic_name = result.topic_name;
      c.answer_explanation = result.answer_explanation;
      c.type_name = result.type_name;
      c.author_id = result.author_id;
      c.author_name = result.author_name;
      c.complexity_code = result.complexity_code;
      c.edit = true;
      this.state.view = c;
      this.state.currentIndex = rowData.index;
      this.showDrawer();
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onChangeBookMark(rowData, context) {
    let questionArray = [];
    questionArray.push(rowData.key);
    let updatedValue = !rowData.isBookmarked;
    let data = [...this.state.data];
    data[rowData.index].isBookmarked = updatedValue;
    this.setState({ data: data }, () => {
      selectorService
        .updateBookMark(questionArray, updatedValue)
        .then((res) => {
          // console.log(res);
          if (res.status.code !== "1") {
            data = [...this.state.data];
            data[rowData.index].isBookmarked = !updatedValue;
            this.setState({ data: data });
          }
          // console.log("updated");
          // this.setState({processing:true})
          // this.handleLoad();
        });
    });

    // console.log(context+"  Bookmark Question =:");
  }

  updateBulkBookMark(value) {
    selectorService
      .updateBookMark(this.state.selectedRowKeys, value)
      .then((data) => {
        // console.log("updated");
        this.state.selectedRowKeys = [];
        this.setState({ processing: true });
        this.handleLoad(this.state.page);
      });
  }

  attachSubCategory() {}

  detachSubCategory() {}

  editQuestions() {}

  onPreviousClick() {
    let index = this.state.currentIndex;
    if (index == 0) return;
    let prevIndex = index - 1;
    let c = this.state.data[prevIndex];

    this.onView(c);
  }

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
      currentIndex: -1,
    });
  };

  onNextClick() {
    let index = this.state.currentIndex;
    let size = this.state.data.length;
    if (index >= size) return;
    let nextIndex = index + 1;
    let c = this.state.data[nextIndex];

    this.onView(c);
  }

  deleteQuestions(value) {
    selectorService
      .deleteQuestions(this.state.selectedRowKeys, value)
      .then((data) => {
        // console.log("updated");
        this.state.selectedRowKeys = [];
        this.setState({ processing: true });
        this.handleLoad();
      });
  }

  attachTag() {}

  detachTag() {}

  componentDidUpdate(prevProps) {
    if (!equal(this.props.selectorState, prevProps.selectorState)) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.setState({ processing: true });
      this.handleLoad();
    }
  }
  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
    this.setState({ processing: true });
    this.handleLoad();
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad(page = 1) {
    //event.preventDefault();
    let jsonObj = {};
    jsonObj.classes = this.state.classes;
    if (this.state.complexity !== "")
      jsonObj.complexity = this.state.complexity;
    if (this.state.module_id !== "") jsonObj.module_id = this.state.module_id;
    if (this.state.module !== "") jsonObj.module = this.state.module;
    if (this.state.questionType !== "") jsonObj.type = this.state.questionType;
    jsonObj.page = page;
    jsonObj.pageSize = this.state.pageSize;
    if (window.location.pathname === "/myQuestions") jsonObj.authorId = this.props.selectorState.currentUser.id;
    // console.log('jsonObj:', jsonObj);
    window.location.pathname !== "/bookmarkQuestions"
      ? selectorService.loadQuestions(jsonObj).then((data1) => {
          // console.log('Success:', data1);
          //data = $.parseJSON(data);
          let result = {};
          if (data1 !== undefined) result = data1.result;
          this.state.data = [];
          let data = [];
          let map = {};
          if (result.questions !== undefined) {
            for (let index = 0; index < result.questions.length; index++) {
              // console.log((result.questions[index]).title);
              let c = {};
              c.index = index;
              c.title = result.questions[index].title;
              c.key = result.questions[index].id;
              c.type = result.questions[index].type;
              c.type_name = result.questions[index].type_name;
              c.complexity = result.questions[index].complexity_code;
              c.author = result.questions[index].author;
              c.isActive = result.questions[index].isActive;
              c.isBookmarked = result.questions[index].isBookmarked;
              c.source = result.questions[index].source;
              c.status = result.questions[index].status;
              c.topic_id = result.questions[index].topic_id;
              c.topic_name = result.questions[index].topic_name;
              c.edit = false;
              data.push(c);
              map[c.key] = c;
              //this.state.data.push(c);
            }
          }
          this.setState({
            page,
            data: data,
            map: map,
            result: result,
            processing: false,
          });
        })
      : selectorService.loadQuestionsBookmark(jsonObj).then((data1) => {
          // console.log('Success:', data1);
          //data = $.parseJSON(data);
          let result = {};
          if (data1 !== undefined) result = data1.result;
          this.state.data = [];
          let data = [];
          let map = {};
          if (result.questions !== undefined) {
            for (let index = 0; index < result.questions.length; index++) {
              // console.log((result.questions[index]).title);
              let c = {};
              c.index = index;
              c.title = result.questions[index].title;
              c.key = result.questions[index].id;
              c.type = result.questions[index].type;
              c.type_name = result.questions[index].type_name;
              c.complexity = result.questions[index].complexity_code;
              c.author = result.questions[index].author;
              c.isActive = result.questions[index].isActive;
              c.isBookmarked = result.questions[index].isBookmarked;
              c.source = result.questions[index].source;
              c.status = result.questions[index].status;
              c.topic_id = result.questions[index].topic_id;
              c.topic_name = result.questions[index].topic_name;
              c.edit = false;
              data.push(c);
              map[c.key] = c;
              //this.state.data.push(c);
            }
          }
          this.setState({
            page,
            data: data,
            map: map,
            result: result,
            processing: false,
          });
        });
    //console.log(data);
  }

  onSelectChange = (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    // console.log(this.state.data); // logs all the question fetched 
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        {
          key: "odd",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: "even",
          text: "Select Even Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    let data = [...this.state.data];
    data = data.map((x) => {
      x = { ...x };
      x.title = this.removeTags(x.title);
      return x;
    });
    if (!this.state.processing) {
      return (
        <div>
          <Table
            // {...this.state}
            // rowKey="key"
            // loading={this.state.processing}
            showHeader={true}
            rowSelection={rowSelection}
            columns={this.columns}
            bordered
            pagination={false}
            dataSource={data}
          />
          <Pagination
            className="pagination-row"
            current={this.state.page}
            defaultPageSize={10}
            showSizeChanger={true}
            defaultCurrent={1}
            total={this.state.result.total_count}
            onChange={(page, pageSize) => {
              this.setState({ page, pageSize, processing: true }, () => {
                this.handleLoad(page);
              });
            }}
          />
          <Drawer
            title={"Question No : " + (this.state.view.index + 1)}
            width={640}
            placement="right"
            closable={false}
            onClose={this.handleCancel}
            visible={this.state.visible}
            footer={
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <Button
                  onClick={this.onPreviousClick}
                  type="primary"
                  disabled={this.state.currentIndex === 0}
                  style={{ marginRight: 8 }}
                >
                  Previous Question
                </Button>
                <Button onClick={this.onNextClick} type="primary" disabled={this.state.currentIndex === (this.state.data.length - 1)}>
                  Next Question
                </Button>
              </div>
            }
          >
            <Space direction="vertical">
              <h2>
                <span className="QuestionHeader">
                  {!this.state.view.title
                    ? this.state.view.title
                    : parse(this.state.view.title)}
                </span>
              </h2>

              {this.state.view.type === "MRQ" ? (
                <Checkbox.Group
                  options={this.state.view.option}
                  disabled
                  value={this.state.view.checkedValue}
                />
              ) : this.state.view.type === "MCQ" ||
                this.state.view.type === "TNF" ? (
                <Radio.Group
                  options={this.state.view.option}
                  disabled
                  value={this.state.view.singleValue}
                />
              ) : (
                <div></div>
              )}

              <span className="drawerHeader">Complexity</span>
              <Avatar
                className="complexity"
                shape="square"
                hidden={this.state.view.complexity_code !== 1}
                style={{ backgroundColor: "#55B2FF", verticalAlign: "middle" }}
                size="large"
              >
                {" "}
                Easy
              </Avatar>
              <Avatar
                className="complexity"
                shape="square"
                hidden={this.state.view.complexity_code !== 2}
                style={{ backgroundColor: "#52C41A", verticalAlign: "middle" }}
                size="large"
              >
                Medium
              </Avatar>
              <Avatar
                className="complexity"
                shape="square"
                hidden={this.state.view.complexity_code !== 3}
                style={{ backgroundColor: "#F8515B", verticalAlign: "middle" }}
                size="large"
              >
                Difficult
              </Avatar>
              <br />
              <span className="drawerHeader">Author</span>
              <Avatar
                className="author"
                shape="square"
                style={{ backgroundColor: "#55B2FF", verticalAlign: "middle" }}
                size="large"
              >
                {" "}
                {this.state.view.author_name}
              </Avatar>
              <br />
              <span className="drawerHeader">Explanation</span>
              <p>
                <span className="explanation">
                  {this.state.view.answer_explanation}
                </span>
              </p>
            </Space>
          </Drawer>
        </div>
      );
    } else {
      let dataSke = [];
      for (let i = 0; i < this.state.pageSize; i++) {
        let y = {};
        y.key = uniqueId();
        y.title = <Skeleton.Input style={{ width: "300px" }} />;
        y.topic_name = <Skeleton.Input style={{ width: "200px" }} />;
        // y.complexity = 4;
        y.type = <Skeleton.Input style={{ width: "100px" }} />;
        dataSke.push(y);
      }
      // console.log(dataSke);
      return (
        <>
          <Table
            columns={this.columns}
            dataSource={dataSke}
            rowSelection={rowSelection}
          />
        </>
      );
    }
  }
}

export default QuestionTableNew;
