import React from 'react';
import './SelectSubjects.css';
import { Button, Form, Space, message, DatePicker, InputNumber } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { authenticationService } from '../../_services';
import TextArea from 'antd/lib/input/TextArea';
import { selectorService } from '../../services/selectorService';
import moment from 'moment';


let subject = [

];
class ManageExam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      examName: props.selectorState.name,
      update: false,
      examId: props.selectorState.examId,
      instruction: "",
      start_date: "",
      end_date: "",
      attempts_allowed: 1,
      exam_duration: 0
    };
    this.onTextAreaInstruction = this.onTextAreaInstruction.bind(this);
    this.onOkExamAvailabityDate = this.onOkExamAvailabityDate.bind(this);
    this.onOkExamEndDate = this.onOkExamEndDate.bind(this);
    this.changeExamDuration = this.changeExamDuration.bind(this);
    this.changeAttemptAllowed = this.changeAttemptAllowed.bind(this);
    this.handleSubmitExam = this.handleSubmitExam.bind(this);
  }

  handleSubmitExam = () => {

    let json = {
      id: this.props.selectorState.id,
      instruction: this.state.instruction,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      attempts_allowed: this.state.attempts_allowed,
      exam_duration: (this.state.exam_duration*60),
    }
    message.loading({ content: 'Publishing Exam ...' });
    selectorService.publishExam(this.props.selectorState.id, json).then(data => {
      message.success({ content: 'Exam Published!', duration: 3 }); 
      this.props.parentCallback();   
    });
  }

  onTextAreaInstruction = (event) => {
    this.setState({
      instruction: event.target.value
    });

  }


  changeAttemptAllowed = (value) => {
    this.setState({
      attempts_allowed: value
    });
  }

  onOkExamAvailabityDate = (date, dateString) => {
    let stDate = date.format("YYYY-MM-DDTHH:mm:ss.sss");
    this.setState({
      start_date: stDate
    });
  }

  onOkExamEndDate = (date, dateString) => {
    let stDate = date.format("YYYY-MM-DDTHH:mm:ss.sss");
    this.setState({
      end_date: stDate
    });
  }

  changeExamDuration = (value) => {
    this.setState({
      exam_duration: value
    })
  }



  render() {
    return (
      <div className='ManageExam'>
        <div className="QuestionOtherContent">
          <br />
          <br />
          <Space direction="vertical" size={12} className="ManageExamSpace">
            <span>Instruction</span>
            <TextArea className='TextArea' autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Instruction" value={this.state.instruction} onChange={e => { this.onTextAreaInstruction(e) }} />
            <br />
            <span>Exam Availbility</span>

            <DatePicker  selected={this.state.start_date == "" ? moment() : moment(this.state.start_date)} showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DDTHH:mm:ss.sss" onChange={this.onOkExamAvailabityDate} />
            <span>Exam End Date</span>

            <DatePicker  selected={this.state.end_date == "" ? moment() : moment(this.state.end_date)} showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DDTHH:mm:ss.sss" onChange={this.onOkExamEndDate} />
            <span>Exam Duration</span>
            <InputNumber defaultValue={this.state.exam_duration} onChange={this.changeExamDuration} min={0} step={1} />
            <span>Attempts Allowed</span>
            <InputNumber defaultValue={this.state.attempts_allowed} onChange={this.changeAttemptAllowed} min={1} step={1} />

          </Space>
          <div>
           
          </div>
        </div>
      </div>
    )
  };
};

export default ManageExam;
