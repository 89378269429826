import 'antd/dist/antd.css';
import './UploadQuestion.css';
import React from 'react';
import csv from 'csvtojson';
import { EditOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload, Button, Input, Typography, Radio } from 'antd';
const { Dragger } = Upload;
const { Title } = Typography;

const UploadQuestion = () => {
    const [fileList, setFileList] = React.useState([]);
    const [uploading, setUploading] = React.useState(false);
    const [jsonObj, setJsonObj] = React.useState([]);

    const handleUpload = () => {
        // const formData = new FormData();
        // fileList.forEach((file) => {
        //     formData.append('files[]', file);
        // });
        setUploading(true); // You can use any AJAX library you like

        // fetch('https://', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then((res) => res.json())
        //     .then(() => {
        //         setFileList([]);
        //         message.success('Upload successfully.');
        //     })
        //     .catch(() => {
        //         message.error('Upload failed.');
        //     })
        //     .finally(() => {
        //         setUploading(false);
        //     });
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setJsonObj([]);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            const reader = new FileReader();

            reader.onload = async (e) => {
                await csv({ noheader: false })
                    .fromString(e.target.result)
                    .then(json => {
                        setJsonObj([...json]);
                    })
                    .catch(err => console.log(err));
            };

            reader.readAsText(file);
            return false;
        },
        //onchange: (err, event) => console.log('event'),
        fileList,
        maxCount: 1
    };

    return (
        <section className='upload-content'>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    {jsonObj.length < 1 && <InboxOutlined />}
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Testing purpose only</p>
            </Dragger>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{
                    marginTop: 16,
                }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
            <div className='questions-list'>
                {jsonObj.map((quesBlock, indx) => {
                    return (
                        // loop here to render multiple QuestionList component
                        <QuestionList key={`question${indx}`} jsonObj={jsonObj} setJsonObj={setJsonObj} indx={indx} />
                    );
                })}
            </div>
        </section>
    );
}

const QuestionList = ({ jsonObj, setJsonObj, indx }) => {

    const [disabled, setDisabled] = React.useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const regex = new RegExp(/^[0-9]+/);

        if (name[0] === 'q' && name[1] === 'u' && regex.test(name[name.length - 1])) {
            const id = name[name.length - 1];
            setJsonObj((prev) => {
                prev[id].question = value;
                return [...prev];
            });
        }

        if (name[0] === 'q' && regex.test(name[1]) && regex.test(name[name.length - 1])) {
            const id = name[1];
            const op = 'option' + name[name.length - 1];
            setJsonObj((prev) => {
                prev[id][op] = value;
                return [...prev];
            });
        }
    }

    return (
        <div>
            <form className="question-block">
                <Title level={4}>Edit Question Number {indx + 1}:</Title>
                <Input
                    className="question" type="text" name={'question' + indx} value={jsonObj[indx].question} onChange={handleChange} disabled={disabled}
                />

                <Title level={5}>Edit Options:</Title>
                <Input
                    className="option" type="text" name={'q' + indx + 'option1'} value={jsonObj[indx].option1} onChange={handleChange} disabled={disabled}
                />
                <Input
                    className="option" type="text" name={'q' + indx + 'option2'} value={jsonObj[indx].option2} onChange={handleChange} disabled={disabled}
                />
                <Input
                    className="option" type="text" name={'q' + indx + 'option3'} value={jsonObj[indx].option3} onChange={handleChange} disabled={disabled}
                />
                <Input
                    className="option" type="text" name={'q' + indx + 'option4'} value={jsonObj[indx].option4} onChange={handleChange} disabled={disabled}
                />

                <Title level={5}>Set Complexity:</Title>
                <Radio.Group className="complexity-radio-btn" buttonStyle="solid" value={jsonObj[indx]?.complexity} onChange={e => setJsonObj(prev => {
                    prev[indx].complexity = e.target.value;
                    return [...prev]
                })}>
                    <Radio.Button value="1">Easy</Radio.Button>
                    <Radio.Button value="2">Medium</Radio.Button>
                    <Radio.Button value="3">Difficult</Radio.Button>
                </Radio.Group>

                <Button type='primary' ghost icon={<EditOutlined />} onClick={(e) => { e.preventDefault(); setDisabled(!disabled); }}>{disabled ? "Edit" : "Save"}</Button>
            </form>
        </div>
    )
}

export default UploadQuestion;
