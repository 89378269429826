import React from 'react';
import './Classes.css';
import { Layout, Card, Avatar, Col, Row, Breadcrumb, Cascader } from 'antd';
import 'antd/dist/antd.css';
import { authenticationService } from '../../_services';
import { Siderc } from '../Main/Siderc';
import { Headerc } from '../Main/Headerc';
import { HomeOutlined } from '@ant-design/icons';

const { Meta } = Card;

let userGroup = [];

class Classes extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
           classId: 1,
           students: null,
           selectedUserGroup: "",
           classes: null,
        }
        this.onChangeUserGroupData = this.onChangeUserGroupData.bind(this);
        this.handleLoad = this.handleLoad.bind(this); 
     }

     renderCardData() {
        if (!this.state.students) { return null; }
        return this.state.students.map((student, index) => {
           const { id, name, regid, email } = student //destructuring
           return (
               // Pending: Making it responsive
            <Col key={id}xs={6} sm={6} md={6} lg={6}> 
                <Card className="usercard"
                hoverable
                bordered={false}
                cover={<Avatar size={64} src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" style={{ display: 'inline-block', verticalAlign: 'middle' }} />}>
                    <Meta 
                    title={name} 
                    description={email} />
                </Card>
            </Col>
           )
        })
     }

     async componentDidMount() {
        window.addEventListener('load', this.handleLoad.bind(this));
        this.handleLoad();
     }

     componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
      }

     handleLoad = () => {
        const currentUser = authenticationService.currentUserValue;
        const currentClass = authenticationService.currentClassValue;
        const requestOptions = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '.concat(currentUser.jwtToken)
          }
        };
        fetch(`https://api-institute.odiprojects.com/v1/UserGroups/Classes/${currentClass}`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then(data => {
            const result = data.result;
            userGroup = [];
            for (let index = 0; index < result.length; index++) {
            let c = {};
            c.label = (result[index]).name;
            c.value = (result[index]).id;
            userGroup.push(c);
            }
            this.setState({classes:userGroup })
        }).catch(error => {
          console.log(error);
        });
     }

     onChangeUserGroupData = (event) => {
        if (event===undefined || event.length === 0) {
            console.log("Not expected event");
        } else {
          console.log("Child sending selector Call: " + event[0]);
          this.fetchUsersUserGroup(event[0])
        }
      }

      fetchUsersUserGroup = (userGroupId) => {
        const currentUser = authenticationService.currentUserValue;
        const requestOptions = {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '.concat(currentUser.jwtToken)
          }
        };
        fetch(`https://api-institute.odiprojects.com/v1/UserGroups/${userGroupId}/Users`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then(data => {
            const result = data.result;
            console.log(result);
            this.state.students = [];
            let userdata =[];
            for (let index = 0; index < result.length; index++) {
            console.log((result[index]).first_name);
            let c = {};
            c.name = (result[index]).first_name;
            c.email = (result[index]).email_id;
            c.id = (result[index]).id;
            c.regid = (result[index]).registration_id;
            userdata.push(c);
            }
            this.setState({
                students: userdata
              });
            console.log(this.state.students)
            this.renderCardData();
        }).catch(error => {
          console.log(error);
        });

      }

    render() {

        return(
            <Layout>
                <Siderc />
                <Layout style={{}}>
                    <Headerc />
                    <Layout>
                        <div className="belowheader">
                        <Breadcrumb style={{float: 'left', padding: '8px'}}>
                            <Breadcrumb.Item href="/">
                            <HomeOutlined />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="/classes">
                            <span>Classes</span>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Cascader 
                        size="small" 
                        ref="userGroup" 
                        key="userGroup" 
                        id="userGroup" 
                        options={this.state.classes} 
                        placeholder="Classes" 
                        onChange={this.onChangeUserGroupData.bind(this)} 
                        style={{float: 'right', padding: '8px', background: '#FAFAFA' }}/>
                        </div>
                        <div className="site-card-wrapper">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            {this.renderCardData()}

                            </Row>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        );
    }

}

export default Classes;
