import React from 'react';
import { useMediaQuery } from 'react-responsive'

const Sidebar = ({ children, collapse, className }) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    const collapsedStyle = {
        overflowX: 'hidden',
        flex: '0 1 220px',
        transform: 'scaleX(0)',
        transformOrigin: '0 0',
        transition: 'all 0.2s ease-out',
        position: !isDesktopOrLaptop ? 'absolute' : 'static',
        zIndex: 50,
        backgroundColor: 'snowwhite',
        height: '100%'
    };
    const openStyle = {
        overflowX: 'hidden',
        flex: '0 0 220px',
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
        transition: 'all 0.2s ease-out',
        position: !isDesktopOrLaptop ? 'absolute' : 'static',
        width: !isDesktopOrLaptop? '200px' : 'unset',
        boxShadow: !isDesktopOrLaptop? '0px 8px 10px rgb(0,0,0,0.3)' : 'unset',
        zIndex: 50,
        backgroundColor: 'snowwhite',
        height: '100%'
    };

    return (
        <div className={className} style={collapse && !isDesktopOrLaptop ? collapsedStyle : openStyle}>
                {children}
        </div>
    );
}

export default Sidebar;
