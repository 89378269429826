import React from "react";
import "./RichTextEditor.css";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;

/*
 * Simple editor component that takes placeholder text as a prop
 */
class RichTextEditor extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      editorHtml:
        this.props.editorHtml !== undefined ? this.props.editorHtml : "",
      theme: "snow",
      reset: "false",
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendData = this.sendData.bind(this);
  }

  sendData = () => {
    this.props.parentCallback(this.state);
  };
  handleChange = (content, delta, editor) => {
    this.setState({ editorHtml: editor.getHTML() });
    this.sendData();
  };

  render() {
    return (
      <div>
        <ReactQuill
          theme={this.state.theme}
          onBlur={this.handleChange}
          onChange={(newValue) => this.setState({ editorHtml: newValue })}
          //value={this.state.id}
          value={this.state.editorHtml ? this.state.editorHtml : ""}
          modules={RichTextEditor.modules}
          formats={RichTextEditor.sformats}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video", "formula"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "formula",
];

/*
 * PropType validation
 */
RichTextEditor.propTypes = {
  placeholder: PropTypes.string,
};

export default RichTextEditor;
