import React from "react";
import "./AttemptExam.css";
import { authenticationService } from '../../../../_services';
import { selectorService } from "../../../../services/selectorService";
import { Layout, Avatar, Button, Radio,Switch, Tabs, Card, Collapse, Statistic, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { ButtonGroup } from "react-bootstrap";


class QuestionDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      exam_id: 'props.location.state.examId',
      question: this.props.question
    };
    this.loadNextQuestion = this.loadNextQuestion.bind(this);
    this.onReviewQuestion = this.onReviewQuestion.bind(this);
    this.onClearResponse = this.onClearResponse.bind(this);
  }

  sendData = (question) => {
    this.props.parentCallback(question);
  }

  loadNextQuestion = () => {
    this.props.parentCallbackNext(this.props.question);
  }

  onReviewQuestion = (val) => {
    this.state.question.isReviewMarked = val;
    this.sendData(this.state.question);
  }

  onAnswerChange = (event) =>{
    let questionType = this.state.question.type_code;
    let id = event.target.value;
    let tempQuestion = this.state.question;
    if(questionType ==='MCQ' || questionType ==='TNF'){
      let options = this.state.question.options.map((option, index) => {
        if(option.id === id)
          option.isSelected = event.target.checked;
        else
        option.isSelected = false;
        return option;
      })
      tempQuestion.options = options;
  }
   
    this.setState({
      question : tempQuestion
    })
    this.sendData(this.state.question);
  }

  onClearResponse = () => {
    let options = this.state.question.options.map((option, index) => {
      option.isSelected = false;
      return option;
    })
    let tempQuestion = this.state.question;
    tempQuestion.options = options;
    this.setState({
      question : tempQuestion
    })
    this.sendData(this.state.question);
  }




  render() {
    this.state.question = this.props.question;
    let answer = "";
    let answerOption = this.state.question.options.map((option,i)=>{
      if(option.isSelected) 
          answer = option.id;
      return <Radio className='radioStyle' key={option.id} value={option.id} checked={option.isSelected}>
      {option.text}
      </Radio>

    })
    let questionDetails= <Layout className="MainExamQuestionLayout">
      <Row>
        <Col>
    <h3>{this.props.question.index}. {parse(this.props.question.question_text)}</h3> 
    </Col>
    </Row>
    <Row className="Options">
    <Col>
    <div> <Radio.Group onChange={this.onAnswerChange} value={answer}>
     
            {
                answerOption
            }
            </Radio.Group>
        </div> 
        </Col>
        </Row>
        <Row>
          <Col>
            <br/>
            <Switch checkedChildren="Review On" unCheckedChildren="Review Off" checked={this.state.question.isReviewMarked} onChange={this.onReviewQuestion} />
            <br/>
          </Col>
        </Row>
        <Row>
          <Col>
            <br/>
            <br/>
          </Col>
        </Row>
        <ButtonGroup><Button
              onClick={this.loadNextQuestion}
              type="primary"
              disabled={this.props.question.index>=this.props.totalQuestion}
              style={{ marginRight: 8 }}
            >
              {" "}
              Next Question{" "}
            </Button>

            <Button
              onClick={this.onClearResponse}
              style={{ marginRight: 8 }}
            >
              {" "}
              Clear Response{" "}
            </Button></ButtonGroup>
    </Layout>
    
    return (
      <>
{questionDetails}
             
      </>
    )
  };
};

export default QuestionDetail;
