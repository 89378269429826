import React, { useState, useEffect } from 'react';
import { Layout, Button, Select, Spin, Space, Breadcrumb, Typography } from 'antd';
import { authenticationService } from '../../_services';
import { selectorService } from '../../services/selectorService';
import { history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';

// nav imports
import questionNav from './questionNav.json'

// local svg imports
import { ReactComponent as LocaleSVG } from '../assets/image/locale-icon.svg';
import { ReactComponent as ClassSVG } from '../assets/image/class-icon.svg';

import './Headerc.css';

const { Header } = Layout;

// const route =[
//     {
//         path: '/',
//         breadcrumbName: 'Dashboard'
//     },
//     {
//         path: '/',
//         breadcrumbName: "Question",
        // children: [
        //     {
        //         path: 'allQuestions',
        //         breadcrumbName: "All Questions"
        //     },
        //     {
        //         path: 'myQuestions',
        //         breadcrumbName: "My Questions",
        //     },
        //     {
        //         path: '/createQuestion',
        //         breadcrumbName: "Create Question"
        //     },
        //     {
        //         path: '/bookmarkQuestions',
        //         breadcrumbName: "Bookmark Questions"
        //     },
        //     {
        //         path: '/darftQuestions',
        //         breadcrumbName: "Draft Questions"
        //     }
        // ]
//     },
//     {
//         path: 'exam',
//         breadcrumbName: "Quiz/Exam",
//         children: [
//             {
//                 path: '/createExam',
//                 breadcrumbName: "Create Exam"
//             },
//         ]
//     }
// ]

const Headerc = (props) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const [page, setPage] = useState([]);

    const [state, setState] = useState({
        user: authenticationService.currentUserValue,
        refresh: false, // lets see if this is needed or not.
        language_code: "",
        language_name: "",
        classID: "",
    });

    const [languages, setlanguages] = React.useState([]);
    const [classes, setClasses] = React.useState([]);

    // console.log(props.location)

    useEffect(()=> {
        switch(props.location.pathname) {
            case "/allQuestions":
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Questions",
                        children: questionNav

                    },
                    {
                        path: 'allQuestions',
                        breadcrumbName: "All Questions"
                    }
                ]);
                break;
            case "/myQuestions":
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Questions",
                        children: questionNav
                    },
                    {
                        path: 'myQuestions',
                        breadcrumbName: "My Questions"
                    }
                ]);
                break;
            case "/createQuestion":
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Questions",
                        children: questionNav
                    },
                    {
                        path: 'addQuestions',
                        breadcrumbName: "Create Question"
                    }
                ]);
                break;
            case "/bookmarkQuestions":
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Questions",
                        children: questionNav
                    },
                    {
                        path: 'bookmarkQuestions',
                        breadcrumbName: "Bookmark Questions"
                    }
                ]);
                break;
            case "/draftQuestions":
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Questions",
                        children: questionNav
                    },
                    {
                        path: 'draftQuestions',
                        breadcrumbName: "Draft Questions"
                    }
                ]);
                break;
            default:
                setPage([
                    {
                        path: '/',
                        breadcrumbName: "Dashboard"
                    }
                ]);
        }
        return () => {}
    }, [props.location.pathname]);

    function itemRender(route, params, pages, paths) {
        const last = pages.indexOf(route) === pages.length - 1;
        return last ? (
          <>{ route.breadcrumbName }</>
        ) : (
            <Link to={route.path}><>{ route.breadcrumbName }</></Link>
        );
    }

    useEffect(() => {
        //window.addEventListener('load', this.handleLoad);
        if (!authenticationService.currentUserValue.language_code) {
            if (!JSON.parse(sessionStorage.getItem('currentUser')).language_code) {
                const storedUser = JSON.parse(sessionStorage.getItem('currentUser'));
                storedUser.language_code = i18n.language;
                authenticationService.currentUserValue.language_code = i18n.language;
                setState(prev => ({ ...prev, language_code: i18n.language }));
                sessionStorage.setItem('currentUser', JSON.stringify(storedUser));
            } else {
                authenticationService.currentUserValue.language_code = JSON.parse(sessionStorage.getItem('currentUser')).language_code;
                setState(prev => ({ ...prev, language_code: JSON.parse(sessionStorage.getItem('currentUser')).language_code }));
            }
        } else {
            i18n.changeLanguage(authenticationService.currentUserValue.language_code);
            setState(prev => ({ ...prev, language_code: authenticationService.currentUserValue.language_code }));
        }
        handleLoad();

        return () => {
            //cleanup
        };
    }, [state.user, state.language_code]);

    function handleLoad() {
        selectorService.loadLanguages().then(data => {
            if (!data) {
                authenticationService.refreshFireToken().then(() => {
                    setState({
                        user: authenticationService.currentUserValue,
                        refresh: true
                    });
                });
            }
            if (data) {
                const result = data.result;
                setlanguages(result)
            }
        });

        if (authenticationService.currentUserValue?.role_id !== 4) {
            selectorService.loadClasses().then(data => {
                if (data) {

                    const result = data.result;
                    setClasses(result);

                    const currentClass = authenticationService.currentClassValue;
                    if (currentClass) {
                        setState((prev) => ({ ...prev, classID: currentClass }));
                        props.isClassLoading && props.isClassLoading(false);
                    }
                    if (currentClass === null) {
                        authenticationService.updateClass(result[0].name, result[0].id);
                        setState((prev) => ({ ...prev, classID: result[0].id }));
                        props.isClassLoading && props.isClassLoading(false);
                    }
                }
            });
        }
    }

    function onClickLanguageChange(_, objectSelected) {
        authenticationService.currentUserValue.language_code = objectSelected.value;
        authenticationService.currentUserValue.language_name = objectSelected.children;
        setState((prev) => ({
            ...prev,
            language_code: objectSelected.value,
            language_name: objectSelected.children
        }));
        sessionStorage.setItem('currentUser', JSON.stringify(authenticationService.currentUserValue));
        i18n.changeLanguage(objectSelected.value);
        // console.log(props.location)
        history.push(props.location.pathname);
        // window.location.reload();
        // this.forceUpdate();
    }

    function onClickClassChange(_, objectSelected) {
        console.log(objectSelected);
        authenticationService.updateClass(objectSelected.children, objectSelected.value);
        window.location.reload();
        //this.forceUpdate();
    };

    // function findClass() {
    //     const id = state.classID;
    //     const classSelected = classes.find(item => `${item.id}` === id);
    //     return (classSelected ? classSelected.name : "");
    // }

    function logout() {
        // let history = useHistory();
        authenticationService.logout();
        history.push('/login');
    }

    return (
        <Header className="app-header">
            <div className='left'>
                <Breadcrumb key={uniqueId()} separator={<CaretRightOutlined />} itemRender={itemRender} routes={page} />
            </div>
            <div className='right'>
                <Select size='normal' placeholder={<> <LocaleSVG /> Language Loading... </>} optionLabelProp="label"
                    dropdownRender={(menu) => {
                        return (
                            <>
                                {languages.length > 0 ? <>{menu}</> :
                                    <>
                                        <Space style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                            <Spin />
                                            Loading languages, please wait...
                                        </Space>
                                    </>
                                }
                            </>
                        );
                    }}
                    onChange={onClickLanguageChange}
                    value={languages.length > 0 ? state.language_code : <> <LocaleSVG /> Loading... </>}
                    style={{ minWidth: '150px' }}
                >
                    {languages.map((languageItem, index) => {
                        return (
                            <Select.Option key={languageItem.code}
                                label={
                                    <>
                                        <LocaleSVG />
                                        &nbsp;
                                        {languageItem.name}
                                    </>
                                }>
                                {languageItem.name}
                            </Select.Option>
                        );
                    })}
                </Select>

                {authenticationService.currentUserValue?.role_id !== 4 ?
                    <Select size='normal' placeholder={<> <ClassSVG /> Loading Classes... </>} optionLabelProp="label"
                        dropdownRender={(menu) => {
                            return (
                                <>
                                    {classes.length > 0 ? <>{menu}</> :
                                        <>
                                            <Space style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                                <Spin />
                                                Loading classes, please wait...
                                            </Space>
                                        </>
                                    }
                                </>
                            );
                        }}
                        onChange={onClickClassChange}
                        value={classes.length > 0 ? state.classID : <> <ClassSVG /> Loading Classes... </>}
                        style={{ minWidth: '150px' }}
                    >
                        {classes.map((classItem, index) => {
                            return (
                                <Select.Option key={classItem.id}
                                    label={
                                        <>
                                            <ClassSVG />
                                            &nbsp;
                                            {classItem.name}
                                        </>
                                    }>
                                    {classItem.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                    : ""
                }

                <div>
                    <Button type='primary' size='normal' onClick={logout} danger>{t('logout')}</Button>
                </div>
            </div>

        </Header>
    );
}

export default Headerc;
