import React from 'react';
import { selectorService } from '../../../services/selectorService';
import 'antd/dist/antd.css';
import '../QuestionTable.css';
import { Link } from 'react-router-dom';
import { Table, Space, Avatar, Menu, Dropdown, Drawer, Button, Checkbox, Radio,Pagination,Skeleton,Empty} from 'antd';
import equal from 'fast-deep-equal';
import {EditOutlined} from '@ant-design/icons';
import parse from "html-react-parser";
import { isNull } from 'lodash';



let subject = [

];

let category = [

];

let subcategory = [

];

let type = [


];

let complexity = [


];

const data = [];

const bottomOptions = [
  { label: 'bottomLeft', value: 'bottomLeft' },
  { label: 'bottomCenter', value: 'bottomCenter' },
  { label: 'bottomRight', value: 'bottomRight' },
  { label: 'none', value: 'none' },
];




 
class QuestionTable extends React.Component {  
  constructor(props){
  super(props);
console.log(props)
    this.state = {
    bottom: 'bottomRight',
    classes: props.selectorState.classes ,
    module_id: props.selectorState.module_id,
    module:props.selectorState.module,
    questionType:props.selectorState.questionType,
    complexity:props.selectorState.complexity,
    //option:props.selectorState.options,
    selectedRowKeys: [],
    data:[],
    map:{},
    visible: false,
    view:{},
    currentIndex:-1,
    que_id:'',
    options:[],
    result:{},
    page:1,
    processing:false,
    pageSize:10,
  }

  this.columns = [

    // {
    //   dataIndex: 'isBookmarked edit key' ,
    //   key: 'action',
    //   width:'8%',
    //   render: (bookmarked, edit, key) => (
    //   <div>
    //   <span style={{display:'inline'}} onClick={this.onChangeBookMark.bind(this,edit)}><StarOutlined style={{padding:'3px'}} hidden={edit.isBookmarked}/><StarFilled style={{padding:'3px', color:'#FADB14'}} hidden={!edit.isBookmarked}/></span>
    //   <EyeFilled style={{padding:'3px'}} hidden={this.state.currentIndex !== edit.index}/>  <EyeOutlined style={{padding:'3px'}}  onClick={this.onView.bind(this,edit)} hidden={this.state.currentIndex === edit.index}/>
    //   </div>
    //   ),
    // },
    { 
      title:'Draft Questions',
      dataIndex: 'title',
      key: 'title',
      render: text => <a className='Anchor'><span title={text} style={{ overflow:'hidden',whiteSpace:'nowrap' ,maxWidth:'50ch',display:'block',fontWeight: 'normal',padding:'10px',textOverflow:'ellipsis' }}>{text}</span></a>
    },
    // { 
    //   title:'Topic',
    //   dataIndex: 'topic_name',
    //   key: 'topic_name',
    //   render: text => <a className='AnchorBlue'><span style={{ fontWeight: 'normal' }}>{text}</span></a>
    // },
    // { 
    //   title:'Sub Topic',
    //   dataIndex: 'subtopic_name',
    //   key: 'subtopic_name',
    //   render: text => <a className='AnchorBlue'><span style={{ fontWeight: 'normal' }}>{text}</span></a>
    // },
  
    {
      title:'Complexity',
      dataIndex: 'complexity',
      key: 'complexity',
      render: complexity =>{
      if(complexity!==4)
        return( <a>
        {complexity===1?
        <Avatar 
      // hidden={text!==1} 
      style={{ backgroundColor: '#55B2FF', verticalAlign: 'middle' }} size="small" complexity={complexity}> E
      
      </Avatar>:""}
      {complexity===2?
      <Avatar 
      // hidden={text!==2} 
      complexity={complexity} style={{ backgroundColor: '#52C41A', verticalAlign: 'middle' }} size="small" >M
      
      </Avatar>:""}
      {complexity===3?
      <Avatar complexity={complexity} 
      // hidden={text!==3}
      style={{ backgroundColor: '#F8515B', verticalAlign: 'middle' }} size="small" >D
      
      </Avatar>:""}
      </a>)
      else return(<Skeleton.Button active />)
    }
    },

    { 
      title:'',
      dataIndex: 'key',
      key: 'Edit',
      render: (key,complexity,options) => {
      if(this.state.processing)
        return(<Skeleton.Button active />)
      else
        return(
      
      <Link to={{ 
        className:'AnchorBlue',
        pathname: "/editQuestion", 
        state: key,complexity,options
       }}> <span>
      <EditOutlined style={{padding:'3px'}} onClick={()=>this.handleEdit(key,complexity,options)}/>Edit</span>
      </Link>)}
   },
 
    { 
      title:'',
      dataIndex: 'Submit',
      key: 'Submit',
      render: text => {
        if(text!==4){return(<a className='AnchorBlue'><span onClick={()=>{}} style={{ fontWeight: 'normal' }}>Submit</span></a>) }
      else return(<Skeleton.Button active />)
    }
    },
  
  ];
  this.handleLoad = this.handleLoad.bind(this);
  this.onPreviousClick = this.onPreviousClick.bind(this);
  this.onNextClick = this.onNextClick.bind(this);
  this.handleEdit = this.handleEdit.bind(this);
};
static getDerivedStateFromProps(props, state) {
  // console.log("getDerivedStateFromProps"+props);
  return {classes: props.selectorState.classes ,
    module_id: props.selectorState.module_id,
    module:props.selectorState.module,
    questionType:props.selectorState.questionType,
    complexity:props.selectorState.complexity };
}


onView(rowData){
  selectorService.getQuestion(rowData.key).then(data1 => {
     console.log('Success:', data1);
    //data = $.parseJSON(data);
    let result = {};
    if(data1 !== undefined)
        result = data1.result;
        this.state.view={};
      let c = {};
      c.index = rowData.index;
      c.key = result.id;
      c.isActive = result.isActive;
      c.isBookmarked =  result.isBookmarked;
      c.type =  result.type;
      if(result.options !== null){
        c.option =[];
        let checkedValue = [];
        let singleValue;
        for (let index = 0; index < result.options.length; index++) {
          let opt = {};
          opt.label = result.options[index].text;
          opt.value = result.options[index].id;
          c.option.push(opt);
        
          if(result.options[index].isCorrect){
            checkedValue.push(opt.value);
            if(c.type!=='MRQ'){
              c.singleValue = opt.value;
            }
          }
          
        }
        c.checkedValue = checkedValue;
        
      }
      c.options = result.options;
      c.source = result.source;
      c.status  = result.status;

      c.subject_id = rowData.subject_id;
      c.subject_name = result.subject_name;
      c.subtopic_id = result.subtopic_id;
      c.subtopic_name =  result.subtopic_name;
      c.tags = result.tags;
      c.title = result.title;
      c.status  = result.status;
      c.title = result.title;
      c.topic_id =  result.topic_id;
      c.topic_name = result.topic_name;
      c.answer_explanation = result.answer_explanation;
      c.type_name = result.type_name;
      c.author_id =  result.author_id
      c.author_name =  result.author_name;
      c.complexity_code = result.complexity_code;
      c.edit = true;
      this.state.view = c;
      this.state.currentIndex = rowData.index;
      this.showDrawer();
  });
};
handleEdit = (id) =>{
this.setState({
  que_id:id
})

}
showDrawer = () => {
  this.setState({
    visible: true,
  });
};


  onChangeBookMark(rowData,context){
  let questionArray = [];
  questionArray.push(rowData.key);
  let updatedValue = !rowData.isBookmarked;
  selectorService.updateBookMark(questionArray,updatedValue).then(data => {
    rowData.isBookmarked = updatedValue;
    // console.log("updated");
    this.setState({processing:true});
    this.handleLoad();
  });
  // console.log(context+"  Bookmark Question =:");
};

  updateBulkBookMark(value){
    selectorService.updateBookMark(this.state.selectedRowKeys,value).then(data => {
      // console.log("updated");
    this.state.selectedRowKeys = [];
    this.setState({processing:true});
      this.handleLoad();
    });
    
  }

  attachSubCategory(){
    
  }

  detachSubCategory(){

  }

  editQuestions(){

  }

  onPreviousClick(){
    let index = this.state.currentIndex;
    if(index == 0)
      return;
    let prevIndex = index - 1;
    let c = this.state.data[prevIndex];

    this.onView(c);
   }

 
  handleCancel = e => {
    // console.log(e);
    this.setState({
      visible: false,
      currentIndex: -1
    });
  };

   onNextClick(){
    let index = this.state.currentIndex;
    let size = this.state.data.length;
    if(index >= this.state.data)
      return;
    let nextIndex = index + 1;
    let c = this.state.data[nextIndex];

    this.onView(c);
   }

  deleteQuestions(value){
    selectorService.deleteQuestions(this.state.selectedRowKeys,value).then(data => {
      // console.log("updated");
      this.state.selectedRowKeys = [];
      this.setState({processing:true});
      this.handleLoad();
    });
  }

  attachTag(){

  }

  detachTag(){

  }

componentDidUpdate(prevProps) {
  if(!equal(this.props.selectorState, prevProps.selectorState)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
  {
    console.log('here');
    this.setState({processing:true});
    this.handleLoad();
  }
}
componentDidMount() {
    window.addEventListener('load', this.handleLoad);
    this.setState({processing:true});
    this.handleLoad();
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }

  handleLoad() {
    //event.preventDefault();
    let jsonObj={};
    jsonObj.classes = this.state.classes;
    if(this.state.complexity !== "")
        jsonObj.complexity = this.state.complexity;
    if(this.state.module_id !== "")
        jsonObj.module_id = this.state.module_id;
    if(this.state.module !== "")
        jsonObj.module = this.state.module;
    if(this.state.questionType !== "")
        jsonObj.type = this.state.questionType;
    // console.log('jsonObj:', jsonObj);
    jsonObj.page=this.state.page;
    jsonObj.pageSize=this.state.pageSize;
    selectorService.draftQuestion(jsonObj).then(data1 => {
      console.log('Success:', data1);
       console.log('Success:', data1.result.questions.id);
       
      //  this.setState({
      //   id:data1.result.questions.id
      // })
      //data = $.parseJSON(data);
      let result = {};
      if(data1 !== undefined)
          result = data1.result;
      this.state.data = [];
      let data =[];
      let map ={};
      if(result.questions !== undefined){

      for (let index = 0; index < result.questions.length; index++) {
        let c = {};
        c.index = index;
        c.title = (result.questions[index]).title;
        c.key = (result.questions[index]).id;
        c.type =  (result.questions[index]).type;
        c.type_name = (result.questions[index]).type_name;
        c.complexity = (result.questions[index]).complexity_code;
        c.author =  (result.questions[index]).author;
        c.isActive = (result.questions[index]).isActive;
        c.isBookmarked =  (result.questions[index]).isBookmarked;
        c.source = (result.questions[index]).source;
        c.status = (result.questions[index]).status;
        c.topic_id =  (result.questions[index]).topic_id;
        c.topic_name = (result.questions[index]).topic_name;
        c.subtopic_id =  (result.questions[index]).subtopic_id;
        c.subtopic_name = (result.questions[index]).subtopic_name;
        c.options=(result.questions[index]).options
        c.edit = false;
        data.push(c);
        map[c.key] = c;
        //this.state.data.push(c);
      }}
      this.setState({
        data: data,
        map: map,
        result:result,
        processing:false
      });
    });
    //console.log(data);

  }

  
  onSelectChange = selectedRowKeys => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  removeTags(str) {
    if (str === null || str === "") return "";
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  

  render() {
 const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
  
  let data = [...this.state.data];
  data = data.map((x) => {
    x = { ...x };
    x.title = this.removeTags(x.title);
    return x;
  });
  console.log(data);
  if(data.length!==0)
    return (
      <div>
        
      <Table {...this.state}      
      rowKey='key' rowSelection={rowSelection} 
      columns={this.columns}
      bordered
      pagination={false}
      dataSource={data} 
      
    />
    <Pagination defaultPageSize={10} style={{float:'right'}}        showSizeChanger={true}
     defaultCurrent={1}  total={this.state.result.total_count} onChange={(page,pageSize)=>{
        this.setState({page,pageSize},()=>{this.handleLoad()})
        }} />
          <Drawer
          title = {"Question No : "+(this.state.view.index+1)}                  
          width={640}
          placement="right"
          closable={false}
          onClose={this.handleCancel}
          visible={this.state.visible}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button onClick={this.onPreviousClick} type="primary" style={{ marginRight: 8 }}>
                Previous Question
              </Button>
              <Button onClick={this.onNextClick} type="primary">
                Next Question
              </Button>
            </div>
          }
        >
          <Space direction="vertical">
              <h2><span className='QuestionHeader'>{!this.state.view.title
                  ? this.state.view.title
                  : parse(this.state.view.title)}</span></h2>

              {this.state.view.type==='MRQ'?
              <Checkbox.Group 
                  options={this.state.view.option}
                  disabled
                  value={this.state.view.checkedValue}
                />:this.state.view.type==='MCQ'||this.state.view.type==='TNF'?
              <Radio.Group 
                  options={this.state.view.option}
                  disabled
                  value={this.state.view.singleValue}
                />:<div></div>}

      <span className="drawerHeader">Complexity</span>
      {/* {this.state.view.complexity_code!==1} */}
      <Avatar className="complexity" shape='square'  hidden={this.state.view.complexity_code!==1} style={{ backgroundColor: '#55B2FF', verticalAlign: 'middle' }} size="large" > Easy
      
      </Avatar>
      <Avatar className="complexity" shape='square'  hidden={this.state.view.complexity_code!==2} style={{ backgroundColor: '#52C41A', verticalAlign: 'middle' }} size="large" >Medium
      
      </Avatar>
      <Avatar className="complexity" shape='square'  hidden={this.state.view.complexity_code!==3}style={{ backgroundColor: '#F8515B', verticalAlign: 'middle' }} size="large" >Difficult
      
      </Avatar>
      <br/>
      <span className="drawerHeader">Author</span>
      <Avatar className="author" shape='square'  style={{ backgroundColor: '#55B2FF', verticalAlign: 'middle' }} size="large" > {this.state.view.author_name}
      </Avatar>
      <br/>
      <span className="drawerHeader">Explanation</span>
      <p><span className="explanation">{this.state.view.answer_explanation}</span></p>
       </Space>
        </Drawer>
      </div>
    )
    else if(this.state.processing) {
      let dataSke=[];
      for(let i =0;i<this.state.pageSize;i++)
      {
        let y={};
        y.title=<Skeleton.Input style={{width:'300px'}}/>;
        y.topic_name=<Skeleton.Input style={{width:'200px'}}/>;
        y.complexity=4;
        y.Edit=[];
        y.Submit=4;
        dataSke.push(y);
      }
      return(
      <>
     <Table
      columns={this.columns}
      dataSource={dataSke}
      rowSelection={rowSelection}
     />
    
      </>
      )}
    else return(<Empty />);
  };
};

export default QuestionTable;
