import React from 'react';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { Siderc } from '../Main/Siderc';
import Headerc from '../Main/FuncHeader';
import { authenticationService } from '../../_services';
// import AllQuestions from '../Questions/AllQuestions';
import AddQuestion from '../Questions/AddQuestion';
import AllQuestionNew from '../Questions/AllQuestionNew';
import MyQuestion from '../Questions/MyQuestions';
import Bookmark from '../Questions/Bookmark';
import Draft from '../Questions/DraftQuestion/DraftNew';
import ErrorHandler from '../ErrorHandler';

import './Dashboard.css';
import CreateExam from '../Exams/CreateExam';

function Dashboard(props) {

  const { i18n } = useTranslation();

  const { url, path } = useRouteMatch();
  const [classLoading, isClassLoading] = React.useState(true);

  function tokenGetter() {
    authenticationService.refreshFireToken();
  }

  // console.log(path, url);

  React.useEffect(() => {
    const intervalID = setInterval(() => tokenGetter(), 2940000);
    return () => clearInterval(intervalID);
  }, []);
  
  return (
    <Layout>
      <Siderc location={props.location}/>
      <Layout style={{}}>
        <Headerc location={props.location} isClassLoading={isClassLoading}/>
        <Layout>
          {!classLoading && <Switch key={i18n.language} >
            <Route exact path={`${url}dashboard`} render={() => <h1 className='ml-2'>Dashboard</h1>} />
            {/* <Route exact path={`${path}allQuestions`} component={AllQuestions} /> only for testing against the new component */}
            <Route exact path={`${path}allQuestions`} component={AllQuestionNew} />
            {/* <Route exact path={`${path}addQuestion`} component={AddQuestion} /> */}
            <Route exact path={`${path}addQuestion`} render={() =>
              <ErrorHandler>
                <AddQuestion />
              </ErrorHandler>
              } />
            <Route exact path={`${path}myQuestions`} render={() => <ErrorHandler> <MyQuestion/> </ErrorHandler>} />
            <Route exact path={`${path}bookmarkQuestions`} render={() => <ErrorHandler> <Bookmark/> </ErrorHandler>} />
            <Route exact path={`${path}draftQuestions`} render={() => <ErrorHandler> <Draft/> </ErrorHandler>} />
            <Route exact path={`${path}createExam`} render={() => <ErrorHandler> <CreateExam /> </ErrorHandler>} />
            
            {/* Redirect route from root to dashboard */}
            <Route exact path={`${url}`} render={ () => <Redirect to={`${url}dashboard`} /> } />
          </Switch>}
          {classLoading && <div style={{textAlign: 'center', marginTop: 50}}><Spin/></div>}
        </Layout>
      </Layout>
    </Layout>
  )

};

export default Dashboard;
