import React from "react";
import { authenticationService } from "../_services";

export default class ErrorBoundaries extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            errorMessage: ""
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        this.setState({ errorMessage: error.message });
        //Do something with err and errorInfo
        console.log(error);
        authenticationService.refreshFireToken().then(() => {
            console.log("force updating after refreshing token");
            this.forceUpdate();
        });
    }
    render() {
        if (this.state?.hasError) {
            return (
                <div className="divClass">
                    <h3>ErrorBoundaries</h3>
                    {this.state.errorMessage}
                    <br/>
                    <p>Please Wait while app re-registers.</p>
                </div>
            )
        }
        return (this.props.children);
    }
}