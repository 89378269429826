import {
  Steps,
  Button,
  message,
  Layout,
  Input,
  List,
  Avatar,
  Skeleton,
  Row,
  Col,
  Empty,
  Checkbox,
  Transfer,
  Drawer,
  Tree,
} from "antd";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { isEqual } from "lodash";
import { Siderc } from "../Main/Siderc";
import React from "react";
import "./CreateClass.css";
import { Headerc } from "../Main/Headerc";
import { Link } from "react-router-dom";
import {
  EditTwoTone,
  DeleteTwoTone,
  EyeOutlined,
  EyeFilled,
  DownOutlined,
} from "@ant-design/icons";
import history from "../../history";
import { selectorService } from "../../services/selectorService";
import parse from "html-react-parser";

const { Content } = Layout;

class ViewClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batchName: "",
      batches: [],
      step: 1,
      mockData: [],
      targetKeys: [],
      currentIndex: "",
      visible: false,
      subjects: [],
    };
  }
  componentDidMount() {
    console.log(this.props);
    selectorService
      .getAllClass()
      .then((res) => {
        let batches = res.result;
        console.log(batches);
        if (this.props.location.state) {
          selectorService
            .getClassDetails(this.props.location.state)
            .then((res) => {
              console.log(res);
              let currentIndex = "";
              batches.map((x, idx) => {
                currentIndex =
                  x.id === this.props.location.state ? idx : currentIndex;
              });
              console.log(currentIndex);
              this.setState({
                batches,
                visible: true,
                subjects: res.result.subjects,
                currentIndex,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.setState({
            batches,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //   componentDidUpdate(prevprops) {
  //     if (!isEqual(this.props.location, prevprops.location)) console.log("here");
  //   }

  render() {
    let header = this.state.step === 1 ? "Create " : this.state.batchName + " ";
    console.log(this.state.currentIndex, this.state.batches);
    let treeData = [
      {
        title:
          typeof this.state.currentIndex !== "string" &&
          this.state.batches.length >= this.state.currentIndex + 1
            ? `${this.state.batches[this.state.currentIndex].name}`
            : "",
        key: "0",
        children: [],
      },
    ];
    treeData[0].children = this.state.subjects.map((x, idx) => {
      return {
        title: x.name,
        key: "0" + idx,
        children: x.topics.map((y, idxY) => {
          return {
            title: y.name,
            key: "0" + idx + idxY,
          };
        }),
      };
    });
    console.log(this.props);

    return (
      <Layout>
        <Siderc />
        <Layout>
          <Headerc />
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Layout className="createExamLayoutHeader">Classes</Layout>
            <Layout className="createExamLayout">
              <Drawer
                title={
                  typeof this.state.currentIndex !== "string" &&
                  this.state.batches.length >= this.state.currentIndex + 1
                    ? `${this.state.batches[this.state.currentIndex].name}`
                    : ""
                }
                placement="right"
                closable={false}
                onClose={() => {
                  this.setState({ visible: false, currentIndex: "" });
                }}
                visible={this.state.visible}
              >
                <Tree
                  showLine
                  switcherIcon={<DownOutlined />}
                  //   defaultExpandedKeys={["0-0-0"]}
                  onSelect={this.onSelect}
                  treeData={treeData}
                />
              </Drawer>
              <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
                <Row>
                  {this.state.batches.map((x, idx) => {
                    if (idx % 4 === 0)
                      return (
                        <Col span={5}>
                          <div
                            key={idx}
                            style={{
                              minHeight: "7vh",
                              backgroundColor: "#F5F6F7",
                              marginBottom: "10%",
                              padding: "2vh",
                            }}
                          >
                            <EyeFilled
                              style={{ padding: "3px" }}
                              hidden={this.state.currentIndex !== idx}
                            />{" "}
                            <EyeOutlined
                              style={{ padding: "3px" }}
                              onClick={() => {
                                console.log("here");
                                selectorService
                                  .getClassDetails(x.id)
                                  .then((res) => {
                                    console.log(res);
                                    this.setState({
                                      currentIndex: idx,
                                      visible: true,
                                      subjects: res.result.subjects,
                                    });
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              hidden={this.state.currentIndex === idx}
                            />
                            <strong>{x.name}</strong>
                            {/* <span style={{ marginLeft: "2%" }}>
                              {"- (" + x.student_count + " Students)"}
                            </span> */}
                            <Link
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to submit",
                                  message:
                                    "Are you sure you want to delete " +
                                    x.name +
                                    " class",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        selectorService
                                          .deleteBatch(
                                            this.state.batches[idx].id
                                          )
                                          .then((res) => {
                                            let batches = this.state.batches.filter(
                                              (y) =>
                                                y.id !==
                                                this.state.batches[idx].id
                                            );
                                            this.setState({ batches }, () => {
                                              message.success(
                                                "Deleted Successfully"
                                              );
                                            });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      },
                                    },
                                    {
                                      label: "No",
                                      onClick: () => {},
                                    },
                                  ],
                                });
                              }}
                              style={{ float: "right", marginLeft: "2%" }}
                              to="#"
                            >
                              <DeleteTwoTone />
                            </Link>
                            <Link
                              style={{ float: "right" }}
                              to={{
                                pathname: "/class",
                                state: this.state.batches[idx],
                              }}
                            >
                              <EditTwoTone />
                            </Link>
                          </div>
                        </Col>
                      );
                    else
                      return (
                        <Col span={5} offset={1}>
                          <div
                            key={idx}
                            style={{
                              minHeight: "7vh",
                              backgroundColor: "#F5F6F7",
                              marginBottom: "10%",
                              padding: "2vh",
                            }}
                          >
                            <EyeFilled
                              style={{ padding: "3px" }}
                              hidden={this.state.currentIndex !== idx}
                            />{" "}
                            <EyeOutlined
                              style={{ padding: "3px" }}
                              onClick={() => {
                                console.log("here");
                                selectorService
                                  .getClassDetails(x.id)
                                  .then((res) => {
                                    console.log(res);
                                    this.setState({
                                      currentIndex: idx,
                                      visible: true,
                                      subjects: res.result.subjects,
                                    });
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                              hidden={this.state.currentIndex === idx}
                            />
                            <strong>{x.name}</strong>
                            {/* <span style={{ marginLeft: "2%" }}>
                              {"- (" + x.student_count + " Students)"}
                            </span> */}
                            <Link
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm to submit",
                                  message:
                                    "Are you sure you want to delete " +
                                    x.name +
                                    " class",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        selectorService
                                          .deleteClass(
                                            this.state.batches[idx].id
                                          )
                                          .then((res) => {
                                            if (
                                              res &&
                                              res.status &&
                                              res.status.code &&
                                              res.status.code !== "-1"
                                            ) {
                                              let batches = this.state.batches.filter(
                                                (x) =>
                                                  x.id !==
                                                  this.state.batches[idx].id
                                              );
                                              message.success(
                                                "Deleted Successfully"
                                              );
                                              this.setState({ batches });
                                            } else {
                                              message.error(
                                                "Some error occured"
                                              );
                                            }
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      },
                                    },
                                    {
                                      label: "No",
                                      onClick: () => {},
                                    },
                                  ],
                                });
                              }}
                              style={{ float: "right", marginLeft: "2%" }}
                              to="#"
                            >
                              <DeleteTwoTone />
                            </Link>
                            <Link
                              style={{ float: "right" }}
                              to={{
                                pathname: "/class",
                                state: this.state.batches[idx],
                              }}
                            >
                              <EditTwoTone />
                            </Link>
                          </div>
                        </Col>
                      );
                  })}
                </Row>
              </div>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default ViewClass;
