import { authenticationService } from "../_services";
import { INSTITUTE_API, LOAD_CLASSES, LOAD_LANGAUGES, LOAD_QUESTIONTYPES, LOAD_TOPICS_PREFIX } from "../_services/config";

export const S3Bucket = {
    examImageUpload,
    practiceImageUpload
}

async function examImageUpload(examId, file) {
    // note - 'examId' argument is named as 'id' in the actual function.
    // console.log("examImageUpload");
    // console.log(file);
    let data = new FormData();
    data.append('name', `${examId}.png`);
    data.append('file', file);

    const currentUser = authenticationService.currentUserValue;
    const requestOptions = {
        method: "POST",
        headers: {
            Accept: "multipart/form-data",
            // "Content-Type": "multipart/form-data",
            Authorization: "Bearer ".concat(currentUser.jwtToken),
        },
        body: data,
    };
    if (currentUser.language_code === null) {
        currentUser.language_code = "En";
        currentUser.language_name = "English";
    }
    try {
        const response = await fetch(`https://api-bucket.odiprojects.com/api/AWSS3File/${examId}/uploadExamImage`, requestOptions);
        const result = await response.json();
        console.log(response);
        if(response.ok) {
            return "https://s3bucket-for-oa.s3.ap-south-1.amazonaws.com/1/exams/"+examId+".png"
        }
        // console.log(result);
    } catch (error) {
        console.log(error);
    }
}

async function practiceImageUpload(practiceId, file) {
    // note - 'examId' argument is named as 'id' in the actual function.
    // console.log("examImageUpload");
    // console.log(file);
    let data = new FormData();
    data.append('name', `${practiceId}.png`);
    data.append('file', file);

    const currentUser = authenticationService.currentUserValue;
    const requestOptions = {
        method: "POST",
        headers: {
            Accept: "multipart/form-data",
            // "Content-Type": "multipart/form-data",
            Authorization: "Bearer ".concat(currentUser.jwtToken),
        },
        body: data,
    };
    if (currentUser.language_code === null) {
        currentUser.language_code = "En";
        currentUser.language_name = "English";
    }
    try {
        const response = await fetch(`https://api-bucket.odiprojects.com/api/AWSS3File/${practiceId}/uploadPracticeImage`, requestOptions);
        const result = await response.json();
        // console.log(result);
    } catch (error) {
        console.log(error);
    }
}