const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const INSTITUTE_API_PREFIX = process.env.REACT_APP_INSTITUTE_API_PREFIX;
const ADMIN_API_PREFIX = process.env.REACT_APP_ADMIN_API_PREFIX;

export const FIREBASE_LOGIN = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_API_KEY}`
export const API_USER_SIGN_IN = `https://api.odiprojects.com/api-user/v${API_VERSION}/Users/SignIn`;

export const INSTITUTE_API = INSTITUTE_API_PREFIX + API_VERSION;
export const LOAD_QUESTIONTYPES = `${ADMIN_API_PREFIX}${API_VERSION}/QuestionTypes`;

// FULL PATH -> LOAD_TOPICS = LOAD_TOPICS_PREFIX + ${subject.id} + "/Categories"
export const LOAD_TOPICS_PREFIX = `${INSTITUTE_API_PREFIX}${API_VERSION}/Subjects/`;

export const LOAD_CLASSES = `${INSTITUTE_API_PREFIX}${API_VERSION}/Classes`;
// FULL PATH TO LOAD SUBJECTS -> LOAD_CLASSES  + "/" + ${currentClass.id} +"/Subjects"

export const LOAD_LANGAUGES = `${ADMIN_API_PREFIX}${API_VERSION}/Languages`;

