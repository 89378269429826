import React from 'react';
import './Selector.css';
import { Cascader } from 'antd';
import { selectorService } from '../../services/selectorService';
import equal from 'fast-deep-equal';


let category = [

];


let type = [


];


let complexity = [
  {
    label:'Easy',
    value: 1
  },
  {
    label:'Medium',
    value: 2
  },
  {
    label:'Difficult',
    value: 3
  }

];








function onChangeQuestionType(value,context) {
  selectorService.loadQuestionTypes();
}


class Selector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classes:props.selectorState.classes,
      module_id: props.selectorState.subjectId,
      module: props.selectorState.module,
      questionType: "",
      complexity: "",
      data: null,
      page: props.page,
      toggle: true
    }
    this.sendData = this.sendData.bind(this);
    this.onChangeQuestionTypeData = this.onChangeQuestionTypeData.bind(this);
    this.onChangeComplexityData = this.onChangeComplexityData.bind(this);
    this.onChangeCategoryData = this.onChangeCategoryData.bind(this); 
    this.handleLoad = this.handleLoad.bind(this); 
  };

  componentDidMount() {
    // console.log(this.state,'mounted');
    window.addEventListener('load', this.handleLoad.bind(this));
    this.setState({classes:this.props.selectorState.classes,
      module_id: this.props.selectorState.subjectId,
      module: this.props.selectorState.module,
      questionType: "",
      complexity: "",
      data: null,
      page: this.props.page,
      toggle: true})
    this.handleLoad();
  }

  componentDidUpdate(prevProps) {
    if(!equal(this.props.selectorState, prevProps.selectorState)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.handleLoad();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }


  handleLoad = () =>  {
    category = [];
    // console.log(this.props);
    selectorService.loadCategory(this.props.selectorState.subjectId).then(data => {
      // console.log(data);
      
      if(data === undefined)
        return;
      const result = data.result;
      category = [];
      for (let index = 0; index < result.length; index++) {
        let c = {};
        c.label = (result[index]).name;
        c.value = (result[index]).id;
        category.push(c);
      }
      this.forceUpdate();
    });


    selectorService.loadQuestionTypes().then(data => {
      const result = data.result;
      type =[];
      for (let index = 0; index < result.length; index++) {
        let c = {};
        c.label = (result[index]).name;
        c.value = (result[index]).code;
        type.push(c);
       
      }
      this.forceUpdate();
    });

  }


  onChangeQuestionTypeData = (event) => {
    if (event===undefined || event.length === 0) {
      this.state.questionType = '';
      this.sendData();
    } else {
      // console.log("Child sending selector Call: " + event[0]);
      this.state.questionType = event[0];
      this.sendData();
      onChangeQuestionType(event,this);
    }

  }

  onChangeComplexityData = (event) => {
    if (event===undefined || event.length === 0) {
      this.state.complexity = '';
      this.sendData();
    } else {
      // console.log("Child sending selector Call: " + event[0]);
      this.state.complexity = event[0];
      this.sendData();
    }

  }

  onChangeCategoryData = (event) => {
    if (event===undefined || event.length === 0) {
      this.state.module = 'subject';
      this.state.module_id = this.props.selectorState.subjectId;

      this.sendData();
    } else {
      // console.log("Child sending selector Call: " + event[0]);
      this.state.module = 'category';
      this.state.module_id = event[0];
      this.sendData();
    }

  }

  sendData = () => {
    const modal ={
      classes: this.props.selectorState.classes ,
      module_id: this.state.module_id === undefined?this.props.selectorState.module_id:this.state.module_id,
      subjectId: this.props.selectorState.subjectId,
      module: this.state.module,
      questionType: this.state.questionType,
      complexity: this.state.complexity,
    }
    this.props.parentCallback(modal);
  }

  render() {
    return (
      <div className="selector">
        <div className="selector-2">
          <Cascader size="small" id="topic" key="topic" ref="category" options={category} placeholder="Topic" onChange={this.onChangeCategoryData.bind(this)} />
        </div>
        <div className="selector-2">

          <Cascader size="small" key="type" options={type} placeholder="Question Type" onChange={this.onChangeQuestionTypeData.bind(this)} />

        </div>
        <div className="selector-2"  hidden={(this.state.page !== 'addQuestions')}>

          <Cascader size="small" key="complexity" options={complexity} placeholder="Complexity" onChange={this.onChangeComplexityData.bind(this)} />
        </div>
        <div className="selector-2">
        
         
        </div>
      </div>
    )
  };
};

export default Selector;
