import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import NewPassword from './NewPassword';

const FirebaseAction = (props) => {

    // Parsing the query string 
    // Using parse method
    let queries = queryString.parse(props.location.search);
    // console.log(queries); // dev-logging

    //first check
    if (!queries.mode) {
        return <Redirect to="/login" />
    }

    if (queries?.mode === "resetPassword") {
        return (
            <NewPassword location={props.location} oobCode={queries.oobCode} />
        );
    }

}

export default FirebaseAction;
