import React from "react";
import "./CreateQuestion.css";
import { PageHeader, Radio, Avatar, Space, Button, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import RichTextEditor from "./RichTextEditor";
import { authenticationService } from "../../_services";
import TextArea from "antd/lib/input/TextArea";
import { selectorService } from "../../services/selectorService";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
let type = {};
class CreateQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      bottom: "bottomRight",
      classes: "",
      module_id: "",
      module: "",
      questionType: "",
      complexity: 0,
      top: "topLeft",
      bottom: "bottomRight",
      upload: false,
      title: "",
      answer_explanation: "",
      source: "Manual Entry",
      options: [],
      count: 0,
      reset: false,
    };
    this.onAddOption = this.onAddOption.bind(this);
    this.onClickRadio = this.onClickRadio.bind(this);
    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.onDeleteOption = this.onDeleteOption.bind(this);
    this.onTextAreaExplaination = this.onTextAreaExplaination.bind(this);
    this.handleLoadQuestionType = this.handleLoadQuestionType.bind(this);
    this.onChangeComplexity = this.onChangeComplexity.bind(this);
    this.onSaveQuestion = this.onSaveQuestion.bind(this);
    this.onSubmitQuestion = this.onSubmitQuestion.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  resetState() {
    this.setState({
      currentUser: authenticationService.currentUserValue,
      bottom: "bottomRight",
      classes: "",
      module_id: "",
      module: "",
      questionType: "",
      complexity: "",
      top: "topLeft",
      bottom: "bottomRight",
      upload: false,
      title: "",
      answer_explanation: "",
      source: "Manual Entry",
      reset: true,
      options: [],
      count: 0,
    });
  }

  onSubmitQuestion() {
    console.log("Submitted");
    let question = {};
    question.title = this.state.title;
    if (this.state.complexity !== "") {
      question.complexity_code = this.state.complexity;
      question.type = this.props.selectorState.questionType;
      if (this.props.selectorState.module === "category") {
        question.topic_id = this.props.selectorState.module_id;
      }
      if (this.state.answer_explanation !== "") {
        question.answer_explanation = this.state.answer_explanation;
      }
      if (this.state.options !== []) {
        question.options = this.state.options;
      }
      question.status = "PUBLISHED";
      question.source = this.state.source;
      const key = "createQuestion";
      console.log(question);
      message.loading({ content: "Creating Question...", key });
      selectorService.createQuestion(question).then((data) => {
        console.log(data);
        message.success({ content: "Question Created!", key, duration: 3 });
        this.resetState();
      });
    }
  }

  onSaveQuestion() {
    let question = {};
    question.title = this.state.title;
    if (this.state.complexity !== "") {
      question.complexity_code = this.state.complexity;
      question.type = this.props.selectorState.questionType;
      if (this.props.selectorState.module === "category") {
        question.topic_id = this.props.selectorState.module_id;
      }
      if (this.state.answer_explanation !== "") {
        question.answer_explanation = this.state.answer_explanation;
      }
      if (this.state.options !== []) {
        question.options = this.state.options;
      }
      question.status = "DRAFT";
      question.source = this.state.source;
      const key = "saveQuestion";
      message.loading({ content: "Creating Question...", key });
      selectorService.createQuestion(question).then((data) => {
        message.success({ content: "Question Draft Saved!", key, duration: 3 });
        this.resetState();
        console.log("Draft Saved");
      });
    }
  }

  callbackRichFunction = (childData) => {
    console.log("Parent recieved Selector Data: " + childData);
    this.setState({
      title: childData.editorHtml,
      reset: false,
    });
  };

  onChangeComplexity = (event) => {
    this.setState({
      complexity: event.target.value,
    });
  };
  componentDidMount() {
    window.addEventListener("load", this.handleLoadQuestionType.bind(this));
    this.handleLoadQuestionType();
  }
  handleLoadQuestionType = () => {
    selectorService.loadQuestionTypes().then((data) => {
      const result = data?.result;
      type = [];
      for (let index = 0; index < result?.length; index++) {
        type[result[index]?.code] = result[index]?.name;
      }
      this.forceUpdate();
    });
  };

  onTextAreaExplaination = (event) => {
    this.setState({
      answer_explanation: event.target.value,
    });
  };

  onDeleteOption = (event, index) => {
    let itemId = this.state.options[index].id;
    const items = this.state.options.filter((item) => item.id !== itemId);
    this.setState({ options: items });
    this.forceUpdate();
  };
  onTextAreaChange = (event, index) => {
    let tempOptions = this.state.options;
    tempOptions[index].text = event.target.value;
    this.setState({ options: tempOptions });
  };

  onAddOption = (event) => {
    let option = { text: "", isCorrect: false, id: this.state.count };
    let tempOptions = this.state.options;
    tempOptions.push(option);
    this.setState({ options: tempOptions });
    this.state.count++;
    // this.state.options;
  };

  onClickRadio = (event, index) => {
    let tempOptions = [...this.state.options];
    let updatedIsCorrect = !tempOptions[index].isCorrect;
    if (this.props.selectorState.questionType === "MCQ")
      tempOptions.map((option, index) => (option.isCorrect = false));
    tempOptions[index].isCorrect = updatedIsCorrect;
    this.setState({ options: tempOptions });
    this.forceUpdate();
  };
  render() {
    if(!type) { return <h4>Waiting for authentication</h4> }
    // console.log(this.props, this.state);
    if (
      this.props.selectorState.questionType === "SUB" &&
      this.state.options.length > 0
    )
      this.setState({ options: [] });
    return (
      <div className="create-question-section">
        <h1 className="Question">
          {" "}
          <span>{type[this.props.selectorState.questionType]}</span>
        </h1>
        <Form className="login-form">
          <div
            className="QuestionPanel"
            hidden={this.props.selectorState.questionType === ""}
          >
            <PageHeader className="QuestionHeader" title="Question Details" />

            <div className="QuestionContent">
              <span>Question</span>
              <RichTextEditor
                reset={this.state.reset}
                parentCallback={this.callbackRichFunction}
                placeholder={"Write Question..."}
              />
              <span hidden={this.props.selectorState.questionType === "SUB"}>
                Correct Options
              </span>
              <div hidden={this.props.selectorState.questionType !== "TNF"}>
                <Radio.Group name="radiogroup">
                  <Radio
                    onChange={(event) =>
                      this.setState({
                        options: [
                          { text: "True", isCorrect: true, id: 0 },
                          { text: "False", isCorrect: false, id: 1 },
                        ],
                      })
                    }
                    value={true}
                  >
                    True
                  </Radio>
                  <Radio
                    onChange={(event) =>
                      this.setState({
                        options: [
                          { text: "True", isCorrect: false, id: 0 },
                          { text: "False", isCorrect: true, id: 1 },
                        ],
                      })
                    }
                    value={false}
                  >
                    False
                  </Radio>
                </Radio.Group>
              </div>
              <div
                className="MCQ"
                hidden={
                  !(
                    this.props.selectorState.questionType === "MCQ" ||
                    this.props.selectorState.questionType === "MRQ"
                  )
                }
              >
                <div className="optionsDiv" id="optionsDiv">
                  {this.state.options.map((option, index) => (
                    <div
                      style={{ display: "inline-block", padding: "4px" }}
                      key={index}
                    >
                      <Space align="baseline">
                        <Avatar
                          className={option.isCorrect ? "radioSelected" : ""}
                          gap={4}
                          shape="square"
                          size={32}
                          onClick={(e) => {
                            console.log("clicked");
                            this.onClickRadio(e, index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {alphabet[index]}
                        </Avatar>

                        <Radio
                          button
                          checked={option.isCorrect}
                          onClick={(e) => {
                            console.log("clicked");
                            this.onClickRadio(e, index);
                          }}
                          value={option.text}
                        >
                          <TextArea
                            className="TextArea"
                            onBlur={(e) => {
                              this.onTextAreaChange(e, index);
                            }}
                          >
                            {option.text}
                          </TextArea>
                        </Radio>
                        <CloseOutlined
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            this.onDeleteOption(e, index);
                          }}
                        />
                      </Space>
                    </div>
                  ))}
                </div>
                <div className="AddOptionButton">
                  <Button
                    onClick={this.onAddOption}
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    + Add New Option
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="QuestionPanel"
            hidden={this.props.selectorState.questionType === ""}
          >
            <PageHeader className="QuestionHeader" title="Other Details" />

            <div className="QuestionOtherContent">
              <br />
              <span>Complexity</span>
              <br />
              <Radio.Group
                buttonStyle="solid"
                value={this.state.complexity}
                onChange={(e) => {
                  this.onChangeComplexity(e);
                }}
              >
                <Radio.Button value="1">Easy</Radio.Button>
                <Radio.Button value="2">Medium</Radio.Button>
                <Radio.Button value="3">Difficult</Radio.Button>
              </Radio.Group>
              <br />
              <br />
              <span>Explanation</span>
              <TextArea
                className="TextArea"
                placeholder="Answer Explanation"
                value={this.state.answer_explanation}
                onChange={(e) => {
                  this.onTextAreaExplaination(e);
                }}
              />

              <div></div>
            </div>
          </div>
          <div
            className="ButtonPanel"
            hidden={this.props.selectorState.questionType === ""}
          >
            <Button
              onClick={this.onSaveQuestion}
              type="primary"
              style={{ marginRight: 8 }}
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              disabled={
                this.props.selectorState.module !== "category" ||
                !this.state.complexity.length ||
                this.state.complexity.length === 0 ||
                this.state.answer_explanation.length === 0 ||
                this.state.title === "" ||
                ((this.state.options.length < 2 ||
                  this.state.options.filter((y) => y.isCorrect).length === 0 ||
                  this.state.options.filter((y) => y.text === "").length > 0) &&
                  (this.props.selectorState.questionType === "MCQ" ||
                    this.props.selectorState.questionType === "MRQ")) ||
                this.state.complexity === ""
              }
              onClick={this.onSubmitQuestion}
              type="primary"
              style={{ marginRight: 8 }}
            >
              {" "}
              Submit{" "}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default CreateQuestion;
