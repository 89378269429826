// @ts-check
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Input, Button, Alert, Typography } from 'antd';
import { authenticationService } from '../../_services';
import { LeftOutlined } from '@ant-design/icons';

import Background from './Background';

// SVG imports here
import logo from '../assets/image/PracticeKiaLogo.svg';

// CSS imports here
import './Login.css';

const NewPassword = (props) => {

    const [email, setEmail] = React.useState("Your Email Here");
    const [disabled, setDisabled] = React.useState(false);
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string('Enter your new password').required('New Password is required'),
        confirmPassword: Yup.string('Confirm your new password').required('Please confirm your password')
    });

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    React.useEffect(() => {
        authenticationService.fetchUserEmail(props.oobCode).then(
            data => {
                if (data.requestType === 'PASSWORD_RESET') setEmail(data.email);
                else {
                    history.push('/login');
                }
            }
        ).catch(({error}) => {
            setDisabled(true);
            if (error.message === "INVALID_OOB_CODE") formik.setStatus("Please check your reset password link");
            if (error.message === "EXPIRED_OOB_CODE") formik.setStatus("Your reset password link has expired, please try again.");
        });
    }, []);

    function handleSubmit({ newPassword, confirmPassword }, { setStatus, setSubmitting }) {
        let checkSuccess = false;
        // This state comes from Formik
        setStatus();
        // setSubmitting is automatically set to true by Formik

        // block of code to check if confirmPassword is same as newPassword
        // 
        //
        if (confirmPassword === newPassword) {
            checkSuccess = true;
        } else {
            checkSuccess = false;
            setStatus("Please make sure the passwords entered match correctly.");
            formik.setSubmitting(false);
            return;
        }

        // Service to send new password to Firebase
        checkSuccess && authenticationService.newPassword(props.oobCode, newPassword) // implement new authentication service for new password
            .then(data => {
                // Dev build logging
                // console.log(user?.id);

                if (!data) {
                    // Technically, I should never enter this block of code and just jump to error block, 
                    // but this is written to make sure everything works in certain edge cases.
                    setStatus('Something went wrong, please try again after some time.');
                    setSubmitting(false);
                    setTimeout(() => {
                        setStatus();
                    }, 6000);

                } else {
                    setStatus("Your password has been changed. You will be redirected to login screen in a few seconds...");
                    setTimeout(() => {
                        const { from } = props.location.state || { from: { pathname: "/login" } };
                        history.push(from);
                    }, 6000);
                }
            },
                ({error}) => {
                    setSubmitting(false);
                    // error.code === 400 && setStatus(error.message);
                    if (error.message === "INVALID_OOB_CODE") setStatus("Please check your reset password link");
                    if (error.message === "EXPIRED_OOB_CODE") setStatus("Your reset password link has expired, please try again.");
                }
            );
    }

    return (
        <Row className='login-page'>
            <Col xs={0} md={0} lg={14} id='background' className='center-flex'>{/*background image here*/}
                <Background />
            </Col>
            <Col xs={24} md={24} lg={10} id='' style={{ position: "relative" }}>
                <div className="container">
                    <Link to='/login' className="top-insert"><LeftOutlined style={{ marginRight: 6 }} />Back to Login</Link>
                    <div className="frame">
                        <div className="image-block">
                            <img src={logo} id='logo' alt='Institute Logo' />
                            <Typography.Title level={3}>Practice<span>Kea</span></Typography.Title>
                        </div>
                        <Typography.Title className='heading' level={2} style={{ textAlign: "center" }}>Reset Password</Typography.Title>
                        <Typography.Text type="secondary" className='heading subtitle'>
                            Enter a new password to reset
                        </Typography.Text>
                        <form className='pb-9' onSubmit={formik.handleSubmit}>

                            <label htmlFor="email"><Typography.Title level={5}>Email Address<span className='required-span'></span>:</Typography.Title></label>
                            <Input
                                disabled={disabled}
                                className='input-custom'
                                name="email"
                                id="email"
                                size="large"
                                value={email}
                            />

                            <label htmlFor="new-password"><Typography.Title level={5}>New Password<span className='required-span'>*</span>:</Typography.Title></label>
                            <Input.Password
                                disabled={disabled}
                                className='input-custom'
                                name="newPassword"
                                id="new-password"
                                size="large"
                                placeholder="Enter the new password"
                                autoComplete='new-password'
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                            />

                            <label htmlFor="confirm-password"><Typography.Title level={5}>Confirm Password<span className='required-span'>*</span>:</Typography.Title></label>
                            <Input.Password
                                disabled={disabled}
                                className='input-custom'
                                name="confirmPassword"
                                id="confirm-password"
                                size="large"
                                placeholder="Confirm the new password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                            />

                            <Button disabled={disabled} htmlType="submit" type={"primary"} size={`large`} loading={formik.isSubmitting} style={{ marginBottom: 8, width: "100%" }}
                                onClick={() => {
                                    formik.errors.newPassword || formik.errors.confirmPassword
                                        ? formik.setStatus(formik.errors.newPassword || formik.errors.confirmPassword)
                                        : formik.setStatus("")
                                }}>
                                {formik.isSubmitting ? `Loading` : `Submit Password`}
                            </Button>

                            {formik.status ? <Alert message={formik.status} type='error' style={{ marginBottom: 8 }} /> : <></>}

                        </form>
                    </div>
                    <footer>© {new Date().getFullYear()} PracticeKea</footer>
                </div>
            </Col>

        </Row>
    );
}

export default NewPassword;
