import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import httpApi from "i18next-http-backend";

i18n.use(initReactI18next).use(httpApi).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('../locales/en/translation.json')
    },
    hi: {
      translations: require('../locales/hi/translation.json')
    },
    or: {
      translations: require('../locales/or/translation.json')
    }
  },
  interporlation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === 'development',
  ns: ['translations'],
  defaultNS: 'translations',

  // special options for react-i18next
  // learn more: https://react.i18next.com/components/i18next-instance
  react: {
    wait: true,
  },
});

i18n.languages = ['en', 'hi', 'or'];

// // export function to change Language on the fly
// export const changeLanguage = (lang) => {
//   const { i18n } = useTranslation();

//   function changeLanguage(lang) {
//     i18n.changeLanguage(lang);
//   }

//   changeLanguage(lang);
// }

export default i18n;
