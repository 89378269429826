import React from 'react';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Input, Button, Alert, Typography } from 'antd';
import { authenticationService } from '../../_services';
import { LeftOutlined } from '@ant-design/icons';

import Background from './Background';

// SVG imports here
import logo from '../assets/image/PracticeKiaLogo.svg';

// CSS imports here
import './Login.css';

const ResetPassword = (props) => {

    React.useEffect(() => {
        const userAuth = authenticationService.currentUserValue;
        if (userAuth) {
            props.history.push(props.location.state || '/dashboard' );
        }
    }, []);

    const validationSchema = Yup.object().shape({
        username: Yup.string('Enter your email address').required('Email address is required'),
    });

    function handleSubmit({ username }, { setStatus, setSubmitting }) {
        // This state comes from Formik
        setStatus();
        // setSubmitting is automatically set to true by Formik

        authenticationService.resetPassword(username) // implement new authentication service for reset password
            .then(
                user => {
                    // Dev build logging
                    // console.log(user?.id);

                    if (!user) {
                        // Technically, I should never enter this block of code and just jump to error block, 
                        // but this is written to make sure everything works in certain edge cases.
                        setStatus('Something went wrong, please try again after some time.');
                        setSubmitting(false);
                        setTimeout(() => {
                            setStatus();
                        }, 6000);

                    } else {
                        setStatus("Please check your Email and follow the instruction to reset your password.");
                        setTimeout(() => {
                            const { from } = props.location.state || { from: { pathname: "/login" } };
                            props.history.push(from);
                        }, 6000);
                    }
                },
                error => {
                    setSubmitting(false);
                    error.error.code === 400 && setStatus("Email is not registered, please sign in.");
                }
            );
    }

    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Row className='login-page'>
            <Col xs={0} md={0} lg={14} id='background' className='center-flex'>{/*background image here*/}
                <Background />
            </Col>
            <Col xs={24} md={24} lg={10} id='' style={{ position: "relative" }}>
                <div className="container">
                    <Link to='/login' className="top-insert"><LeftOutlined style={{ marginRight: 6 }} />Back to Login</Link>
                    <div className="frame">
                        <div className="image-block">
                            <img src={logo} id='logo' alt='Institute Logo' />
                            <Typography.Title level={3}>Practice<span>Kea</span></Typography.Title>
                        </div>
                        <Typography.Title className='heading' level={2} style={{ textAlign: "center" }}>Forgot Password</Typography.Title>
                        <Typography.Text type="secondary" className='heading subtitle'>
                            Enter the email address associated with your account and we will send you the reset password
                            link on your registered email.
                        </Typography.Text>
                        <form className='pb-9' onSubmit={formik.handleSubmit}>

                            <label htmlFor="username"><Typography.Title level={5}>Username<span className='required-span'>*</span>:</Typography.Title></label>
                            <Input
                                className='input-custom'
                                name="username"
                                id="username"
                                size="large"
                                placeholder="Enter your email address"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                            // style={{ marginBottom: 20 }}
                            />

                            <Button htmlType="submit" type={"primary"} size={`large`} loading={formik.isSubmitting} style={{ marginBottom: 8, width: "100%" }}
                                onClick={() => { formik.errors.username || formik.errors.password ? formik.setStatus(formik.errors.password || formik.errors.username) : formik.setStatus() }}>
                                {formik.isSubmitting ? `Loading` : `Send Email`}
                            </Button>

                            {formik.status ? <Alert message={formik.status} type='error' style={{ marginBottom: 8 }} /> : <></>}

                        </form>
                    </div>
                    <footer>© {new Date().getFullYear()} PracticeKea</footer>
                </div>
            </Col>

        </Row>
    );
}

export default ResetPassword;
