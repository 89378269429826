import React from 'react';
import { Spin, Select, Space } from 'antd';
import _ from 'lodash';

import { selectorService } from '../../services/selectorService';
import { SubjectSubscriber } from 'rxjs/internal/Subject';
import { CaretDownOutlined } from '@ant-design/icons';



const SelectorNew = ({serviceCall, pathActive, handleSelection, property, placeholder}) => {

    const [items, setItems] = React.useState([]);
    const [loading, isLoading] = React.useState(true);
    const [selected, setSelected] = React.useState();

    React.useEffect(() => {
        async function a() {
            if(property === 'subject'||'category') {
                if(pathActive){
                    property === 'category' && handleChange();
                    const data = await serviceCall(pathActive);
                    if(data) {
                        const result = await data.result;
                        // console.log(result);
                        if(_.isEmpty(result)) {
                            setItems([]);
                        } else {
                            await setItems([...result]);
                        }
                        isLoading(false); 
                    }
                } else {
                    isLoading(true);
                    setItems([]);                    
                    handleChange();
                }
            }
            if(property === 'questionType') {
                const data = await serviceCall();
                if(data) {
                    const result = await data.result;
                    // console.log(result);
                    if(_.isEmpty(result)) {
                        setItems([]);
                    } else {
                        await setItems([...result]);
                    }
                    isLoading(false); 
                } else {
                    setItems([]);
                } 
            }
            if (property === 'complexity') {
                setItems([
                    {
                        name: 'Easy',
                        value: 1,
                        id: 1
                    },
                    {
                        name: 'Medium',
                        value: 2,
                        id: 2
                    },
                    {
                        name: 'Difficult',
                        value: 3,
                        id: 3
                    }
                ]);
                isLoading(false);
            }
        }
        a();
    }, [pathActive]);

    function handleChange(event, objectSelected) {
        event? setSelected(event) : setSelected(null);
        // console.log(event, objectSelected?.children);
        handleSelection(property, event);
    }

    return (
            <Select
                size='normal'
                // suffixIcon={<CaretDownOutlined />}
                placeholder={placeholder}
                dropdownRender={(menu) => {
                    return (
                        <>
                            {!loading? <>{menu}</> : 
                            <>
                                <Space style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10}}>
                                    <Spin/>
                                    You must have a subject selected to have topic listed!
                                </Space>
                            </>
                            }
                        </>
                    );
                }}
                allowClear
                onChange={handleChange}
                value={selected}
                style={{minWidth: '200px'}}
            >
                {items.map((item) => (
                    <Select.Option key={item.id} value={item.code||item.id}>{item.name}</Select.Option>
                ))}
            </Select>
    );
}

export default SelectorNew;