import React from 'react';
import { Button, Layout } from 'antd';
// import 'antd/dist/antd.css';
import './AllQuestions.css';

import Selector from './Selector';
import QuestionTableNew from './QuestionTableNew';
import { authenticationService } from '../../_services';
import { selectorService } from '../../services/selectorService';

//experiment
import SelectorNew from './SelectorNew';
import { Link } from 'react-router-dom';


function AllQuestionNew() {
    const [state, setState] = React.useState({
        currentUser: authenticationService.currentUserValue,
        top: 'topLeft',
        bottom: 'bottomRight',
        classes: authenticationService.currentClassValue,
        subject: "",
        category: "",
        questionType: "",
        complexity: ""
    });


    // function callbackFunction () {
    //     //  console.log("Parent recieved Selector Data: "+ childData);
    //     setState(prevVal => ({...prevVal,
    //         module_id: state.category? state.category : state.subject,
    //         module: state.category? 'category' : state.subject? 'subject' : "",
    //     }));
    // }

    function handleSelection(property, value="") {
        // console.log(property);
        setState(prevVal => ({ ...prevVal,
            [property]: value ,
        }));
        // callbackFunction();
    }

    return (
        <Layout>
            <Layout className="all-questions-section">
                <div className="selector-section">
                    <div className='left-side'>
                        <SelectorNew serviceCall={selectorService.loadSubjects} pathActive={state.classes} handleSelection={handleSelection} property="subject"
                            placeholder="Select a subject" />
                        <SelectorNew serviceCall={selectorService.loadCategory} pathActive={state.subject} handleSelection={handleSelection} property="category" 
                            placeholder="Select a topic" />
                        <SelectorNew serviceCall={selectorService.loadQuestionTypes} handleSelection={handleSelection} property="questionType" 
                            placeholder="Select the question type" />
                        <SelectorNew handleSelection={handleSelection} property="complexity" placeholder="Select a difficulty" />
                    </div>
                    <div className='right-side'>
                        <Button type='primary'><Link to="/addQuestion">New +</Link></Button>
                    </div>
                </div>
                <Layout className="list-questions-section">
                    <div>
                        <QuestionTableNew selectorState={state} page={1}/>
                    </div>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default AllQuestionNew;
