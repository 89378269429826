import React, { Component } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';
import { authenticationService } from '../../_services';
import { Input, Button, Typography, Alert } from 'antd';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// CSS import
import './Login.css';
// SVG import
import logo from '../assets/image/OdiSVGlogo.svg';


const CreateAccount = (props) => {

    const {t} = useTranslation();

    const validationSchema = Yup.object().shape({
        username: Yup.string('Enter your username or email').required('Username is required'),
        password: Yup.string('Enter your password').required('Password is required')
    });

    function handleSubmit({ username, password }, { setStatus, setSubmitting }) {
        let protocol = window.location.protocol;
        let host = window.location.hostname;
        let port = window.location.port;
        let obj ={
            "emailId": username,
            "userPassword": password,
            "scheme": protocol,
            "host": host,
            "port": port
        }

        // This state comes from Formik
        setStatus();
        // setSubmitting is automatically set to true by Formik

        authenticationService.registerUser(obj)
            .then(
                user => {
                    // Dev build logging
                    console.log(user?.id);

                    if (!user) {
                        // Technically, I should never enter this block of code and just jump to error block, 
                        // but this is written to make sure everything works in certain edge cases.
                        setStatus('Invalid Credential');
                        setSubmitting(false);
                        setTimeout(() => {
                            setStatus();
                        }, 3000);

                        // Original Code - I am already on login page, why make user reload the page again?
                        // const { from } = this.props.location.state || { from: { pathname: "/login" } };
                        // this.props.history.push(from);

                    } else {
                        const { from } = props.location.state || { from: { pathname: "/login" } };
                        props.history.push(from);
                    }
                },
                error => {
                    setSubmitting(false);
                    setStatus(error.message);
                }
            );
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Row className='login-page'>
            <Col xs={0} md={0} lg={15} id='background'>{/*background image here*/}</Col>
            <Col xs={24} md={24} lg={9} id='' style={{ position: "relative" }}>
                <div className="container" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="frame" style={{
                        paddingBlock: 20, paddingInline: 8,
                        display: "flex", flexDirection: "column", height: "500px",
                        maxWidth: "400px", width: "100%", justifyContent: "center"
                    }}>
                        <div className="image-block">
                            <img src={logo} id='logo' alt='Institute Logo' />
                        </div>
                        <Typography.Title level={4} style={{ textAlign: "center" }}>Institute Registration</Typography.Title>
                        <form onSubmit={formik.handleSubmit}>
                            <label htmlFor="username"><Typography.Title level={5}>Username*:</Typography.Title></label>
                            <Input
                                name="username"
                                id="username"
                                size="large"
                                placeholder="Username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                style={{ marginBottom: 20 }}
                            />

                            <label htmlFor="password"><Typography.Title level={5}>Password*:</Typography.Title> </label>
                            <Input.Password
                                name="password"
                                id="password"
                                size="large"
                                placeholder="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                style={{ marginBottom: 20 }}
                            />

                            <Button htmlType="submit" type={"primary"} size={`large`} loading={formik.isSubmitting} style={{ marginBottom: 8, width: "100%" }}
                                onClick={() => { formik.errors.username || formik.errors.password ? formik.setStatus(formik.errors.password || formik.errors.username) : formik.setStatus() }}>
                                {formik.isSubmitting ? `Creating, please wait..` : t('register')}
                            </Button>

                            {formik.status ? <Alert message={formik.status} type='error' style={{ marginBottom: 8 }} /> : <></>}

                            <Typography.Paragraph>Have an account already? <Link to="/login">Click here to login!</Link> </Typography.Paragraph>
                        </form>
                    </div>
                </div>
            </Col>

        </Row>
    );
}

export default CreateAccount;
