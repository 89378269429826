import React from 'react';
import Carousel from './Carousel';
import Feature1 from '../assets/image/feature-1.svg';
import Feature2 from '../assets/image/feature-2.webp';
import Feature3 from '../assets/image/feature-3.svg';

const headings = ["Mock Exam Made Easy", "Multi Language Support", "Institute Management"];
const subtitles = [
    "User friendly software to create questions, manage practice sessions or mock exams, manage student groups and assess their results.",
    "Multiple local Indian languages supported. Create a practice session or mock exam in any language and then translate it to other supported languages.",
    "Customise your institute information. Complete user and their group management, user friendly dashboard for analytics."
];

const Background = () => {
    return (
        <>
            <div className='circle-1'></div>
            <div className='circle-2'></div>
            <div className='circle-3'></div>

            <Carousel imageArr={[Feature1, Feature2, Feature3]} interval={3000} headings={headings} subtitles={subtitles} />
        </>
    );
}

export default Background;
