import React from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
import './Container.css';
import { selectorService } from '../../../services/selectorService';
import { isEqual } from 'lodash'



class Container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cards: props.modelState,
			styleClassName: "subjectButtonTrue",//this.prop.styleClassName
			sections: [],
			loaded: false,
		};
		this.sendData = this.sendData.bind(this);
		this.renderCard = this.renderCard.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.sendClickData = this.sendClickData.bind(this);
	}
	sendData = () => {
		this.props.parentCallback(this.state.cards);
	}

	componentDidMount() {
		this.setState({
			cards: this.props.modelState,
			styleClassName: "subjectButtonTrue",//this.prop.styleClassName
			sections: [],
			loaded: false,
		})
	}

	componentDidUpdate(prevprops) {
		if (!isEqual(prevprops.modelState, this.props.modelState))
			this.setState({ cards: this.props.modelState });
	}

	// componentDidMount(){
	//   selectorService.loadSections(this.props.id).then(data => {
	//     console.log('Success:', data);
	//     const result = data.result;
	//     this.state.sections = [];
	//     const sections = result.sections;
	//     for (let index = 0; index < sections.length; index++) {
	//       let c = {};
	//       c.id=(sections[index]).id;
	//       c.label = (sections[index]).subject_name;
	//       c.value = (sections[index]).subject_id;
	//       c.section_state=(sections[index]).section_state;
	//       c.totalQuestion = (sections[index]).total_questions;
	//       c.subject_id = (sections[index]).subject_id;
	//       c.index = index;
	//       c.buttonState = false;
	//       this.state.sections.push(c);
	//       }
	//      this.state.loaded=true;
	//       this.forceUpdate();
	//   });
	// }

	sendClickData = (index) => {
		this.props.parentButtonCallback(index);
	}
	renderCard = (card, index) => {
		// console.log(card);
		return (
			<Card key={card.value}
				status={card.section_state}
				index={index}
				id={card.value}
				text={card.label}
				moveCard={this.moveCard}
				clickButton={(e) => { this.sendClickData(e) }}
			/>
		);
	};

	moveCard = (dragIndex, hoverIndex) => {
		// console.log(dragIndex, hoverIndex, this.state.cards, this.props);
		const dragCard = this.state.cards[dragIndex];
		this.setState({
			cards: update(this.state.cards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			})
		});
		this.sendData();
	};

	render() {
		// console.log(this.props);
		return (
			<>
				{/* {this.state.loaded? */}
				<div className='ReaarngeButtons'>
					{this.props.modelState.map((card, i) => this.renderCard(card, i))}
				</div>
				{/* :null} */}
			</>
		)
	};
};

export default Container;