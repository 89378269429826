import React from 'react';
import { Input, Col, Row, Select, InputNumber, DatePicker, AutoComplete, Cascader,Button } from 'antd';
import { QuestionCard } from './QuestionCard';
import update from 'immutability-helper';
import './ReviewContainer.css';



class ReviewContainer extends React.Component{
    constructor(props) {
        super(props);
    this.state = {
      cards : this.props.modelState,
      styleClassName: "subjectButtonTrue",//this.prop.styleClassName,
      pmark:1,
      nmark:0,
    };
    this.sendData = this.sendData.bind(this);
    this.renderCard = this.renderCard.bind(this);
    this.moveCard = this.moveCard.bind(this);
    this.sendClickData = this.sendClickData.bind(this);
    this.detachQuestion = this.detachQuestion.bind(this);
    this.changePositiveMark = this.changePositiveMark.bind(this);
    this.changeNegativeMark = this.changeNegativeMark.bind(this);
 }
    changePositiveMark = (index, value) => {
      // console.log(index,value);
      this.state.cards[index].pmark = value;
      this.sendData();
    }
    changeNegativeMark = (index, value) => {
      // console.log(index,value);
      this.state.cards[index].nmark = value;
      this.sendData();
    }

    sendData = () => {
        this.props.parentCallback(this.state.cards);
      }

    sendClickData = (index) => {
        this.props.parentButtonCallback(index);
      }

      detachQuestion = (index) => {
        this.setState(prev => ({...prev, cards: this.state.cards.filter((card, idx) => idx!==index)}));
        this.props.detachQuestionCallback(index);
      }
    renderCard = (card, index) => {
      // console.log(card,index);
        return (<QuestionCard key={card.value} index={index} id={card.value} text={card.label} pmark={card.pmark}  nmark={card.nmark} moveCard={this.moveCard} detachQuestion={this.detachQuestion} changeNegativeMarkCall={this.changeNegativeMark} changePositiveMarkCall={this.changePositiveMark}/>);
    };

    moveCard = (dragIndex, hoverIndex) => {
        const dragCard = this.state.cards[dragIndex];
        this.setState({cards:update(this.state.cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })});
        this.sendData();
    };
    
    render(){
      // console.log(this.state.cards)
      return(
        <>
        <Input.Group compact>
      <Input allowClear type='number' placeholder='Positive' style={{ width: '10%' }} defaultValue="1" onChange={(e,newValue)=>{this.setState({pmark:e.target.value})}} value={this.state.pmark} />
      <Input allowClear type='number' placeholder='Negative' style={{ width: '10%' }} defaultValue="0" onChange={(e,newValue)=>{this.setState({nmark:e.target.value})}} value={this.state.nmark} />
      <Button type="primary" onClick={()=>{
        for(let i in this.state.cards)
        {
          this.changePositiveMark(i,Number(this.state.pmark));
          this.changeNegativeMark(i,Number(this.state.nmark));
        }

      }}>Apply</Button>
    </Input.Group>
    
    <br />
				<div  className='ArrangeQuestions'><table className='ReviewTable'><thead><th></th><th>Questions</th><th>Positive Marks</th><th>Negative Marks</th><th></th></thead><tbody> {this.props.modelState.map((card, i) => this.renderCard(card, i))}</tbody></table></div>
			</>
      )
    };
  };

export default ReviewContainer;