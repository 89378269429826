//@ts-check
import React from 'react';
import './AddQuestions.css';
import { Layout, Button, Drawer, message } from 'antd';
import 'antd/dist/antd.css';
import Selector from './Selector';
import { authenticationService } from '../../_services';
import QuestionTable from './QuestionTable';
import equal from 'fast-deep-equal';
import { selectorService } from '../../services/selectorService';
import ReviewQuestions from './ReviewQuestions';



class AddQuestions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser: authenticationService.currentUserValue,
			top: 'topLeft',
			bottom: 'bottomRight',
			subject: "",
			category: "",
			questionType: "",
			complexity: "",
			classes: props.addQuestion.classes || 1,
			module_id: props.addQuestion.subjectId,
			section_id: props.addQuestion.module_id,
			module: props.addQuestion.module,
			subjectId: props.addQuestion.subjectId,
			visible: props.addQuestion.visible,
			attachedQuestions: [],
			examId: props.addQuestion.examId,
			reviewVisible: false
		};
		this.ReviewQuestionRef = React.createRef();
		this.attachQuestion = this.attachQuestion.bind(this);
		this.reviewQuestions = this.reviewQuestions.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	attachQuestion() {
		const data = {};
		data.idList = this.state.attachedQuestions;
		message.loading({ content: 'Attaching Question...' });
		let examSectionId = this.props.addQuestion.sections.filter(x => x.label === this.props.addQuestion.sectionName)[0].id;
		selectorService.attachQuestionsToExam(examSectionId, data).then(data1 => {
			// console.log(data1);
			message.success({ content: 'Question Attached to : ' + this.props.addQuestion.sectionName, duration: 3 });
		});

	}
	handleCancel = e => {
		// console.log(e);

		this.setState({ reviewVisible: false, visible: false }, () => { this.props.closeComplete(e); });
	};

	handleSubmit = e => {
		this.ReviewQuestionRef.current.submitReviewQuestion();
		// this.handleCancel();
	}

	reviewQuestions = () => {
		this.setState({
			reviewVisible: true,
		});


	}

	addQuestions = (questions) => {
		//  console.log("Parent recieved Selector Data: "+ childData);
		this.state.attachedQuestions = questions
	}


	callbackFunction = (childData) => {
		//  console.log("Parent recieved Selector Data: "+ childData);
		this.setState({
			classes: childData.classes,
			module_id: childData.module_id,
			subjectId: this.props.addQuestion.subjectId,
			module: childData.module,
			questionType: childData.questionType,
			complexity: childData.complexity,
			visible: true
		});
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props.addQuestion, prevProps.addQuestion)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
		{

			this.setState({
				classes: this.props.addQuestion.classes,
				module_id: this.props.addQuestion.module_id,
				module: this.props.addQuestion.module,
				subjectId: this.props.addQuestion.subjectId,
			})
		}
	}

	componentDidMount() {
		this.setState({
			classes: this.props.addQuestion.classes,
			module_id: this.props.addQuestion.module_id,
			module: this.props.addQuestion.module,
			subjectId: this.props.addQuestion.subjectId,
		})
		// this.ReviewQuestionRef = React.createRef();   // took the initialisation to contructor function
	}
	render() {
		// console.log(this.state, this.props);
		return (
			<div>
				<Layout>
					<Layout>
						<Layout className="SubSection1">
							<div className='ExamSelector'>
								<Selector page="addQuestions" selectorState={this.props.addQuestion} parentCallback={this.callbackFunction} />
								<div className="selector"><div className="selector-2">
									<Button className="selectorLeftButton" type="primary" onClick={this.attachQuestion}>
										Attach
									</Button>
								</div>
								<div className="selector-2"> <Button className="selectorLeftButton" type="primary" onClick={this.reviewQuestions}>
									Review Questions
								</Button></div></div>
							</div>
							<Layout className="SubSection2">
								<div style={{ padding: '5px' }}>
									<QuestionTable selectorState={this.state} parentCallback={this.addQuestions} page={1}/>
								</div>
							</Layout>
						</Layout>
					</Layout>

				</Layout>
				{
				this.state.reviewVisible ?
					<Drawer title={"Review Questions for Sections : " + this.props.addQuestion.sectionName} width={"100%"} placement="right"
						closable={true} onClose={this.handleCancel} visible={this.state.reviewVisible}
						footer={
							<div style={{ textAlign: 'left', }} >
								<Button onClick={this.handleSubmit} type="primary" style={{ marginRight: 8 }}>
									Submit
								</Button>
								<Button onClick={this.handleCancel} type="primary" style={{ marginRight: 8 }}>
									Close
								</Button>
							</div>
						}
					>
						<ReviewQuestions ref={this.ReviewQuestionRef} handleCancel={this.handleCancel} sectionState={this.props.addQuestion}></ReviewQuestions>
					</Drawer> 
					: null
				}
			</div>
		);
	}
}

export default AddQuestions;
